import React, { Component } from 'react';
import FormControl from '../common/FormControl.js';
import FormButton from '../common/FormButton.js';
import ProjectService from '../../services/project.js';
import ProjectTeamList from './ProjectTeamList.js';
import ProjectProgramList from './ProjectProgramList.js';
import PurchaseOrderFormControl from "../common/PurchaseOrderFormControl";
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Utils from "../common/Utils";
import { DATE_MAX, DATE_MIN, STRING_LENGTH } from '../common/Constants';
import TeamService from "../../services/team";
import UserSuggestion from '../common/UserSuggestion';

class ProjectForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			project: {
				...this.props.project,
				teams: []
			},
			initialTeams: []
		};

		this.onChange = this.onChange.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.copying = this.copying.bind(this);
		this.onTeamChange = this.onTeamChange.bind(this);
		this.findProjectTeams = this.findProjectTeams.bind(this);
		this.onPurchaseOrderCodeChange = this.onPurchaseOrderCodeChange.bind(this);
		this.onManagerIdChange = this.onManagerIdChange.bind(this);
	}

	componentDidMount() {
		if (this.props.project.id !== "+") {
			ProjectService
				.get(this.props.project.id)
				.then((response) => {
					this.setState({ project: response }, () => this.findProjectTeams());
				}).catch((error) => {
					Utils.error(i18next.t("single-project-error") + JSON.stringify(error));
				});
		}
	}

	findProjectTeams() {
		TeamService
			.findByProjectId(this.state.project.id)
			.then((result) => {
				this.setState({
					project: {
						...this.state.project,
						teams: result
					},
					initialTeams: result
				})
			});
	}


	onChange(event) {
		let project = Object.assign({}, this.state.project);
		project[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		this.setState({ project });
	}

	onTeamChange(teams) {
		let project = Object.assign({}, this.state.project);
		teams.forEach((team) => {
			team.name = team.text
		});
		project.teams = teams;
		this.setState({ project });
	}

	resetForm() {
		let copyProject = Object.assign({}, this.props.project);
		copyProject.teams = this.state.initialTeams;
		this.setState({
			project: copyProject
		})
	}

	onSubmit(event) {
		event.preventDefault();
		if (this.state.project.id === "+") {
			ProjectService
				.create(this.state.project)
				.then((response) => {
					this.props.onCreate(response);
					this.props.onFindProjects(this.props.filter);
					this.setState({
						initialTeams: response.teams
					});
					toast.info(i18next.t("project-save-success"), {
						icon: false
					  });
				}).catch((error) => {
					Utils.error(i18next.t("project-save-error") + " " + error.data.message);
				});
		} else {
			ProjectService
				.update(this.state.project)
				.then((response) => {
					this.setState({
						project: response,
						initialTeams: response.teams
					});
					this.props.onUpdate(response);
                    toast.info(i18next.t("project-update-success"), {
						icon: false
					  });
				}).catch((error) => {
					Utils.error(i18next.t("project-save-error") + JSON.stringify(error));
				});
		}
	}

	copying() {
		this.props.copyAnItem(this.state.project);
	}

	getCopyButton() {
		if (this.state.project.id !== "+") {
			return <FormButton type="button" onClick={this.copying} value={i18next.t("clone")} />
		}
	}

	getCreatedBy() {
		if (this.state.project.createdByFirstName !== undefined) {
			return <FormControl label={i18next.t("createdBy")} name="createdBy"
				value={this.state.project.createdByFirstName + " " + this.state.project.createdByLastName + " (" + this.state.project.createdBy + ")"}
				className="form-control-plaintext" onChange={() => { }} />
		}
	}

	onPurchaseOrderCodeChange(purchaseOrderCode) {
		if (this.state.project.purchaseOrderCode !== purchaseOrderCode) {
			let project = Object.assign({}, this.state.project);
			project.purchaseOrderCode = purchaseOrderCode;
			project.programId = undefined;
			this.setState({ project });
		}
	}

	onManagerIdChange(managerId) {
		this.setState(prevState => ({
			project: {
				...prevState.project,
				managerId: managerId
			}
		}))
	}
	
	render() {
		return (
			<form id="projectForm" style={this.props.style} onSubmit={this.onSubmit}>
				<FormControl label={i18next.t("project-name")} name="name" value={this.state.project.name} onChange={this.onChange} maxLength={STRING_LENGTH} />
				<FormControl label={i18next.t("project-code")} name="code" value={this.state.project.code} onChange={this.onChange} maxLength={STRING_LENGTH} />
				<UserSuggestion label={i18next.t("managing-user")}
					chosenUserId={this.state.project.managerId}
					onChangeSuggestionId={this.onManagerIdChange}
					inputPlaceholder={i18next.t("choose-the-user")}
					required={false} />
				<FormControl label={i18next.t("active")} name="active" type="checkbox" checked={this.state.project.active} onChange={this.onChange} required={true} />
				<PurchaseOrderFormControl label={i18next.t("order")}
										  onChangeSuggestionId={this.onPurchaseOrderCodeChange}
										  chosenExternalId={this.state.project.purchaseOrderCode}
										  inputPlaceholder={i18next.t("choose-the-order")}
										  removePossible={true}/>
				<ProjectProgramList label={i18next.t("portfolio")} purchaseOrderCode={this.state.project.purchaseOrderCode} name="programId" value={this.state.project.programId} onChange={this.onChange} />
				<FormControl label={i18next.t("start-date")} name="startDate" type="date" value={this.state.project.startDate} onChange={this.onChange} max={DATE_MAX} min={DATE_MIN} />
				<FormControl label={i18next.t("end-date")} name="endDate" type="date" value={this.state.project.endDate} onChange={this.onChange} max={DATE_MAX} min={DATE_MIN} />
				<FormControl label={i18next.t("start-report-date")} name="reportingFromDate" type="date" value={this.state.project.reportingFromDate} onChange={this.onChange} max={DATE_MAX} min={DATE_MIN} />
				<ProjectTeamList label={i18next.t("team")} name="team" project={this.state.project}
					onChange={this.onTeamChange} teams={this.state.project.teams} />
				{this.getCreatedBy()}
				<div className="row buttons-to-left btn-grey-container btn-overlap-container">
					<div className="col-sm-2"></div>
					<FormButton type="submit" value={i18next.t("save-button")} />
					<FormButton type="button" onClick={this.resetForm} value={i18next.t("cancel-button")} />
					{this.getCopyButton()}
				</div>
			</form>
		);
	}
}

export default ProjectForm;
