import React from 'react';
import OrderService from '../../services/order.js';
import Suggestion from './Suggestion';
import i18next from 'i18next';

class PurchaseOrderFormControl extends Suggestion {


	componentDidMount() {
    	OrderService
		    .getPurchaseOrders()
			.then((result) => {
				this.setState(
					{
						items: result,
						value: this.props.chosenExternalId ? this.props.chosenExternalId : ''
					}, () => {
						if(this.props.removePossible){
							let items = [...this.state.items];
							items.unshift({externalId: null});
							this.setState({items});
						}
					});
			});
    }

	getSuggestionValue (suggestion)  {
		if(suggestion.externalId) {
			return suggestion.externalId;
		} else {
			return i18next.t("none");
		}
	}

	renderSuggestion (suggestion){
		if(suggestion.externalId) {
			return (
				<div>
					{suggestion.externalId}
				</div>
			);
		} else {
			return (
				<div>
					&lt;{i18next.t("none")}&gt;
				</div>
			);
		}

	}

	onSuggestionsFetchRequested ({value}){
		const inputValue = value.trim().toLowerCase();
		this.setState({
			suggestions: this.state.items.filter((item) => {
				if(item.externalId) {
					return item.externalId.toLowerCase().includes(inputValue)
				} else{
					return true;
				}
			})
		})
	}

	onSuggestionSelected(event, {suggestion}) {
		this.props.onChangeSuggestionId(suggestion.externalId);
	}

}

export default PurchaseOrderFormControl;
