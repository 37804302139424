import React, {Component} from 'react';
import TaskFilter from './TaskFilter';
import TaskListItem from './TaskListItem';
import i18next from 'i18next';
import SortableColumn from "../common/SortableColumn";
import PageableFilter from "../common/PageableFilter";
import ListTitle from "../common/ListTitle";

class TaskList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedRowId: null,
			filter: {},
			statuses: [],
			copyItem: null,
			addButtonLabel: i18next.t("add-new")
		};

		this.selectRow = this.selectRow.bind(this);
		this.findTasks = this.findTasks.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onCreateTask = this.onCreateTask.bind(this);
		this.onUpdateTask = this.onUpdateTask.bind(this);
		this.onSortOrder = this.onSortOrder.bind(this);
		this.copyAnItem = this.copyAnItem.bind(this);
		this.onFilterTaskStatusChange = this.onFilterTaskStatusChange.bind(this);
		this.addNewItem = this.addNewItem.bind(this);
	}

	selectRow(key) {
		this.setState((prevState) => {
				return {selectedRowId: key === prevState.selectedRowId ? null : key,
					    addButtonLabel: i18next.t("add-new")}
		});
	}

	findTasks() {
		this.props.onFindTasks(this.state.filter);
	}

    onFilterChange(event) {
		let filter = Object.assign({}, this.state.filter);
		filter[event.target.name] = event.target.value;
		if(!["findPageNumber", "sortBy", "sortDirection"].includes(event.target.name)){
			filter["findPageNumber"] = "0";
		}
		this.setState({filter}, () => this.findTasks());
	}

	onFilterTaskStatusChange(statuses) {
		let filter = Object.assign({}, this.state.filter);
		filter["findTaskStatusId"] = statuses;
		filter["findPageNumber"] = "0";
		this.setState({
			filter: filter
		}, () => this.findTasks());
	}

	onCreateTask(task) {
    	this.setState({
    	    selectedRowId: null,
		    copyItem: null,
			addButtonLabel: i18next.t("add-new")
		});
    }

    onUpdateTask(task) {
    	this.setState({task: task});
    	this.props.onFindTasks(this.state.filter);
    }

	renderProjectTaskFilter() {
		return
	}

	onSortOrder(filter) {
        this.setState({filter: filter, selectedRowId: null}, () => this.findTasks());
    }

    renderAllItems() {
        return this.props.tasks.map((task) => this.renderItem(task));
    }

    renderItem(task) {
        return <TaskListItem key={task.id}
                             selected={this.state.selectedRowId}
                             task={task}
                             onSelect={this.selectRow}
                             onUpdate={this.onUpdateTask}
                             onFindTasks={this.props.onFindTasks}
                             filter={this.state.filter}
                             copyAnItem={this.copyAnItem}/>
    }

    renderEmptyItem() {
        let task = {};
        if (this.state.copyItem !== null) {
            task = Object.assign({}, this.state.copyItem);
            task.id = "+";
        } else {
            task = {id: "+", name: "", assignedUser: {firstName: "", lastName: ""},
                projectName: "", projectId: "", externalCode: "", taskStatusName: "",
                startDate: "", endDate: "", plannedHours: "", reportingFrom: "",
                active: true, purchaseOrderId: "", plus: "plus", teamId: null};
        }
        return <TaskListItem
            key={'+'}
            selected={this.state.selectedRowId}
            onSelect={this.selectRow}
            onCreate={this.onCreateTask}
            task={task}
            onFindTasks={this.props.onFindTasks}
            filter={this.state.filter} />
    }

    copyAnItem(task) {
        this.setState({
            selectedRowId: "+",
            copyItem: task
        });
    }

	addNewItem(){
		this.setState(prevState => ({
			selectedRowId: prevState.selectedRowId === '+' ? null : '+',
			addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
		}))
	}

	render() {
	  	return (
		    <table className="table table-sm">
		    	<thead>
				<ListTitle sectionLabel={i18next.t("navbar-tasks")} span={8}
						   onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
                    <TaskFilter filter={this.state.filter}
                            onFilterChange={this.onFilterChange}
                            resultSize={this.props.tasks.length}
                            onSubmit={this.findTasks}
                            onFilterTaskStatusChange={this.onFilterTaskStatusChange} />
		    	    <tr>
                        <SortableColumn name="t.id" label="Id" filter={this.state.filter} onClick={this.onSortOrder}/>
						<SortableColumn name="t.externalCode" label={i18next.t("task-code")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="t.name" label={i18next.t("task-name")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="t.assignedUser.login" label={i18next.t("assigned-to")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="v.value" label={i18next.t("status")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="t.active" label={i18next.t("active-female-plural")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="t.project.code" label={i18next.t("project-code")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="t.project.name" label={i18next.t("project-name")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="t.project.program.code" label={i18next.t("portfolio-code")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="t.project.program.name" label={i18next.t("portfolio-name")} filter={this.state.filter} onClick={this.onSortOrder}/>
		    	    </tr>
		    	</thead>
		    	<tbody>
					{this.renderEmptyItem()}
		    		{this.renderAllItems()}
					<PageableFilter colSpan="10" onFilterChange={this.onFilterChange}
									findPageSize={this.state.filter.findPageSize}
									findPageNumber={this.state.filter.findPageNumber}
									resultSize={this.props.tasks.length}
									hidePageSize={true}/>
		    	</tbody>
		    </table>
		);
  	}

}

export default TaskList;
