import request from '../lib/request';

function get() {
    return request({
        url: "/date",
        method: 'GET'
    });
}

const CalendarService = {
    get
}

export default CalendarService;