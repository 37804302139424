import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {ContainerOrders} from "../../styledDropzone";
import i18next from 'i18next';

class OrderAttachmentsDropzoneForm extends Component {

    render() {
        return (
            <Dropzone onDrop={acceptedFile => this.props.handleUploadFile(acceptedFile)}>
                {({getRootProps, getInputProps}) => (
                    <section>
                        <ContainerOrders {...getRootProps({})}>
                            <input {...getInputProps()}/>
                            <p>{i18next.t("add-file")}</p>
                        </ContainerOrders>
                    </section>)}
            </Dropzone>
        )
    }
}

export default OrderAttachmentsDropzoneForm;