import React, { Component } from 'react';
import PropTypes from "prop-types";
import UserTab from './UserTab';

class UserListItem extends Component {
  
	constructor(props) {
		super(props);

		this.onCreateUser = this.onCreateUser.bind(this);
		this.onUpdateUser = this.onUpdateUser.bind(this);
	}	

	onCreateUser(user) {
		this.props.onCreate(user);
	}
	
	onUpdateUser(user) {
		this.props.onUpdate(user);
	}

 	render() {
		if(this.props.user.id === '+' && this.props.selected !== this.props.user.id){
			return null;
		}
		let userTab = null;
		if (this.props.selected === this.props.user.id) {
		  	userTab =
			    <tr>
				    <td colSpan="6">
				  	    <UserTab
				  		    key={this.props.user.id}
				  		    user={this.props.user}
				  		    onCreate={this.onCreateUser}
				  		    onUpdate={this.onUpdateUser}
				  		    onFindUsers={this.props.onFindUsers}/>
				    </td>
				</tr>;
	    }
	    return (
            <>
		        <tr className={this.props.user.plus} onClick={() => this.props.onSelect(this.props.user.id)}>
		        	<td>{this.props.user.id}</td>
		        	<td>{this.props.user.firstName}</td>
		        	<td>{this.props.user.lastName}</td>
		        	<td>{this.props.user.login}</td>
		        	<td>{this.props.user.email}</td>
		        	<td>{this.props.user.active ? "✔" : ""}</td>
		        </tr>
		        {userTab}
		    </>
        );
    }
}

UserListItem.propTypes = {
	onCreate : PropTypes.func,
	onUpdate: PropTypes.func,
	user: PropTypes.object
};

export default UserListItem;