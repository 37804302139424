import React, {Component} from 'react';
import TaskStatusList from './TaskStatusList.js';
import TaskStatusService from '../../../services/taskstatus.js';
import i18next from 'i18next';
import Utils from "../../common/Utils";

class TaskStatusFilteredList extends Component {

	constructor(props) {
		super(props);
		this.state = {
		    taskStatusData: []
		};
		this.findTaskStatuses = this.findTaskStatuses.bind(this);
	}

    componentDidMount() {
        this.findTaskStatuses({});
    }

	findTaskStatuses(filter) {
		TaskStatusService
		    .find(filter)
			.then((taskStatusData) => {
				this.setState({taskStatusData});
			})
			.catch((error) => {
				Utils.error(i18next.t("save-error") + JSON.stringify(error));
			});
	}

  	render() {
    	return <TaskStatusList taskStatuses={this.state.taskStatusData}
						 onFindTaskStatuses={this.findTaskStatuses}/>
	}
}

export default TaskStatusFilteredList;