import React, { Component } from 'react';
import styled from 'styled-components';
import uploadService from '../../services/upload';
import Dropzone from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Utils from "../common/Utils";

class TimesheetMonthlyTaskItem extends Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            attachmentId: this.props.data.attachmentId,
            attachmentName: this.props.data.attachmentName
        }
    }

    onChange(event) {
        if(!isNaN(event.target.value)) {
            let data = Object.assign({}, this.props.data);
            data[event.target.name] = event.target.value;
            this.props.onUpdate(data);
        }
    }

    handleClick() {
        uploadService
            .downloadAttachment(this.state.attachmentId)
            .then(response => {
                const filename =  response.headers.contentdisposition.split('filename=')[1];
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            });
    }

    handleUploadFile = (files) => {
        files.forEach(file => {
            var formData = new FormData();
            formData.append('file', file);

            uploadService.uploadAttachment(this.props.year, this.props.month, this.props.data.taskId, formData)
                .then( response => {
                    toast.info(i18next.t("attachment-add-success"), {icon: false});

                    this.setState({
                        attachmentId: response.data.id,
                        attachmentName: file.name
                    });
                }).catch(() => {
                toast.error(i18next.t("attachment-add-error"), {icon: false});
            });
        });
    };
    removeUploadFile(attachmentId) {
        uploadService.removeAttachment(attachmentId)
            .then( () => {
                toast.info(i18next.t("attachment-remove-success"), {icon: false});
            }).catch(() => {
                toast.error(i18next.t("attachment-remove-error"), {icon: false});
            })
        this.setState({
            attachmentName: null,
            attachmentId: null
        })
    }

    render() {
        const Container = styled.div`
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 4px;
            border-width: 2px;
            border-radius: 2px;
            border-color: grey;
            border-style: dashed;
            background-color: #fafafa;
            color: #bdbdbd;
            outline: none;
            transition: border .24s ease-in-out;
        `;

        return (
            <tr>
                <td>{this.props.data.taskName}</td>
                <td>{this.props.data.taskCode}</td>
                <td>{this.props.data.plannedHours}</td>
                <td>{this.props.data.usedHours}</td>
                <td><input type="text" name={"workedHours"} value={this.props.data.workedHours} size="1" onChange={this.onChange}/></td>
                <td>
                    <Button onClick={() => this.removeUploadFile(this.state.attachmentId)} disabled={!this.state.attachmentId}>{i18next.t("attachment-remove")}</Button>
                    <Button variant="link" onClick={this.handleClick}>{this.state.attachmentName}</Button>
                </td>
                <td className="text-center mt-5">
                    <Dropzone onDrop={acceptedFile => this.handleUploadFile(acceptedFile)}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <Container {...getRootProps({})}>
                                    <input {...getInputProps()} />
                                    <p>{i18next.t("dropzone")}</p>
                                </Container>
                            </section>)}
                    </Dropzone>
                </td>
            </tr>
        );
    }
}
export default TimesheetMonthlyTaskItem;