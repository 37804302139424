import OrderList from '../order/OrderList';
import React from 'react';
import CustomerOrderListItem from './CustomerOrderListItem';
import OrderService from '../../services/order';
import i18next from 'i18next';
import Utils from "../common/Utils";

class CustomerOrders extends OrderList {

    constructor(props) {
        super(props);
        this.state = {
            orders: []
        }
    }

    componentDidMount() {
        let correctId = this.props.customer.id;
        if (correctId === '+') {
            correctId = null;
        }
        OrderService
            .find({ customerId: correctId })
            .then((response) => {
                this.setState({
                    orders: response
                })
            }).catch((error) => {
                Utils.error(i18next.t("data-read-error") + JSON.stringify(error));
            });
    }

    render() {
        return (
            <div>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">{i18next.t("external-id")}</th>
                            <th scope="col" className="text-center">{i18next.t("start-date")}</th>
                            <th scope="col">{i18next.t("end-date")}</th>
                            <th scope="col">{i18next.t("managing-user")}</th>
                            <th scope="col">{i18next.t("daywork")}</th>
                            <th scope="col">{i18next.t("pay-rate")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.orders.map((order) =>
                            <CustomerOrderListItem key={order.id} selected={this.state.selectedRowId} order={order}
                                onSelect={this.selectRow} findOrders={this.findOrders} />)}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default CustomerOrders;