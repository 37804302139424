import React, {Component} from 'react';
import CustomerForm from './CustomerForm';
import CustomerAddresses from './CustomerAddresses';
import CustomerOrders from './CustomerOrders';
import {Tab, Tabs} from "react-bootstrap";
import i18next from 'i18next';

class CustomerTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1"
        };

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(selectedTab) {
        this.setState({
            activeTab: selectedTab
        })
    }

    getForm() {
        if (this.state.activeTab === "1") {
            return <CustomerForm
                key={this.props.customer.id}
                customer={this.props.customer}
                onCreate={this.props.onCreateCustomer}
                onUpdate={this.props.onUpdateCustomer}
                onFindCustomers={this.props.onFindCustomers}
                filter={this.props.filter}
            />
        }
    }

    getAddresses() {
        if (this.state.activeTab === "2") {
            return <CustomerAddresses customerId={this.props.customer.id}/>
        }
    }

    getOrders() {
        if (this.state.activeTab === "3") {
            return <CustomerOrders orders={[]} onFindOrders={() => {}}
            customer = {this.props.customer}/>
        }
    }

    checkIfCustomerExistsAndGetAddresses() {
        if (this.props.customer.id !== '+') {
            return (
                <Tab eventKey="2" title={i18next.t("address")} className="bg-light border">
                    {this.getAddresses()}
                </Tab>
            );
        }
    }

    checkIfCustomerExistsAndGetOrders() {
        if (this.props.customer.id !== '+') {
            return (
                <Tab eventKey = "3" title={i18next.t("order")} className="bg-light border">
                    {this.getOrders()}
                </Tab>
            );
        }
    }

    render() {
        return (
            <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect} className="customer-container-form">
                <Tab eventKey="1" title={i18next.t("edit")} className="bg-light border ">
                    {this.getForm()}
                </Tab>
                {this.checkIfCustomerExistsAndGetAddresses()}
                {this.checkIfCustomerExistsAndGetOrders()}
            </Tabs>
        );
    }
}

export default CustomerTab;