import request from '../lib/request';

function getRoles() {
    return request({
        url: "/business/roles",
        method: 'GET'
    });
}

function findReports(searchParams) {
    return request({
        url: "/business/reports",
        method: 'GET',
	    params: {
	        startDate: searchParams.startDateLeftLimit,
	        endDate: searchParams.startDateRightLimit,
	        roleName: searchParams.role
	    }
    });
}

const BusinessReportService = {
    getRoles,
    findReports
}

export default BusinessReportService;