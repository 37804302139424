import React, {Component} from 'react';
import UserInfo from './UserInfo';
import PasswordChange from './PasswordChange';
import ReportIssue from "./ReportIssue";
// import LanguageChange from "./LanguageChange";
import {USE_ACTIVE_DIRECTORY} from "../common/Constants"
import Version from "./Version";

class Profile extends Component {

    render() {
        return (
            <div className="jumbotron profile-container">
                <Version/>
                <UserInfo/>
                {USE_ACTIVE_DIRECTORY || <PasswordChange/>}
                <ReportIssue/>
                {/* <LanguageChange/> */}

            </div>
        );
    }
}

export default Profile;