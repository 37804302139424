import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReconciliationForm from "./ReconciliationForm";
import moment from "moment";
import uploadService from "../../services/upload";
import i18next from 'i18next';
import Utils from "../common/Utils";

class ReconciliationListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            statuses: this.props.statuses
        };

        this.onUpdate = this.onUpdate.bind(this);
    }

    onUpdate() {
        this.props.onSelect(null);
        this.props.findReconciliations();
    }

    componentDidMount() {
        if (this.props.statuses !== undefined) {
            this.setState({
                statuses: this.props.statuses
            }, () => this.setDictionaryStatus());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.reconciliation.status !== this.props.reconciliation.status) {
            this.setDictionaryStatus();
        }
    }

    handleDownloadAttachment = (e, id) => {
        e.stopPropagation();
        uploadService
            .downloadReconciliationAttachment(id)
            .then(response => {
                const filename = response.headers.contentdisposition.split('filename=')[1];
                let url = window.URL.createObjectURL(new Blob([response.data]))
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            }).catch((error) => {
                Utils.error(error.data.message);
        });
    }

    onSelect(event, reconciliationId) {
        this.props.onSelect(reconciliationId);
    }

    setDictionaryStatus() {
        this.state.statuses.forEach((status) => {
            if (status.id === this.props.reconciliation.status) {
                this.setState({status: status.name})
            }
        });
    }

    renderDownloadButton() {
        let button = null;
        if (this.props.reconciliation.attachmentId !== null && this.props.reconciliation.attachmentId !== undefined) {
            button = <button className={"btn btn-link"}
                             onClick={(e) => {
                                 this.handleDownloadAttachment(e, this.props.reconciliation.attachmentId)
                             }}>{i18next.t("download")}</button>
        }
        return button;
    }

    setSentDateFormat() {
        var sentDate = this.props.reconciliation.sentDate;
        if (this.props.reconciliation.sentDate !== null && this.props.reconciliation.sentDate !== undefined) {
            sentDate = moment(sentDate).format('YYYY-MM-DD hh:mm');
        }
        return sentDate;
    }

    setGeneratedDateFormat() {
        var generatedDate = this.props.reconciliation.generatedDate;
        if (this.props.reconciliation.generatedDate !== null && this.props.reconciliation.sentDate !== undefined) {
            generatedDate = moment(generatedDate).format('YYYY-MM-DD hh:mm:ss');
        }
        return generatedDate;
    }

    renderReconciliationForm() {
        let reconciliationForm = null;
        if (this.props.selected === this.props.reconciliation.id) {
            reconciliationForm =
                <tr>
                    <td colSpan="7">
                        <ReconciliationForm
                            key={this.props.reconciliation.id}
                            reconciliation={this.props.reconciliation}
                            onUpdate={this.onUpdate}
                            filter={this.props.filter}/>
                    </td>
                </tr>;
        }
        return reconciliationForm;
    }

    render() {
        if(this.props.reconciliation.id === '+' && this.props.selected !== this.props.reconciliation.id){
            return null;
        }
        return (
            <>
                <tr className={this.props.reconciliation.plus} onClick={(event) => this.onSelect(event, this.props.reconciliation.id)}>
                    <td>{this.props.reconciliation.id}</td>
                    <td>{this.props.reconciliation.dateStart}</td>
                    <td>{this.props.reconciliation.dateEnd}</td>
                    <td>{this.renderDownloadButton()}</td>
                    <td>{this.setGeneratedDateFormat()}</td>
                    <td>{this.setSentDateFormat()}</td>
                    <td>{this.state.status}</td>
                </tr>
                {this.renderReconciliationForm()}
            </>
        );
    }
}

ReconciliationListItem.propTypes = {
    reconciliation: PropTypes.object,
    onSelect: PropTypes.func,
    onUpdate: PropTypes.func,
    onCreate: PropTypes.func
}

export default ReconciliationListItem;