import React, { Component } from 'react';
import ProjectTab from './ProjectTab.js';


class ProjectListItem extends Component {
  
	constructor(props) {
		super(props);
		this.onCreateProject = this.onCreateProject.bind(this);
		this.onUpdateProject = this.onUpdateProject.bind(this);
	}	
  
	onCreateProject(project) {
		this.props.onCreate(project);
	}
	
	onUpdateProject(project) {
		this.props.onUpdate(project);
	}

	getManager() {
    	if (this.props.project.id !== "+" && this.props.project.managerLogin !== null) {
    	    return <td>{this.props.project.managerFirstName + " "+ this.props.project.managerLastName + " (" + this.props.project.managerLogin + ")"}</td>
    	}
    	return <td></td>
    }

    getCreatedBy() {
        if (this.props.project.id !== "+") {
            return <td>{this.props.project.createdByFirstName + " "+ this.props.project.createdByLastName + " (" + this.props.project.createdBy + ")"}</td>
        }
        return <td></td>
    }

    getProjectTab() {
        if (this.props.selected === this.props.project.id) {
            return <ProjectTab project = {this.props.project} selected={this.props.selected} onCreate={this.onCreateProject}
                               onUpdate={this.onUpdateProject} onFindProjects={this.props.onFindProjects} filter={this.props.filter}
		                       copyAnItem={this.props.copyAnItem}/>
        }
    }



  render() {
	  if(this.props.project.id === '+' && this.props.selected !== this.props.project.id){
		  return null;
	  }
    return (
    <>
		<tr className={this.props.project.plus} onClick={() => this.props.onSelect(this.props.project.id)}>
			<td>{this.props.project.id}</td>
			<td>{this.props.project.name}</td>
			<td>{this.props.project.code}</td>
			{this.getManager()}
			<td>{this.props.project.active ? "✔" : ""}</td>
			{this.getCreatedBy()}
			<td>{this.props.project.programName}</td>
			<td>{this.props.project.startDate}</td>
			<td>{this.props.project.endDate}</td>
			<td>{this.props.project.reportingFromDate}</td>
			<td>{this.props.project.purchaseOrderCode}</td>
		</tr>
		{this.getProjectTab()}
   	</>
    );
  }
}

export default ProjectListItem;