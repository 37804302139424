import OrderListItem from '../order/OrderListItem';
import React from 'react';

class CustomerOrderListItem extends OrderListItem {

    render() {
        return (
            <tr>
                <td>{this.props.order.id}</td>
                <td>{this.props.order.externalId}</td>
                <td className={"text-center"}>{this.props.order.startDate}</td>
                <td>{this.props.order.endDate}</td>
                <td>{this.props.order.userFullName}</td>
                <td>{this.props.order.orderInDays}</td>
                <td>{this.props.order.ratePerDay}zł</td>
            </tr>
        );
    }
}

export default CustomerOrderListItem;