const Theme = {
    container: {
        position : 'relative'
    },

    input: {
        display: 'block',
        width: '100%',
        height: 'calc(1.5em + 0.75rem + 2px)',
        padding: '0.375rem 0.75rem',
        border: '1px solid #ced4da',
        borderRadius: '0.25rem',
        backgroundClip: 'padding-box',
        '&:focus': {
            borderRadius: '0.25rem'
        }
    },

    suggestion: {
        cursor: 'pointer'
    },

    suggestionHighlighted: {
        backgroundColor: '#ddd'
    },

    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        maxHeight: 240,
        overflowY: 'scroll'
    }
};

export default Theme;
