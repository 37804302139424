import React, { Component } from 'react';
import FormControl from '../../common/FormControl';
import FormButton from '../../common/FormButton.js';
import HolidayService from '../../../services/holiday';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Utils from "../../common/Utils";

class HolidayForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            holiday: this.props.holiday
        };

        this.onChange = this.onChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteHoliday = this.deleteHoliday.bind(this);
        this.onSpacePress = this.onSpacePress.bind(this);
    }

    componentDidMount() {
        if (this.state.holiday.id !== "+") {
            HolidayService
                .get(this.state.holiday.id)
                .then((response) => {
                    let holiday = Object.assign({}, this.state.holiday);
                    holiday.id = response.id;
                    this.setState({holiday: response});
                }).catch((error) => {
                    Utils.error(i18next.t("save-error") + error.data.message);
            });
        }
    }

    onChange(event) {
        let holiday = Object.assign({}, this.state.holiday);
        holiday[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({holiday});
    }

    onReset(event) {
        this.setState({holiday: this.props.holiday});
    }

    onSpacePress(event) {
         if (event.which === 32) {
              event.preventDefault();
         }
    }

    deleteHoliday() {
        if (this.state.holiday.id !== "+") {
            HolidayService
                .remove(this.state.holiday.id)
                .then(() => {
                    this.props.onFindHolidays();
                    toast.info(i18next.t("holiday-delete-success", {
                        icon: false
                      }));
                })
                .catch((error) => {
                    Utils.error(i18next.t("delete-error") + error.data.message);
                });
        }
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.holiday.id === "+") {
            HolidayService
                .create(this.state.holiday)
                .then((response) => {
                    this.props.onCreate(response);
                    this.props.onFindHolidays();
                    toast.info(i18next.t("holiday-toast-success"), {
                        icon: false
                      });
                })
                .catch((error) => {
                    Utils.error(i18next.t("save-error") + error.data.message);
                });
        } else {
            HolidayService
                .update(this.state.holiday)
                .then((response) => {
                    this.setState({holiday: response});
                    this.props.onUpdate(response);
                })
                .catch((error) => {
                    Utils.error(i18next.t("update-error") + error.data.message);
                });
        }
    }

    render() {
        return (
            <form id="holidayForm" style={this.props.style} onReset={this.onReset}>
                <FormControl labelKey="holiday-year-label" name="year" value={this.state.holiday.year} onChange={this.onChange} maxLength="4" onKeyPress={this.onSpacePress}/>
                <FormControl labelKey="holiday-month-label" name="month" value={this.state.holiday.month} onChange={this.onChange} maxLength="2" onKeyPress={this.onSpacePress}/>
                <FormControl labelKey="holiday-day-label" name="day" value={this.state.holiday.day} onChange={this.onChange} maxLength="2" onKeyPress={this.onSpacePress}/>
                <FormControl labelKey="holiday-description-label" name="description" value={this.state.holiday.description} onChange={this.onChange}/>
                <div className="row buttons-to-left btn-grey-container btn-overlap-container">
                    <div className="col-sm-2">&nbsp;</div>
                    <FormButton type="button" valueKey="save-button" onClick={this.onSubmit}/>
                    <FormButton type="reset" valueKey="cancel-button"/>
                    <FormButton type="button" valueKey="delete-button" onClick={this.deleteHoliday}/>
                </div>
            </form>
        );
    }
}

export default HolidayForm;