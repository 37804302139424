import React, { Component } from 'react';

class FormCheckbox extends Component {
  
    render() {
  	    const style = {float: 'left'};
  	    const clear = {clear: 'both'};
        return (
	    	<>
	    		<div style={style}>
	    			<input className="form-check-input" checked={this.props.checked} type="checkbox" name={this.props.name} value={this.props.value} onChange={this.props.onChange}/><br/>
	    		</div>
	    		<div style={style}>
	    			<label className="form-check-label" htmlFor={this.props.name}>{this.props.label}</label>
	    		</div>
	    		<div style={clear}>
	    		</div>
	    	</>
        );
    }
}

export default FormCheckbox;