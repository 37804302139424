import React, { Component } from 'react';
import 'react-dates/initialize';
import i18next from 'i18next';
import { CSVLink } from "react-csv";
import {toast} from 'react-toastify';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';


class TaskReportFilter extends Component {

    constructor(props) {
            super(props);

            this.state = {
                headers: [
                		{label: i18next.t("portfolio-name"), key: "programName"},
                		{label: i18next.t("assigned-to"), key: "assignedUserName"},
                		{label: i18next.t("project-code"), key: "projectCode"},
                		{label: i18next.t("project-name"), key: "projectName"},
                		{label: i18next.t("task-code"), key: "taskCode"},
                		{label: i18next.t("task-name"), key: "taskName"},
                		{label: i18next.t("start-date"), key: "taskStartDate"},
                		{label: i18next.t("end-date"), key: "taskEndDate"},
                		{label: i18next.t("planned-hours"), key: "plannedHours"},
                		{label: i18next.t("logged-prev-period"), key: "loggedPrevPeriodHours"},
                		{label: i18next.t("logged-in-period"), key: "loggedInPeriodHours"},
                		{label: i18next.t("remaining-hours"), key: "remainingHours"},
                		{label: i18next.t("status"), key: "taskStatusName"}
                	]
            };

        }

    componentDidMount() {
		if (this.props.reports.length > 0 && this.props.reports[0].workDays !== null) {
		    let details = this.props.reports[0].workDays.map((workDay) => {
		        return {label: workDay.date, key: workDay.date}
		    })

			this.setState({
				headers: [...this.state.headers, ...details]
			});
		}
    }



	filename() {
		let today = new Date();
		let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
		return date + ' ' + i18next.t("task-group-reports") + ".csv";
	}

	modifyReportItem(item) {
		item.loggedPrevPeriodHours = ('' + item.loggedPrevPeriodHours).replace('.', ',');
		item.loggedInPeriodHours = ('' + item.loggedInPeriodHours).replace('.', ',');
		item.remainingHours = ('' + item.remainingHours).replace('.', ',');

		if (item.workDays !== null && item.workDays.length > 0) {
			item.workDays.forEach((workDay) => {
				let date = workDay.date;
				let workedHours = workDay.workedHours;

				item[date] = workedHours ? workedHours.toString().replace('.', ',') : "0";
			});
		}

		return item;
	}

	prepareReportData(){
		let copyList = JSON.parse(JSON.stringify(this.props.reports));

		if (this.props.reports.length > 0 && this.props.reports[0].workDays !== null) {

			copyList.forEach((item) => {
				if (item.workDays) {
					item.workDays.forEach((workDay) => {
						let date = workDay.date;
						let workedHours = workDay.workedHours;

						item[date] = workedHours ? workedHours.toString().replace('.', ',') : "0";
					});
				}
			});
		}

		copyList.forEach((item) => this.modifyReportItem(item));
		return copyList;
	}

	render() {
		return (
			<tr>
				<th scope="col"><input className="form-control" type="text" name="programName" value={this.props.filter.programName} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
				<th scope="col"><input className="form-control" type="text" name="assignedUserName" value={this.props.filter.userName} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
				<th scope="col"><input className="form-control" type="text" name="projectCode" value={this.props.filter.projectCode} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
				<th scope="col"><input className="form-control" type="text" name="projectName" value={this.props.filter.projectName} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
				<th scope="col"><input className="form-control" type="text" name="taskCode" value={this.props.filter.taskCode} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
				<th scope="col"><input className="form-control" type="text" name="taskName" value={this.props.filter.taskName} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
				<th scope="col"></th>
				<th scope="col"></th>
				<th scope="col">
					<OverlayTrigger overlay={<Tooltip>{i18next.t("hours-report-tooltip-row1")}<br></br>{i18next.t("hours-report-tooltip-row2")}<br></br>{i18next.t("hours-report-tooltip-row3")}<br></br>{i18next.t("hours-report-tooltip-row4")}</Tooltip>}>
					<input className="form-control" type="text" name="plannedHours" value={this.props.filter.plannedHours} onChange={this.props.onFilterChange}/>
					</OverlayTrigger>
				</th>
				<th scope="col">
					<OverlayTrigger overlay={<Tooltip>{i18next.t("hours-report-tooltip-row1")}<br></br>{i18next.t("hours-report-tooltip-row2")}<br></br>{i18next.t("hours-report-tooltip-row3")}<br></br>{i18next.t("hours-report-tooltip-row4")}</Tooltip>}>
					<input className="form-control" type="text" name="loggedPrevPeriodHours" value={this.props.filter.loggedPrevPeriodHours} onChange={this.props.onFilterChange}/>
					</OverlayTrigger>
				</th>
				<th scope="col">
					<OverlayTrigger overlay={<Tooltip>{i18next.t("hours-report-tooltip-row1")}<br></br>{i18next.t("hours-report-tooltip-row2")}<br></br>{i18next.t("hours-report-tooltip-row3")}<br></br>{i18next.t("hours-report-tooltip-row4")}</Tooltip>}>
					<input className="form-control" type="text" name="loggedInPeriodHours" value={this.props.filter.loggedInPeriodHours} onChange={this.props.onFilterChange}/>
					</OverlayTrigger>
        		</th>
				<th scope="col">
					<OverlayTrigger overlay={<Tooltip>{i18next.t("hours-report-tooltip-row1")}<br></br>{i18next.t("hours-report-tooltip-row2")}<br></br>{i18next.t("hours-report-tooltip-row3")}<br></br>{i18next.t("hours-report-tooltip-row4")}</Tooltip>}>
					<input className="form-control" type="text" name="remainingHours" value={this.props.filter.remainingHours} onChange={this.props.onFilterChange}/>
					</OverlayTrigger>
				</th>
				<th scope="col"><input className="form-control" type="text" name="taskStatusName" value={this.props.filter.taskStatusName} onChange={this.props.onFilterChange}/></th>
				<th colSpan={this.props.lastColumnSpan ? this.props.lastColumnSpan : 1} scope="col">
					<CSVLink data={this.prepareReportData()}
							 headers={this.state.headers}
							 filename={this.filename()}
							 onClick={() => toast.info(i18next.t("file-csv-downloaded", {icon: false, filename: this.filename()}))}
							 separator={";"}
							 uFEFF={true}>{i18next.t("download-csv")}</CSVLink>
				</th>
			</tr>
		);
	}
}

export default TaskReportFilter;