import React, { Component } from 'react';
import ProgramService from '../../services/program';
import Utils from "../common/Utils";
import i18next from 'i18next';

class ProjectProgramList extends Component {

    constructor(props) {
		super(props);
		this.state = {
			programs: [],
		}
		this.findPrograms = this.findPrograms.bind(this);
		this.findPrograms();
	}

	componentDidUpdate(prevProps) {
    	if (prevProps.purchaseOrderCode !== this.props.purchaseOrderCode) {
			this.findPrograms(this.props.purchaseOrderCode);
		}
	}

    findPrograms(purchaseOrderCode) {
		ProgramService
		.find({findPurchaseOrderId: purchaseOrderCode} )
		.then((programs) => {
			this.setState({programs});
		})
		.catch((error) => {
			Utils.error(i18next.t("portfolio-error") + JSON.stringify(error));
		});
    }

    render() {
        let newProject = null;
        if (this.props.value === undefined) {
            newProject = <option key="" value="">&lt;Wybierz program&gt;</option>
        }
        return (
	    	<div className="form-group row">
	    		<label className="col-sm-2 control-label" htmlFor={this.props.name}>{this.props.label}</label>
	    		<div className="col-sm-6" >
	    			<select name={this.props.name} className="form-control" value={this.props.value} onChange={this.props.onChange}>
	    			    {newProject}
	    				{this.state.programs.map((program) =>
	    					<option key={program.id} value={program.id}>{program.name}</option>)}
	    			</select>
	    		</div>
	    	</div>
        );
    }
}

export default ProjectProgramList;
