import request from '../lib/request';

function get(id) {
    return request({
        url: `/teams/${id}`,
        method: 'GET'
    });
}

function find(searchParams) {
    return request({
        url: "/teams",
        method: 'GET',
        params: {
            name: searchParams.findName,
            leaderFullName: searchParams.findLeader,
            size: searchParams.findPageSize === undefined ? "50": searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy + "," + searchParams.sortDirection
        }
    });
}

function findAll() {
    return request({
        url: "/teams",
        method: 'GET'
    });
}

function create(team) {
	let data = Object.assign({}, team);
	data.id = null;
    return request({
    	url: "/teams",
    	method: 'POST',
    	data: data
    });
}

function update(team) {
    return request({
    	url: "/teams/" + team.id,
    	method: 'PUT',
    	data: team
    });
}

function updateTeamMembers(teamId, userIdList) {
    return request({
    	url: "/teamMembers/update/" + teamId,
    	method: 'POST',
    	data: userIdList
    });
}


function del(id) {
    return request({
        url: `/teams/${id}`,
        method: 'DELETE'
    });
}

function findByProjectId(projectId) {
    return request({
        url: `/teams/projects/${projectId}`,
        method: 'GET'
    });
}

function findByLeader() {
    return request({
        url: `/teams/byLeader`,
        method: 'GET'
    });
}

const TeamService = {
    find,
    findAll,
    get,
    create,
    update,
    del,
    findByProjectId,
    findByLeader,
    updateTeamMembers
};

export default TeamService;