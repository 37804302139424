import request from '../lib/request';

function get(id) {
    return request({
        url: `/skillcategories/${id}`,
        method: 'GET'
    });
}

function create(skillCategory) {
	var data = Object.assign({}, skillCategory);
	data.id = null;
    return request({
    	url: "/skillcategories",
    	method: 'POST',
    	data: data
    });
}

function update(skillCategory) {
    return request({
    	url: "/skillcategories/" + skillCategory.id,
    	method: 'PUT',
    	data: skillCategory
    });
}

function find(searchParams) {
    return request({
        url: "/skillcategories",
        method: 'GET',
        params: {
            name: searchParams.findName,
			size: searchParams.findPageSize === undefined ? "50" : searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy+","+searchParams.sortDirection
        }
    });
}

function remove(id) {
    return request({
        url: `/skillcategories/${id}`,
        method: 'DELETE'
    });
}

const SkillCategoryService = {
    get,
    create,
    update,
    find,
    remove
};

export default SkillCategoryService;