import React, { Component } from 'react';
import ProgramFilter from './ProgramFilter';
import ProgramListItem from './ProgramListItem';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import SortableColumn from "../common/SortableColumn";
import PageableFilter from "../common/PageableFilter";
import ListTitle from "../common/ListTitle";

class ProgramList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowId: null,
            filter: {},
            copyItem: null,
            addButtonLabel: i18next.t("add-new")
        };

        this.selectRow = this.selectRow.bind(this);
        this.findPrograms = this.findPrograms.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onCreateProgram = this.onCreateProgram.bind(this);
        this.onUpdateProgram = this.onUpdateProgram.bind(this);
        this.onSortOrder = this.onSortOrder.bind(this);
        this.copyAnItem = this.copyAnItem.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
    }

    selectRow(key) {
        this.setState((prevState) => {
            return {
                selectedRowId: key === prevState.selectedRowId ? null : key,
                addButtonLabel: i18next.t("add-new")
            };
        });
    }

    findPrograms() {
        this.props.onFindPrograms(this.state.filter);
    }

    onFilterChange(event) {
        let filter = Object.assign({}, this.state.filter);
        filter[event.target.name] = event.target.value;
        if (!["findPageNumber", "sortBy", "sortDirection"].includes(event.target.name)) {
            filter["findPageNumber"] = "0";
        }
        this.setState({ filter }, () => this.findPrograms());
    }

    onCreateProgram(program) {
        this.setState({
            selectedRowId: null, copyItem: null,
            addButtonLabel: i18next.t("add-new")
        });
    }

    onUpdateProgram(program) {
        this.setState({ program: program });
        this.findPrograms();
    }

    onSortOrder(filter) {
        this.setState({ filter: filter, selectedRowId: null }, () => this.findPrograms());
    }

    copyAnItem(program) {
        this.setState({ selectedRowId: "+", copyItem: program });
    }

    renderEmptyItem() {
        let program = { id: "+", name: "", code: "", createdBy: "", managingUserName: "", purchaseOrderId: "", plus: "plus" };
        if (this.state.copyItem !== null) {
            program = this.state.copyItem;
            program.id = "+";
        }
        return <ProgramListItem key={'+'} selected={this.state.selectedRowId} program={program} onSelect={this.selectRow} onCreate={this.onCreateProgram} onFindPrograms={this.props.onFindPrograms} filter={this.state.filter} />;
    }

    addNewItem() {
        this.setState(prevState => ({
            selectedRowId: prevState.selectedRowId === '+' ? null : '+',
            addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
        }))
    }

    render() {
        return (
            <table className="table table-sm">
                <thead>
                    <ListTitle sectionLabel={i18next.t("navbar-programs")} span={5}
                        onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel} />
                    <ProgramFilter filter={this.state.filter} onFilterChange={this.onFilterChange} resultSize={this.props.programs.length} />
                    <tr>
                        <SortableColumn name="p.id" label="Id" filter={this.state.filter} onClick={this.onSortOrder} />
                        <SortableColumn name="c.name" label={i18next.t("customer-name")} filter={this.state.filter} onClick={this.onSortOrder} />
                        <SortableColumn name="p.name" label={i18next.t("portfolio-name")} filter={this.state.filter} onClick={this.onSortOrder} />
                        <SortableColumn name="p.code" label={i18next.t("portfolio-code")} filter={this.state.filter} onClick={this.onSortOrder} />
                        <SortableColumn name="p.active" label={i18next.t("active")} filter={this.state.filter} onClick={this.onSortOrder} />
                        <SortableColumn name="m.firstName, m.lastName, m.login" label={i18next.t("managing-user")} filter={this.state.filter} onClick={this.onSortOrder} />
                        <SortableColumn name="p.purchaseOrderCode" label={i18next.t("order")} filter={this.state.filter} onClick={this.onSortOrder} />
                    </tr>
                </thead>
                <tbody>
                    {this.renderEmptyItem()}
                    {this.props.programs.map((program) =>
                        <ProgramListItem key={program.id} selected={this.state.selectedRowId} program={program} onSelect={this.selectRow} onUpdate={this.onUpdateProgram}
                            copyAnItem={this.copyAnItem} />)}
                    <PageableFilter colSpan="7" onFilterChange={this.onFilterChange}
                        findPageSize={this.state.filter.findPageSize}
                        findPageNumber={this.state.filter.findPageNumber}
                        resultSize={this.props.programs.length}
                        hidePageSize={true} />
                </tbody>
            </table>
        );
    }
}

ProgramList.propTypes = {
    onFindPrograms: PropTypes.func,
    programs: PropTypes.array,
};

export default ProgramList;