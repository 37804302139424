import request from '../lib/request';

function file(data) {
    return request({
        url: "/teryt/process/file",
        method: 'POST',
        data: data
    });
}

function getUploadProcesses() {
    return request({
        url: "/teryt/process",
        method: 'GET'
    });
}

const UploadProcessService = {
    file,
    getUploadProcesses
};

export default UploadProcessService;