import React, { Component } from 'react';
import ProjectForm from './ProjectForm.js';
import { Tab, Tabs } from 'react-bootstrap';
import i18next from 'i18next';

class ProjectTab extends Component {

    constructor(props, context) {
		super(props, context);
		this.state = {
		    activeTab: props.activeTab || "1"
		};

        this.handleSelect = this.handleSelect.bind(this);
	}

    handleSelect(selectedTab) {
        this.setState({
            activeTab: selectedTab
        });
    }

	render() {
        if (this.props.selected !== this.props.project.id) {
            return null;
        }

        let projectForm = null;
        if (this.state.activeTab === "1") {
            projectForm = <ProjectForm
                key={this.props.project.id}
                project={this.props.project}
                onCreate={this.props.onCreate}
                onUpdate={this.props.onUpdate}
                onFindProjects={this.props.onFindProjects}
                filter={this.props.filter}
                copyAnItem={this.props.copyAnItem}/>
        }

        return (
            <tr>
                <td colSpan="11">
                    <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect} className="customer-container-form">
                        <Tab eventKey="1" title={i18next.t("edit")}>
                            {projectForm}
                        </Tab>
                    </Tabs>
                </td>
            </tr>
        );
    }
}

export default ProjectTab;