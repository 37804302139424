import React, { Component } from 'react';
import i18next from 'i18next';

class FormListControl extends Component {

    render() {
        return (
            <div className= "form-group row">
                <label htmlFor={this.props.name} className="col-sm-2 control-label">{this.props.labelKey === undefined ? this.props.label : i18next.t(this.props.labelKey)}</label>
                <div className= "col-sm-6">
                    <select onChange={this.props.onChange} value={this.props.value}style={this.props.style} className="form-control" name={this.props.name}>
                        {this.props.options.map((option) =>
                        <option key = {option.id} value={option.id}>{option.name}</option>)}
                    </select>
                </div>
            </div>
        );
    }
}

export default FormListControl;