import React, {Component} from 'react';
import 'react-dates/initialize';
import DateRangePickerWrapper from '../common/DateRangePickerWrapper';
import PageableFilter from "../common/PageableFilter";
import i18next from "i18next";

class OrderFilter extends Component {

    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.props.onSubmit();
        }
    }

    render() {
        return (
            <>
                <PageableFilter colSpan="8" onFilterChange={this.props.onFilterChange}
                                            findPageSize={this.props.filter.findPageSize}
                                            findPageNumber={this.props.filter.findPageNumber}
                                            resultSize={this.props.resultSize}/>
                <tr>
                    <th scope="col"/>
                    <th scope="col"><input className="form-control" type="text" name="customerName"
                                           value={this.props.filter.customerName} onChange={this.props.onFilterChange}
                                           onKeyDown={this.onKeyDown}
                                           placeholder={i18next.t("all")}/>
                    </th>
                    {/* <th scope="col"/> */}
                    <th scope="col"><input className="form-control" type="text" name="externalId"
                                           value={this.props.filter.externalId} onChange={this.props.onFilterChange}
                                           onKeyDown={this.onKeyDown}
                                           placeholder={i18next.t("all")}/>
                    </th>
				<th scope="col " className="data-picker-container"><DateRangePickerWrapper onChange={this.props.onCalendarChangeStartDate}
                                                          dateLeftLimit={this.props.filter.startDateLeftLimit}
                                                          dateRightLimit={this.props.filter.startDateRightLimit} /></th>
                <th scope="col" className="data-picker-container" ><DateRangePickerWrapper onChange={this.props.onCalendarChangeEndDate}
                                                          dateLeftLimit={this.props.filter.endDateLeftLimit}
                                                          dateRightLimit={this.props.filter.endDateRightLimit} /></th>
                    <th scope="col"><input className="form-control" type="text" name="userFullName" onKeyDown={this.onKeyDown}
                                           value={this.props.filter.userFullName} onChange={this.props.onFilterChange}
                                           placeholder={i18next.t("all")}/>
                    </th>
                    <th/>
                    <th/>
                </tr>
            </>
        );
    }
}

export default OrderFilter;