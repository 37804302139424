import React, {Component} from 'react';
import OrderTabs from "./OrderTabs";

class OrderListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: this.props.order
        }

        this.onUpdate = this.onUpdate.bind(this);
    }

    onUpdate(order) {
        if (order.id !== this.props.order.id) {
            this.props.onSelect(this.props.order.id);
            this.props.findOrders();
        } else {
            this.setState({
                order: order
            });
        }
    }

    render() {
        if(this.props.order.id === '+' && this.props.selected !== this.props.order.id){
            return null;
        }
        let orderTabs = null;
        let rateWithCurrency = null;
        if (this.props.selected === this.props.order.id) {
              orderTabs = <OrderTabs order = {this.props.order} selected={this.props.selected} onUpdate={this.onUpdate}/>
        }
        if (typeof this.props.order.ratePerDay === "number") {
            rateWithCurrency = this.state.order.ratePerDay + "zł";
        }
        return (
            <>
                <tr className={this.state.order.plus} onClick={() => this.props.onSelect(this.state.order.id)}>
                    <td>{this.state.order.id}</td>
                    <td>{this.state.order.customerName}</td>
                    <td>{this.state.order.externalId}</td>
                    {/* <td className={"text-center"}>{this.state.order.startDate}</td> */}
                    <td>{this.state.order.startDate}</td>
                    <td>{this.state.order.endDate}</td>
                    <td>{this.state.order.userFullName}</td>
                    <td>{this.state.order.orderInDays}</td>
                    <td>{rateWithCurrency}</td>
                </tr>
                {orderTabs}
            </>
        );
    }
}

export default OrderListItem;