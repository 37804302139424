import React, {Component} from 'react';
import i18next from 'i18next';
import TimesheetService from "../../services/timesheet";
import TimesheetMonthlyTaskList from './TimesheetMonthlyTaskList';
import TimesheetButtons from './TimesheetButtons';
import {toast} from "react-toastify";
import Utils from "../common/Utils";
import timesheetMonthlyTaskList from "./TimesheetMonthlyTaskList";

export default class MonthlyContainer extends Component{

    constructor(props){
        super(props);
        this.state = {
            timesheetMonthlyTaskList: [],                                      //lista tasków o rozliczeniu miesięcznym
            tmpTimesheetMonthlyTaskList: [],
            filter : {sortBy: "taskName", sortDirection: "ASC"}
        };
        this.onSave = this.onSave.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onDataChanged = this.onDataChanged.bind(this);
        this.getSorting = this.getSorting.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.month !== this.props.month || prevProps.year !== this.props.year){
            this.getData();
        }
    }

    getData (){
        TimesheetService.getMonthly(this.props.year, this.props.month, this.state.filter.sortBy, this.state.filter.sortDirection)
            .then((response) => {
                const tmp = JSON.parse(JSON.stringify(response));
                this.setState({
                    timesheetMonthlyTaskList: response,
                    tmpTimesheetMonthlyTaskList: tmp
                })
            })
            .catch((error) => {
                Utils.error(i18next.t("timesheet-error") + JSON.stringify(error));
            })
    }


    onSave() {
        toast.info(JSON.stringify(this.props.data));

        TimesheetService.updateMonthly(this.props.year, this.props.month, this.state.tmpTimesheetMonthlyTaskList)
            .then((response) => {
                const tmp = JSON.parse(JSON.stringify(response));
                toast.info(i18next.t("timesheet-save-success"), {
                    icon: false
                  });
                this.setState({
                    timesheetMonthlyTaskList: response,
                    tmpTimesheetMonthlyTaskList: tmp
                }, this.forceUpdate(() => {this.componentDidMount()}))
            })
    }

    onReset() {
        const tmp = JSON.parse(JSON.stringify(this.state.timesheetMonthlyTaskList));
        this.setState({tmpTimesheetMonthlyTaskList: tmp});
    }

    onDataChanged(timesheetMonthlyTaskList) {
        this.setState({
            tmpTimesheetMonthlyTaskList: timesheetMonthlyTaskList
        })
    }

    getSorting (sortingObject){
        this.setState({
            filter: sortingObject
        }, () => {
            this.getData();
        })
    }

    render() {

        if (this.state.timesheetMonthlyTaskList.length !== 0) {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <TimesheetMonthlyTaskList data={this.state.tmpTimesheetMonthlyTaskList}
                                                      onUpdate={this.onDataChanged} year={this.props.year}
                                                      month={this.props.month}
                                                      getSorting={this.getSorting}
                                                      filter={this.state.filter}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TimesheetButtons onSave={this.onSave}
                                              onReset={this.onReset}
                            />
                        </div>
                    </div>
                </div>)
        }
        else {
            return <div><br/><h3 align="center">{i18next.t("task-no-tasks-to-report")}</h3></div>;
        }
    }
    
}