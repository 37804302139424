import React from "react";
import UserFilteredList from './components/user/UserFilteredList.js';
import ProgramFilteredList from './components/program/ProgramFilteredList';
import ProjectFilteredList from './components/project/ProjectFilteredList.js';
import SkillFilteredList from './components/administration/skill/SkillFilteredList';
import TaskFilteredList from './components/task/TaskFilteredList.js';
import OrderFilteredList from './components/order/OrderFilteredList.js';
import Profile from './components/profile/Profile';
import TimesheetContainer from "./components/timesheet/TimesheetContainer";
import PropTypes from "prop-types";
import AdministrationContainer from "./components/administration/AdministrationContainer";
import CustomerFilteredList from "./components/customer/CustomerFilteredList";
import ReportContainer from "./components/report/ReportContainer";
import TeamFilteredList from "./components/team/TeamFilteredList";
import ReconciliationFilteredList from "./components/reconciliation/ReconciliationFilteredList";

class Container extends React.Component {

    render() {
        if (this.props.name === 'programs') {
            return <ProgramFilteredList/>;

        } else if (this.props.name === 'projects') {
            return <ProjectFilteredList/>;

        } else if (this.props.name === 'teams') {
            return <TeamFilteredList/>;

        } else if (this.props.name === 'users') {
            return <UserFilteredList/>;
        
		} else if (this.props.name === 'adminpanel') {

            return <AdministrationContainer roles={this.props.roles}/>

        } else if (this.props.name === 'tasks') {
            return <TaskFilteredList/>;

        } else if (this.props.name === "reconciliations") {
            return <ReconciliationFilteredList/>;

        } else if (this.props.name === 'skills') {
            return <SkillFilteredList/>;

        } else if (this.props.name ==="profile") {
            return <Profile/>;
			
        } else if (this.props.name === 'timesheets') {
            return <TimesheetContainer />;
        
		} else if (this.props.name === 'customers'){
			return <CustomerFilteredList />;
        
		} else if (this.props.name === "orders") {
			return <OrderFilteredList />;
			
        } else if (this.props.name === "reports"){
            return <ReportContainer/>;
        }
        return null;
    }
}

Container.propTypes = {
    name: PropTypes.string
};

export default Container;