import React from 'react';
import SecurityService from '../../services/security';
import FormControl from "../common/FormControl";
import FormButton from "../common/FormButton";
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router';
import Utils from "../common/Utils";
import { ACCESS_TOKEN } from "../common/Constants";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #000',
        background: 'linear-gradient(rgba(29,181,254,1) 0%, rgb(2, 20, 82) 150%)'
    }
};

class ResetPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            token: this.props.match.params.token,
            tokenIsValid: null,
            newPassword: "",
            confirmPassword: "",
            passwordChanged: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setBackgroundColor = this.setBackgroundColor.bind(this);
        this.handleRedirectToMain = this.handleRedirectToMain.bind(this);
    }

    componentDidMount() {
        localStorage.removeItem(ACCESS_TOKEN);
        SecurityService
            .checkToken(this.state.token).then(
                response => {
                    this.setState({
                        tokenIsValid: true
                    })
                }).catch(error => {
                    this.setState({
                        tokenIsValid: false
                    });
                })
    }

    setBackgroundColor() {
        document.body.style.backgroundColor = "#a6a6a6";
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { newPassword, confirmPassword } = this.state;

        if (newPassword !== confirmPassword) {
            Utils.error(i18next.t("password-retype-incorrect"));
        } else if (!newPassword.match(/^(?=.*\d).{6,}$/)) {
            Utils.error(i18next.t("password-format-incorrect"));
        } else {
            SecurityService
                .resetPassword(this.state.newPassword, this.state.token)
                .then(() => {
                    this.setState({
                        passwordChanged: true
                    });
                    toast.info(i18next.t("password-changed"), {
                        icon: false
                    });
                });
        }
    }

    handleRedirectToMain = () => {
        this.props.history.push("/");
    }

    render() {
        let changed = null
        let buttonLoginPage = null
        if (this.state.passwordChanged === true) {
            changed = i18next.t("password-changed");
            buttonLoginPage = <Button variant="link" onClick={this.handleRedirectToMain} type="button" style={{ color: "#ffffff" }}>{i18next.t("sign-in-page")}</Button>
        }

        if (this.state.tokenIsValid) {
            return (
                <section id="cover">
                    <div className="intro-container">
                        <div className="intro">
                            <div><img alt="logo" src='/logo-timepeeker-blue.png' /></div>
                        </div>
                    </div>
                    <Modal isOpen={true} style={customStyles}>
                        <div className="log-window-container">
                            {this.setBackgroundColor()}
                            <h2 className="log-window-headline">{i18next.t("password-change")}</h2>
                            <h6 style={{ color: 'green' }}>{changed}</h6>
                            <form id="resetPasswordForm" className="justify-content-center log-window-form" onSubmit={this.handleSubmit}>
                                <FormControl label={i18next.t("password-new")} name="newPassword" value={this.state.newPassword} onChange={this.handleChange} type="password" style={{ backgroundColor: "#ffffff" }} />
                                <FormControl label={i18next.t("password-retype-new")} name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleChange} type="password" style={{ backgroundColor: "#ffffff" }} />
                                <div className="log-window-btns">
                                    {/* <th><FormButton type="submit" value={i18next.t("send-button")}/></th>
                                <th>{buttonLoginPage}</th> */}
                                    <FormButton type="submit" value={i18next.t("send-button")} />
                                    {buttonLoginPage}
                                </div>
                            </form>
                        </div>
                    </Modal>
                    <div className="copyrights-container">
                        <div><p className="copyrights-desc">{i18next.t("intro-copyrights")}</p></div>
                    </div>
                </section>
            );
        } else if (this.state.tokenIsValid === false) {
            return (
                <Redirect to={{ pathname: '/', tokenStatus: { valid: false } }} />
            );
        } else {
            return (
                <h2>{i18next.t("loading-data")}</h2>
            );
        }
    }
}

export default ResetPassword;