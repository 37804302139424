import React, { Component } from 'react';
import InactiveListItem from './InactiveListItem.js';
import i18next from 'i18next';

class InactiveList extends Component {

	constructor(props) {
		super(props);

		this.onUpdate = this.onUpdate.bind(this);
	}

    onUpdate(object) {
	    let copyOfObjects = this.props.inactiveObjects;
        for(let i = 0; i < copyOfObjects.length; i++){
            if(copyOfObjects[i].id === object.id){
                copyOfObjects[i] = object;
                break;
            }
        }
        this.props.onUpdate(copyOfObjects);
	}

	renderProjectColumns(){
	    if (this.props.inactiveObjects.some((object) => object.projectCode != null)){
	        return <>
	            <th scope="col">{i18next.t("project-code")}</th>
                <th scope="col">{i18next.t("project-name")}</th>
	        </>
	    }
	}

    render() {
        return (
	    	<table className="table table-sm">
	    		<thead>
	    		    <tr>
                        <th scope="col">{i18next.t("check")}</th>
                        <th scope="col">{i18next.t("id")}</th>
                        <th scope="col">{i18next.t("name")}</th>
                        <th scope="col">{i18next.t("code")}</th>
                        <th scope="col">{i18next.t("active")}</th>
                        {this.renderProjectColumns()}
	    		    </tr>
	    		</thead>
	    		<tbody>
	    			{this.props.inactiveObjects.map((object) =>
	    				<InactiveListItem key={object.id} onUpdate={this.onUpdate} object={object}/>)}
	    		</tbody>
	    	</table>
        );
    }
}

export default InactiveList;