import React, { Component } from 'react';
import AddressService from '../../services/address';
import CustomerService from '../../services/customer';
import FormControl from '../common/FormControl';
import FormButton from "../common/FormButton";
import PropTypes from 'prop-types';
import FormListControl from '../common/FormListControl';
import StreetSuggestion from './StreetSuggestion';
import PostalCodeSuggestion from './PostalCodeSuggestion';
import i18next from 'i18next';
import Utils from "../common/Utils";

class AddressForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            address: this.props.address,
            addressUsageTypes: [],
            suggestedStreets: []
        };

        this.onChange = this.onChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDeleteAddress = this.onDeleteAddress.bind(this);
        this.passStreet = this.passStreet.bind(this);
        this.passData = this.passData.bind(this);
        this.passCode = this.passCode.bind(this);
        this.getSuitableAddressUsageTypes = this.getSuitableAddressUsageTypes.bind(this);
    }

    componentDidMount() {
        if (this.state.address.id !== "+") {
            AddressService
                .getAddressById(this.props.customerId, this.state.address.id)
                .then ((response) => {
                    let address = Object.assign({}, this.state.address);
                    address.id = response.id;
                    this.setState({
                        address : response
                    });
                }).catch((error) => {
                    Utils.error(i18next.t("data-read-error") + JSON.stringify(error))
                });
        }

        CustomerService
            .getAddressUsageTypes()
            .then((response) => {
                this.setState({
                    addressUsageTypes : response
                }, () => {
                    this.setState({
                        addressUsageTypes : this.getSuitableAddressUsageTypes(this.state.addressUsageTypes)
                    }, () => {
                        if (this.state.address.id === "+") {
                            let copy = Object.assign({}, this.state.address);
                            copy.addressUsageTypeId = this.state.addressUsageTypes[0].id;
                            copy.addressUsageTypeName = this.state.addressUsageTypes[0].name;
                            this.setState({
                                address: copy
                            });
                        }
                    })

                });
            }).catch((error) => {
                Utils.error(i18next.t("data-read-error") + JSON.stringify(error))
            });
    }

    getSuitableAddressUsageTypes (addressUsageTypes){
        let suitableUsageTypes = [];
        if(this.state.address.id === '+') {
            suitableUsageTypes = addressUsageTypes.filter((aUT) => {
                    return !this.props.addresses.map((address) => address.addressUsageTypeId).includes(Number(aUT.id))
                        || Number(aUT.id) === 3
                }
            );
        } else{
            suitableUsageTypes = addressUsageTypes.filter((aUT) => {
                return !this.props.addresses.map((address) => address.addressUsageTypeId).includes(Number(aUT.id))
                    || Number(aUT.id) === 3 || this.state.address.addressUsageTypeId === Number(aUT.id)
            })
        }

        return suitableUsageTypes;
    }

    onChange(event) {
        let address = Object.assign({}, this.state.address);
        address[event.target.name] = event.target.value;
        if(event.target.name === 'addressUsageTypeId'){
            address['addressUsageTypeName'] = this.state.addressUsageTypes.find(function (element) {
                return element.id === event.target.value;
            }).name;
        }
        this.setState({address});
    }

    onReset (event){
        event.preventDefault();
        this.setState({address: this.props.address});
    }

    onSubmit (event){
        event.preventDefault();
        if (this.state.address.id === "+") {
            const add = Object.assign({}, this.state.address);
            add.id = null;
            this.setState({
                address: add
            }, () => {
                AddressService
                    .create(this.state.address, this.props.customerId)
                    .then((response) => {
                        this.setState({
                            address: response
                        }, () => this.props.onChange(response, "create"));

                    }).catch((error) => {
                        Utils.error(i18next.t("data-read-error") + JSON.stringify(error))
                    });
            });
        } else {
            AddressService
                .update(this.state.address, this.props.customerId)
                .then ((response) => {
                    this.setState({
                        address : response
                    });
                    this.props.onChange(response, "update");
                }).catch((error) => {
                    Utils.error(i18next.t("data-read-error") + JSON.stringify(error));
                });
        }
    }

    onDeleteAddress() {
        AddressService.del(this.props.customerId, this.state.address.id);
        this.props.onChange(this.state.address, "delete");
    }

    passStreet(streetName) {
        let copyAddress = Object.assign({}, this.state.address);
        copyAddress['street'] = streetName;
        this.setState({
            address: copyAddress
        });
    }

    passData(street, settl, mun, county, voiv) {
        let copyAddress = Object.assign({}, this.state.address);
        copyAddress['street'] = street;
        copyAddress['settlement'] = settl;
        copyAddress['municipality'] = mun;
        copyAddress['county'] = county;
        copyAddress['voivodeship'] = voiv;
        this.setState({
            address: copyAddress
        });
    }

    passCode(code) {
        let copyAddress = Object.assign({}, this.state.address);
        copyAddress['postalCode'] = code;
        this.setState({
            address: copyAddress
        });
    }

    renderDeleteAddress(){
        if (this.state.address.id !== "+"){
            return(
                <FormButton onClick={this.onDeleteAddress} type="button" value={i18next.t("delete-button")}/>
            );
        }
    }

    render() {
        return (
            <form id = "addressForm" style={this.props.style} onReset={this.onReset} onSubmit={this.onSubmit}>
                <FormListControl onChange={this.onChange} name="domestic" value={this.state.address.domestic}
                                 label={i18next.t("polish-foreign-address")} options={[{id: true, name: i18next.t("domestic")}, {id: false, name: i18next.t("foreign")}]}/>
                <FormListControl onChange={this.onChange} name="addressUsageTypeId" value={this.state.address.addressUsageTypeId}
                                 options={this.state.addressUsageTypes} label={i18next.t("type-of-address")}/>
                <StreetSuggestion passStreet={this.passStreet} passData={this.passData} street={this.state.address.street}/>
                <FormControl label={i18next.t("buildingNo")} name="buildingNo" onChange={this.onChange} value={this.state.address.buildingNo}/>
                <FormControl label={i18next.t("flatNo")} name="flatNo" onChange={this.onChange} value={this.state.address.flatNo}/>
                <PostalCodeSuggestion passCode={this.passCode} settlement={this.state.address.settlement}
                                      street={this.state.address.street} postalCode = {this.state.address.postalCode}/>
                <FormControl label={i18next.t("settlement")} name="settlement" onChange={this.onChange} value={this.state.address.settlement}/>
                <FormControl label={i18next.t("municipality")} name="municipality" onChange={this.onChange} value={this.state.address.municipality}/>
                <FormControl label={i18next.t("county")} name="county" onChange={this.onChange} value={this.state.address.county}/>
                <FormControl label={i18next.t("voivodeship")} name="voivodeship" onChange={this.onChange} value={this.state.address.voivodeship}/>
                <div className="row">
                    <div className="col-sm-2">&nbsp;</div>
                    <FormButton type="submit" value={i18next.t("save-button")}/>
                    <FormButton type="reset" value={i18next.t("cancel-button")}/>
                    {this.renderDeleteAddress()}
                </div>
            </form>
        );
    }
}
AddressForm.propTypes = {
    address : PropTypes.object
};

export default AddressForm;