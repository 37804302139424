import React, { Component } from 'react';
import TeamService from '../../services/team';
import i18next from 'i18next';


export default class TeamCopyMembersFromAnotherTeamList extends Component{

    constructor(props){
        super(props);
        this.state = {
            teams: []
        };
    }

    componentDidMount() {
        TeamService.findAll()
            .then((response) => {
                this.setState({
                    teams: response
                })
            })
    }


    render() {

        return (<div className="form-group row">
            <label className="col-sm-2 control-label" htmlFor={this.props.name}>{this.props.label}</label>
            <div className="col-sm-6" >
                <select name={this.props.name} className="form-control" onChange={this.props.onChange}>
                    <option>{i18next.t("don't-copy")}</option>
                    {this.state.teams.map((team) =>{
                        return <option key={team.id} value={team.id}>{team.name}</option>})}
                </select>
            </div>
        </div>)
    }

}