import request from '../lib/request';

function getPostalCodes(settlement, street) {
    return request({
        url: `/teryt/postalcodes/${settlement}/${street}`,
        method: 'GET'
    });
}

const PostalCodeService = {
    getPostalCodes
};

export default PostalCodeService;