import React, { Component } from 'react';
import ProgramForm from './ProgramForm';
import { Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class ProgramListItem extends Component {

    constructor(props) {
        super(props);

        this.onCreateProgram = this.onCreateProgram.bind(this);
        this.onUpdateProgram = this.onUpdateProgram.bind(this);
    }

    onCreateProgram(program) {
        this.props.onCreate(program);
    }

    onUpdateProgram(program) {
        this.props.onUpdate(program);
    }

    render() {
        if (this.props.program.id === '+' && this.props.selected !== this.props.program.id) {
            return null;
        }
        let programForm = null;
        if (this.props.selected === this.props.program.id) {
            programForm = <tr>
                <td colSpan="7">
                    <Tabs className="tab-container-editform">
                        <Tab eventKey="1" title={i18next.t("edit")}>
                        </Tab>
                    </Tabs>
                    <ProgramForm
                        key={this.props.program.id}
                        program={this.props.program}
                        style={this.props.selected === this.props.program.id ? { display: 'block' } : { display: 'none' }}
                        onCreate={this.onCreateProgram}
                        onUpdate={this.onUpdateProgram}
                        onFindPrograms={this.props.onFindPrograms}
                        filter={this.props.filter}
                        copyAnItem={this.props.copyAnItem} />
                </td>
            </tr>
        }
        return <>
            <tr className={this.props.program.plus} onClick={() => this.props.onSelect(this.props.program.id)}>
                <td>{this.props.program.id}</td>
                <td>{this.props.program.customerName}</td>
                <td>{this.props.program.name}</td>
                <td>{this.props.program.code}</td>
                <td>{this.props.program.active ? "✔" : ""}</td>
                <td>{this.props.program.managingUserName}</td>
                <td>{this.props.program.purchaseOrderCode}</td>
            </tr>
            {programForm}
        </>
    }
}

ProgramListItem.propTypes = {
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    onSelect: PropTypes.func,
    program: PropTypes.object,
    selected: PropTypes.number
};

export default ProgramListItem;