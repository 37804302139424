import React, { Component } from 'react';
import PageableFilter from "../common/PageableFilter";
import PropTypes from "prop-types";
import i18next from 'i18next';

class CustomerFilter extends Component {

    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.props.onSubmit();
        }
    }

    render() {
        return (
            <>
                <PageableFilter colSpan="5"
                                findPageSize={this.props.filter.findPageSize}
                                findPageNumber={this.props.filter.findPageNumber}
                                onSubmit={this.props.onSubmit}
                                onFilterChange={this.props.onFilterChange}
                                resultSize={this.props.resultSize} />
                <tr>
                    <th/>
                    <th scope="col"><input className="form-control" type="text" name="findName" value={this.props.filter.findName} onChange={this.props.onFilterChange}
                                           onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
                    <th scope="col"><input className="form-control" type="text" name="findExternalId" value={this.props.filter.findExternalId} onChange={this.props.onFilterChange}
                                           onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
                    <th scope="col"><input className="form-control" type="text" name="findNip" value={this.props.filter.findNip} onChange={this.props.onFilterChange}
                                           onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
                    <th scope="col"><input className="form-control" type="text" name="findManagingUserFullName" value={this.props.filter.findAssignedUserFullName} onChange={this.props.onFilterChange}
                                           onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
                </tr>
            </>
        );
    }
}

CustomerFilter.propTypes = {
    filter: PropTypes.object,
    resultSize: PropTypes.number,
    onFilterChange: PropTypes.func,
    onSubmit: PropTypes.func
};

export default CustomerFilter;