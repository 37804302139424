import CustomerService from "../../services/customer";
import Suggestion from '../common/Suggestion';

class CustomerCodeFormControl extends Suggestion {



    componentDidMount() {
        CustomerService
            .findAll()
            .then((result) => {
                this.setState({items: result});
            });
    }

    onSuggestionSelected(event, {suggestion}) {
        this.props.onChangeSuggestionId(suggestion.externalId);
    }


}

export default CustomerCodeFormControl;
