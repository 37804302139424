import React, {Component} from 'react';
import FormControl from '../common/FormControl.js';
import FormButton from '../common/FormButton.js';
import OrderService from '../../services/order';
import CustomerFormControl from "../common/CustomerFormControl";
import FilteredCustomerFormControl from "../common/FilteredCustomerFormControl";
import UserService from "../../services/user";
import {toast} from 'react-toastify';
import i18next from 'i18next';
import Utils from "../common/Utils";
import UserSuggestion from '../common/UserSuggestion';

class OrderForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: this.props.order,
            orderCustomerList: null,
            loggedInUser: {roles: []}
        };

        this.onChange = this.onChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getCustomerFormControl = this.getCustomerFormControl.bind(this);
        this.onChangeCustomerId = this.onChangeCustomerId.bind(this);
        this.onChangeUserId = this.onChangeUserId.bind(this);
    }

    componentDidMount() {
        UserService
            .getLoggedInUser()
            .then((response) => {
                this.setState({
                    loggedInUser: response
                }, () => this.loadOrder())
            }).catch((error) => {
                Utils.error(i18next.t("user-read-error") + JSON.stringify(error));
        });
    }

    loadOrder() {
        if (this.props.order.id !== "+") {
            OrderService
                .get(this.props.order.id)
                .then((response) => {
                    this.setState({
                        order: response
                    });
                }).catch((error) => {
                    Utils.error(i18next.t("data-read-error") + JSON.stringify(error));
                });
        }
    }

    onChange(event) {
        let order = Object.assign({}, this.state.order);
        order[event.target.name] = event.target.value;
        this.setState({order});
    }

    onChangeCustomerId(customerId){
        let order ={...this.state.order};
        order['customerId'] = customerId;
        this.setState({
            order
        })
    }

    onChangeUserId(userId){
        let order ={...this.state.order};
        order['userId'] = userId;
        this.setState({
            order
        })
    }

    resetForm() {
        this.loadOrder();
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.order.id === "+") {
            OrderService
                .create(this.state.order)
                .then((response) => {
                    this.setState({order: response}, () => {
                        this.props.onUpdate(this.state.order)
                        toast.info(i18next.t("order-add-success"), {
                            icon: false
                          });
                    });
                }).catch((error) => {
                    Utils.error(error.data.message);
                });
        } else {
            OrderService
                .update(this.state.order)
                .then((response) => {
                    toast.info(i18next.t("order-update-success"), {
                        icon: false
                      });
                    this.setState({order: response}, () => {
                        this.props.onUpdate(this.state.order)
                    });
                }).catch((error) => {
                    Utils.error(error.data.message);
                });
        }
    }

    getCustomerFormControl() {
        if (this.state.loggedInUser.roles.some(e => e === "ROLE_SUPERUSER")) {
            return <CustomerFormControl label={i18next.t("customer-name")}
                                        required={true}
                                        onChangeSuggestionId={this.onChangeCustomerId}
                                        inputPlaceholder={i18next.t("choose-customer")}
                                        chosenCustomerId={this.state.order.customerId}/>
        } else {
            return <FilteredCustomerFormControl label={i18next.t("customer")}
                                                required={true}
                                                onChangeSuggestionId={this.onChangeCustomerId}
                                                inputPlaceholder={i18next.t("choose-customer")}
                                                chosenCustomerId={this.state.order.customerId}/>
        }
    }

    render() {
        return (
            <div className="">
                <form id="projectForm" style={this.props.style} onSubmit={this.onSubmit}>
                    {this.getCustomerFormControl()}
                    <FormControl label={i18next.t("external-id")} name="externalId" value={this.state.order.externalId}
                                 onChange={this.onChange} required={true}/>
                    <FormControl label={i18next.t("start-date")} type="date" name="startDate" value={this.state.order.startDate}
                                 onChange={this.onChange} required={true}/>
                    <FormControl label={i18next.t("end-date")} type="date" name="endDate" value={this.state.order.endDate}
                                 onChange={this.onChange}/>
                    <UserSuggestion required={true}
                                    label={i18next.t("managing-user")}
                                    onChangeSuggestionId={this.onChangeUserId}
                                    inputPlaceholder={i18next.t("choose-the-user")}
                                    chosenUserId={this.state.order.userId}/>
                    <FormControl label={i18next.t("daywork")} type="number" name="orderInDays" value={this.state.order.orderInDays}
                                 onChange={this.onChange}/>
                    <FormControl label={i18next.t("pay-rate")} type="number" name="ratePerDay" value={this.state.order.ratePerDay}
                                 onChange={this.onChange}/>
                    <div className="row buttons-to-left btn-grey-container btn-overlap-container">
                        <div className="col-sm-2 "></div>
                        <FormButton type="submit" value={i18next.t("save-button")}/>
                        <FormButton type="button" onClick={this.resetForm} value={i18next.t("cancel-button")}/>
                    </div>
                </form>
            </div>
        )
    }
}

export default OrderForm;
