import React from 'react';
import Autosuggest from 'react-autosuggest';
import Theme from '../customer/autosuggestStyle';
import UserService from '../../services/user';
import i18next from 'i18next';
import Utils from "../common/Utils";

class TeamLeaderControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.team.leaderName ? this.props.team.leaderName : "",
            suggestions: []
        };

        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    }

    getSuggestions(value) {
            UserService
                .findSuggestions(value)
                .then((response) => this.setState({suggestions: response}))
                .catch((error) => {
                    Utils.error(i18next.t("suggested-leaders-error") + JSON.stringify(error));
                });

    }

    onChange(event, {newValue}) {
        if (newValue.length === 0) {
            this.props.leaderClear();
        }
        this.setState({
            value: newValue
        });
    }

    onSuggestionsFetchRequested({value}) {
        this.getSuggestions(value);
    }

    onSuggestionsClearRequested() {
        this.setState({suggestions: []});
    }

    renderSuggestion(suggestion) {
        return <div>{suggestion.name}</div>
    }

    getSuggestionValue(suggestion) {
        return suggestion.name;
    }


    render() {
        const {value, suggestions} = this.state;
        const inputProps = {
            placeholder: i18next.t("enter-the-username"),
            value,
            onChange: this.onChange
        };

        return (
            <div className="form-group row">
                <label className="col-sm-2 control-label" htmlFor="source">{i18next.t("choose-the-leader")}</label>
                <div className="col-sm-6">
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        onSuggestionSelected={this.props.changeState}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputProps}
                        shouldRenderSuggestions={() => true}
                        highlightFirstSuggestion={true}
                        theme={Theme}
                        id="source"/>
                </div>
            </div>
        );
    }
}

export default TeamLeaderControl;
