import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

export default class TimesheetCalendar extends Component {

    constructor(props) {
        super(props);

        this.onMonthChange = this.onMonthChange.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.onMonthDecrement = this.onMonthDecrement.bind(this);
        this.onMonthIncrement = this.onMonthIncrement.bind(this);
    }

    onMonthChange(event) {
        this.props.onChange(this.props.year, event.target.value);
    }

    onYearChange(event) {
        this.props.onChange(event.target.value, this.props.month);
    }

    onMonthDecrement() {
        const monthDec = this.props.month - 1;
        if (monthDec === 0) {
            const yearDec = this.props.year - 1;
            this.props.onChange(yearDec, 12);
        } else {
            this.props.onChange(this.props.year, monthDec);
        }
    }

    onMonthIncrement() {
        const monthInc = Number(this.props.month) + Number(1);
        if (monthInc === 13) {
            const yearInc = Number(this.props.year) + Number(1);
            this.props.onChange(yearInc, 1);
        } else {
            this.props.onChange(this.props.year, monthInc);
        }
    }

    render() {
        const date = new Date();
        const thisYear = date.getFullYear();
        const yearBefore = thisYear - 1;
        const nextYear = Number(thisYear) + Number(1);
        const twoYearsAfter = Number(thisYear) + Number(2);
        return (
            <div className="timereport--btn-container">
                <button onClick={this.onMonthDecrement} className="timereport--btn">&lt;&lt;</button>
                <select onChange = {this.onMonthChange} value={this.props.month} className="timereport--select">
                    <option value="1">{i18next.t("january")}</option>
                    <option value="2">{i18next.t("february")}</option>
                    <option value="3">{i18next.t("march")}</option>
                    <option value="4">{i18next.t("april")}</option>
                    <option value="5">{i18next.t("may")}</option>
                    <option value="6">{i18next.t("june")}</option>
                    <option value="7">{i18next.t("july")}</option>
                    <option value="8">{i18next.t("august")}</option>
                    <option value="9">{i18next.t("september")}</option>
                    <option value="10">{i18next.t("october")}</option>
                    <option value="11">{i18next.t("november")}</option>
                    <option value="12">{i18next.t("december")}</option>
                </select>
                <button onClick={this.onMonthIncrement} className="timereport--btn">&#62;&#62;</button>
                <select onChange={this.onYearChange} value={this.props.year} className="timereport--select">
                    <option value={yearBefore}>{yearBefore}</option>
                    <option value={thisYear}>{thisYear}</option>
                    <option value={nextYear}>{nextYear}</option>
                    <option value={twoYearsAfter}>{twoYearsAfter}</option>
                </select>
            </div>
        );
    }
}

TimesheetCalendar.propTypes = {
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};