import React, { Component } from 'react';
import DictionaryService from '../../../services/dictionary.js';
import i18next from 'i18next';

class SkillCategoryFormControl extends Component {

    constructor(props) {
		super(props);
		this.state = {
			skillCategories: [],
			skill: props.skill
		}
	}

    componentDidMount() {
		DictionaryService
		    .getSkillCategories()
			.then((result) => {
				this.setState({skillCategories: result});
			});
	}

    render() {
        let skillCategoryControl = null;
        if (this.props.value === undefined) {
            skillCategoryControl = <option key="" value="">{i18next.t("skill-skill-category-select-option")}</option>
        }
        return (
	    	<div className="row">
	    	    <div className="col-sm-2">
                	<label className="col-form-label" htmlFor={this.props.name}>{this.props.label}</label>
                </div>
	    		<div className="col-sm-4" >
	    		    <select className="custom-select" name={this.props.name} value={this.props.value} onChange={this.props.onChange}>
	    				{skillCategoryControl}
	    		        {this.state.skillCategories.map((skillCategory) =>
	    					<option key={skillCategory.id} value={skillCategory.id}>{skillCategory.name}</option>)
                        }
                    </select>
	    		</div>
	    	</div>
        );
    }
}

export default SkillCategoryFormControl;