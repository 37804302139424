import request from '../lib/request';

function get(year, month, sortBy, sortDirection) {
    return request({
        url: "/timesheet/" + year + "/" + month,
        method: 'GET',
        params: {
            sortBy: sortBy,
            sortDirection: sortDirection
        }
    });
}

function getMonthly(year, month, sortBy, sortDirection) {
    return request({
        url: "/timesheet/monthly/" + year + "/" + month,
        method: 'GET',
        params: {
            sortBy: sortBy,
            sortDirection: sortDirection
        }
    });
}

function update(year, month, timesheet) {
    return request({
        url: "/timesheet/" + year + "/" + month,
        method: 'POST',
        data: timesheet
    });
}

function updateMonthly(year, month, timesheetMonthlyTaskList) {
    return request({
        url: "/timesheet/monthly/" + year + "/" + month,
        method: 'POST',
        data: timesheetMonthlyTaskList
    });
}

const TimesheetService = {
    get,
    update,
    getMonthly,
    updateMonthly
};

export default TimesheetService;