import React, {Component} from 'react';
import TimesheetCalendar from './TimesheetCalendar';
import CalendarService from '../../services/calendar';
import TimesheetService from '../../services/timesheet';
import i18next from 'i18next';
import {Tab, Tabs} from "react-bootstrap";
import DailyContainer from './DailyContainer';
import MonthlyContainer from './MonthlyContainer';
import Utils from "../common/Utils";

export default class TimesheetContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            isLoading: true
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.calendarHasChanged = this.calendarHasChanged.bind(this);
    }

    componentDidMount() {
        CalendarService.get()
            .then((response) => {
                this.setState({
                    year: response.year,
                    month: response.month,
                    isLoading: false
                })
            }).catch((error) => {
                Utils.error(i18next.t("date-error") + JSON.stringify(error));
            });
    }

    handleSelect(selectedTab) {
        this.setState({
            activeTab : selectedTab
        });
    }

    calendarHasChanged(year, month) {
        TimesheetService.get(year, month, null, null)
            .then(() => {
                this.setState({
                    year: year,
                    month: month
                })
            }).catch((error) => {
                Utils.error(i18next.t("timesheet-error") + JSON.stringify(error));
            });

    }

    renderDailyContainer() {
        if (this.state.activeTab === "1") {
            return <DailyContainer year={this.state.year} month={this.state.month}/>
        }
    }

    renderMonthlyContainer() {
        if (this.state.activeTab === "2"){
            return <MonthlyContainer year={this.state.year} month={this.state.month}/>
        }
    }

    render() {
        if (this.state.isLoading) {
            return null;
        }

        return <div className="main-timesheets-container">
            <div className="row">
                <div className="col-md-12">
                    <h2>{i18next.t("navbar-timesheets")}</h2>
                </div>
                <div className="col-md-12 text-center">
                    <TimesheetCalendar
                        onChange={this.calendarHasChanged}
                        month={this.state.month}
                        year={this.state.year}
                    />
                </div>
            </div>
            <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                <Tab eventKey="1" title={i18next.t("daily-settlement-type")}>
                    {this.renderDailyContainer()}
                </Tab>
                <Tab eventKey="2" title={i18next.t("monthly-settlement-type")}>
                    {this.renderMonthlyContainer()}
                </Tab>
            </Tabs>

        </div>
    }
}