import React, {Component} from 'react';
import uploadProcessService from '../../../services/uploadProcess.js';
import Dropzone from 'react-dropzone';
import {ContainerTERYT} from "../../../styledDropzone";
import {toast} from 'react-toastify';
import i18next from 'i18next';

class UploadDropzone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            info: {text: null, type: null}
        }
    }

    handleUploadFile = (files) => {
        files.forEach(file => {
            var formData = new FormData();
            formData.append('file', file);
            uploadProcessService
                .file(formData)
                .then(() => {
                    toast.info(i18next.t("refresh-table"), {
                        icon: false
                      });
                }).catch((error) => {
                    toast.error(error.data.message, {
                        icon: false
                      });
                });
        })
    };

    render() {
        return (
            <div>
                <div className="text-center mt-5">
                    <Dropzone onDrop={acceptedFile => this.handleUploadFile(acceptedFile)}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <ContainerTERYT{...getRootProps({})}>
                                    <input {...getInputProps()} />
                                    <p>{i18next.t("dropzone-info-administration")}</p>
                                </ContainerTERYT>
                            </section>
                        )}
                    </Dropzone>
                    <h3 style={{color: this.state.info.color}}>{this.state.info.text}</h3>
                </div>
            </div>
        )
    }
}

export default UploadDropzone