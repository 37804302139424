import React from 'react';
import UserList from './UserList.js';
import UserService from '../../services/user.js';
import ErrorHandlerComponent from "../common/error/ErrorHandlerComponent";

class UserFilteredList extends ErrorHandlerComponent {
	
	constructor(props) {
		super(props);
		this.state = {
		    userData: []
		};

		this.findUsers = this.findUsers.bind(this);
	}

    componentDidMount() {
        this.findUsers({});
    }

	findUsers(filter) {
		UserService
			.find(filter)
			.then((userData) => {
				this.setState({userData});				
			})
			.catch((error) => {
				this.setState({error});
			});
	}
	
    doRender() {
        return (
        	<div>
	    	    <UserList users={this.state.userData} onFindUsers={this.findUsers}/>
	    	</div>
        );
    }
}

export default UserFilteredList;