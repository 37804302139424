import React, {Component} from 'react';
import UserService from "../../services/user";
import SecurityService from "../../services/security";
import FormControl from "../common/FormControl";
import Label from "../common/Label";
import i18next from 'i18next';
import Utils from "../common/Utils";

class UserInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedInUser: {},
			userRolesDescriptions: []
        }
    }

    componentDidMount() {
        UserService
            .getLoggedInUser()
            .then((response) => {
                this.setState({
                    loggedInUser : response
                })
            }).catch((error) => {
                Utils.error(i18next.t("user-read-error") + JSON.stringify(error));
        });
		SecurityService
		    .getUserRolesDescriptions()
            .then((response) => {
                this.setState({
                    userRolesDescriptions: response
                })
            }).catch((error) => {
                Utils.error(i18next.t("data-read-error" + JSON.stringify(error)));
        });
    }

    render() {
        return (
            <>
                <div className="profile-box-container">
                    <h4 className="profile-main-headline"><Label messageKey="profile-user-data-title"/></h4>
                    <hr className="my-headline"/>
                    <FormControl value={this.state.loggedInUser.firstName}
                                labelKey="profile-user-first-name" name="firstName" className="form-control-plaintext" disabled={true}/>
                    <FormControl value={this.state.loggedInUser.lastName}
                                labelKey="profile-user-last-name" name="lastName" className="form-control-plaintext" disabled={true}/>
                    <FormControl value={this.state.loggedInUser.login}
                                labelKey="profile-user-login" name="login" className="form-control-plaintext" disabled={true}/>
                    <FormControl value={this.state.loggedInUser.email}
                                labelKey="profile-user-email" naCodeServiceActivatorme="email" className="form-control-plaintext" disabled={true}/>
                    {/* <hr className="my-4"/> */}
                    <h4 className="profile-main-headline profile-main-headline-2row"><Label messageKey="profile-user-roles-title"/></h4>
                    <hr className="my-headline"/>
                    {this.state.userRolesDescriptions.map((role) =>
                        <span>{role}, </span>
                    )}
                    {/* <hr className="my-4"/> */}
                </div>
                
            </>
        )
    }
}

export default UserInfo;