import React, {Component} from 'react';
import {CSVLink} from "react-csv";
import i18next from 'i18next';
import {toast} from "react-toastify";

export default class TaskGroupReportAdditionalFilter extends Component {



    getHeaders (){
        let headers = [
            {label: i18next.t("project-name"), key: "projectName"},
            {label: i18next.t("project-code"), key: "projectCode"}
        ];
        this.props.users.forEach((user) =>{
            const elementToPush = {
            };
            elementToPush.label = user;
            elementToPush.key = user;
            headers.push(elementToPush);
        });
        headers.push({
            label: i18next.t("summary"),
            key: "summary"
        });
        return headers;
    }

    getNonDuplicateReports (){
        let nonDuplicateReports = [];
        this.props.reports.forEach((report) => {
            if(!nonDuplicateReports.map(nonDuplicate => nonDuplicate.projectCode)
                .includes(report.projectCode)){
                nonDuplicateReports.push(report);
            }
        });
        return nonDuplicateReports;
    }

    prepareReportData() {
        let reportData = [];
        this.getNonDuplicateReports().forEach((report) => {
            let reportDataItem = {
                projectName: report.projectName,
                projectCode: report.projectCode
            };
            this.props.users.forEach((user) => {
                const filteredReport = this.props.reports.filter((rep) => {
                    return rep.projectCode === report.projectCode && rep.assignedUserName === user
                })[0];
                filteredReport ? reportDataItem[user] = filteredReport.loggedInPeriodHours : reportDataItem[user] = ""
            });
            reportDataItem["summary"] = Object.values(reportDataItem)
                .filter(value => !isNaN(value))
                .reduce((previousValue, currentValue) => {
                    return Number(previousValue) + Number(currentValue)
                }, 0);
            reportData.push(reportDataItem);
        });
        let sumDataItem = {
            projectName: i18next.t("summary"),
            projectCode: ""
        };
        for (let i = 0; i < this.props.users.length; i++) {
            sumDataItem[this.props.users[i]] = this.props.sums[i]
        }
        sumDataItem["summary"] = this.props.sums.reduce((previousValue, currentValue) => {
            return Number(previousValue) + Number(currentValue)
        }, 0);
        reportData.push(sumDataItem);
        return reportData;
    }

    filename() {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
        let name = date + ' ' + i18next.t("task-reports") + ".csv";
        return name;
    }

    render() {
        return (
            <tr>
                <th scope="col">
                    <input className="form-control"
                           placeholder={i18next.t("all")}
                           name="projectName"
                           value={this.props.filter.projectName}
                           onChange={this.props.onFilterChange}/>
                </th>
                <th scope="col">
                    <input className="form-control"
                           placeholder={i18next.t("all")}
                           name="projectCode"
                           value={this.props.filter.projectCode}
                           onChange={this.props.onFilterChange}/>
                </th>
                {this.props.users.map((user, index) => <th scope="col" key={index}/>)}
                <th scope="col">
                    <CSVLink data={this.prepareReportData()}
                             headers={this.getHeaders()}
                             filename={this.filename()}
                             separator=";"
                             onClick={() => toast.info(i18next.t("file-csv-downloaded", {icon: false, filename: this.filename()}))}
                             uFEFF={true}>
                        {i18next.t("download-csv")}
                    </CSVLink>
                </th>
            </tr>
        );
    }
}