import OrderService from "../../services/order";
import Suggestion from '../common/Suggestion';
import React from "react";
import i18next from 'i18next';

class ProgramPurchaseOrderFormControl extends Suggestion {

    componentDidMount() {
        OrderService
            .findOrdersByCustomerManagingUser()
            .then((result) => {
                this.setState(
                    {
                        items: result,
                        value: this.props.chosenExternalId ? this.props.chosenExternalId : ''
                    }, () => {
                        if(this.props.removePossible){
                            let items = [...this.state.items];
                            items.unshift({externalId: null});
                            this.setState({items});
                        }
                    });
            });
    }

    getSuggestionValue (suggestion)  {
        if(suggestion.externalId === null){
            return i18next.t("none");
        }
        else {
            return suggestion.externalId;
        }
    }

    renderSuggestion (suggestion){
        if(suggestion.externalId === null){
            return (
                <div>
                    &lt;{i18next.t("none")}&gt;
                </div>
            );
        }
        else {
            return (
                <div>
                    {suggestion.externalId}
                </div>
            );
        }
    }

    onSuggestionsFetchRequested ({value}){
        const inputValue = value.trim().toLowerCase();
        this.setState({
            suggestions: this.state.items.filter((item) => {
                if(item.externalId) {
                    return item.externalId.toLowerCase().includes(inputValue)
                } else {
                    return true;
                }
            })
        })
    }

    onSuggestionSelected(event, {suggestion}) {
        this.props.onChangeSuggestionId(suggestion.externalId);
    }
}

export default ProgramPurchaseOrderFormControl;
