import {Tab, Tabs} from "react-bootstrap";
import React, {Component} from 'react';
import UploadDropzone from './teryt/UploadDropzone'
import SkillFilteredList from './skill/SkillFilteredList'
import HolidayList from './holiday/HolidayList'
import FileStatusTable from './teryt/FileStatusTable';
import i18next from 'i18next';
import UploadInstruction from "./teryt/UploadInstruction";
import TaskStatusFilteredList from "./taskstatus/TaskStatusFilteredList";
import SkillCategoryFilteredList from "./skillCategory/SkillCategoryFilteredList";

class AdministrationContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.roles.includes("ROLE_SUPERUSER") ? "1" : "2"
        };
        this.handleSelect = this.handleSelect.bind(this);
    }


    handleSelect(selectedTab) {
        this.setState({
            activeTab: selectedTab
        });
    }

    getUploadTabContent() {
        if (this.state.activeTab === "1") {
            return <>
                <FileStatusTable/>
                <UploadInstruction/>
                <UploadDropzone/>
            </>
        }
    }

    getSkillsTabContent() {
        if (this.state.activeTab === "2") {
            return <SkillFilteredList/>
        }
    }

    getHolidayTabContent() {
        if (this.state.activeTab === "4") {
            return <HolidayList/>
        }
    }

    getSkillCategories() {
        if (this.state.activeTab === "3") {
            return <SkillCategoryFilteredList/>
        }
    }

    getTaskStatuses() {
        if (this.state.activeTab === "5") {
            return <TaskStatusFilteredList/>
        }
    }

    renderContent(){
        if(this.props.roles.includes("ROLE_SUPERUSER")){
            return (
                <>
                <h2 className="headline-main-style">{i18next.t("navbar-configuration")}</h2>
                <Tabs className="nav-tabs-exception" activeKey={this.state.activeTab} onSelect={this.handleSelect}>
                    <Tab eventKey="1" title={i18next.t("administration-panel-tab-title")}>
                        {this.getUploadTabContent()}
                    </Tab>
                    <Tab eventKey="2" title={i18next.t("configurations-tab-skill")}>
                        {this.getSkillsTabContent()}
                    </Tab>
                    <Tab eventKey="3" title={i18next.t("skill-category")}>
                        {this.getSkillCategories()}
                    </Tab>
                    <Tab eventKey="4" title={i18next.t("holiday-title")}>
                        {this.getHolidayTabContent()}
                    </Tab>
                    <Tab eventKey="5" title={i18next.t("task-status")}>
                        {this.getTaskStatuses()}
                    </Tab>
                </Tabs>
                </>
            );
        } else {
            return (

            <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect}>
            <Tab eventKey="2" title={i18next.t("configurations-tab-skill")}>
                {this.getSkillsTabContent()}
            </Tab>
            <Tab eventKey="3" title={i18next.t("skill-category")}>
                {this.getSkillCategories()}
            </Tab>
            </Tabs>
            )
        }
    }

    render() {
        return (
            this.renderContent()
        );
    }
}

export default AdministrationContainer;