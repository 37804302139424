import request from '../lib/request';

function getRoles() {
    return request({
        url: "/taskReports/getRoles",
        method: 'GET'
    });
}

function findReports(searchParams, details, projectNotActive, userNotActive) {
    return request({
        url: "/taskReports/generate",
        method: 'GET',
	    params: {
	        roleName: searchParams.role,
		    startDate: searchParams.startDateLeftLimit,
	        endDate: searchParams.startDateRightLimit,
	        details: details,
            projectNotActive: projectNotActive,
            userNotActive: userNotActive
	    }
    });
}

const TaskReportService = {
  getRoles,
  findReports
};

export default TaskReportService;