import React, { Component } from 'react';
import FormControl from '../../common/FormControl.js';
import FormButton from '../../common/FormButton.js';
import SkillService from '../../../services/skill.js'
import SkillCategoryFormControl from './SkillCategoryFormControl.js';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Utils from "../../common/Utils";

class SkillForm extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			skill: props.skill
		};
		
		this.onChange = this.onChange.bind(this);
		this.onReset = this.onReset.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.renderDeleteButton = this.renderDeleteButton.bind(this);
	}
	
	componentDidMount() {
		if (this.props.skill.id !== "+") {
			SkillService
				.get(this.props.skill.id)
				.then((response) => {
					this.setState({skill: response});					
				})
				.catch((error) => {
					Utils.error(i18next.t("save-error") + JSON.stringify(error));
				});
		}
	}

	onChange(event) {
		let skill = Object.assign({}, this.state.skill);
		skill[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		this.setState({skill});
	}
	

	onReset(event) {
		this.setState({ skill: this.props.skill });
	}

	onSubmit(event) {
		event.preventDefault();
		if (this.state.skill.id === "+") {
			SkillService
				.create(this.state.skill)
				.then((response) => {
					this.props.onCreate(response);
					toast.info(i18next.t("skill-add-success"), {
						icon: false
					  });
					this.props.onFindSkills(this.props.filter);
				})
				.catch((error) => {
					this.setState({error});
					Utils.error(error.data.message);
				});
		} else {
			SkillService
				.update(this.state.skill)
				.then((response) => {
					toast.info(i18next.t("skill-update-success"), {
						icon: false
					  });					
					this.setState({skill: response});	
					this.props.onUpdate(response);
				})
				.catch((error) => {
					this.setState({error});
					Utils.error(error.data.message);
				});
		}
	}

	onDeleteSkill = () => {

		if(this.state.skill.id !== "+"){
			SkillService.del(this.state.skill.id)
				.then(() => {
					toast.info(i18next.t("skill-delete-success"), {
						icon: false
					  });
					this.props.onFindSkills(this.props.filter);
				})
				.catch((error) => {
					Utils.error(error.data.message);
				})
		}
	};

	renderDeleteButton = () => {
		if(this.props.loggedIn.roles.includes("ROLE_SUPERUSER")){
			return (
				<FormButton type="button" value={i18next.t("delete-button")} onClick={this.onDeleteSkill}/>
			);
		}
};

  render() {
    return (

		<form id="skillForm" style={this.props.theme} onReset={this.onReset} onSubmit={this.onSubmit}>
		
			<FormControl label={i18next.t("configurations-skill-skill")} name="name" value={this.state.skill.name} onChange={this.onChange} />
			<SkillCategoryFormControl label={i18next.t("configurations-skill-skill-category")} name="skillCategoryId" value={this.state.skill.skillCategoryId} onChange={this.onChange} />

			<div className="row add-space-top-to-btn buttons-to-left btn-grey-container btn-overlap-container">
				<div className="col-sm-2">&nbsp;</div>
				<FormButton type="submit" value={i18next.t("save-button")}/>
				<FormButton type="reset" value={i18next.t("cancel-button")}/>
				{this.renderDeleteButton()}
			</div>
		</form>
    );
  }
}

export default SkillForm;