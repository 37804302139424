import React, {Component} from 'react';
import FormControl from '../common/FormControl.js';
import FormButton from '../common/FormButton.js';
import {toast} from 'react-toastify';
import i18next from 'i18next';
import ReconciliationService from "../../services/reconciliation";
import CustomerCodeFormControl from "../common/CustomerCodeFormControl";
import UserService from "../../services/user";
import FilteredCustomerCodeFormControl from "../common/FilteredCustomerCodeFormControl";
import Utils from "../common/Utils";

class ReconciliationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reconciliation: this.props.reconciliation,
            loggedInUser: {roles: []},
            isLoading: true
        };

        this.onChange = this.onChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getCustomerFormControl = this.getCustomerFormControl.bind(this);
        this.onCustomerCodeChange = this.onCustomerCodeChange.bind(this);
    }

    componentDidMount() {
        UserService
            .getLoggedInUser()
            .then((response) => {
                this.setState({
                    loggedInUser: response,
                }, () => this.loadReconciliation())
            }).catch((error) => {
                Utils.error(i18next.t("user-read-error") + JSON.stringify(error))
            });
    }

    loadReconciliation() {
        if (this.props.reconciliation.id !== "+") {
            ReconciliationService
                .get(this.props.reconciliation.id)
                .then((response) => {
                    this.setState({reconciliation: response});
                }).catch((error) => {
                    Utils.error(i18next.t("single-task-error") + JSON.stringify(error));
                })
                .finally(() => this.setState( {isLoading: false}));
        } else {
            this.setState( {isLoading: false});
        }
    }

    onChange(event) {
        this.setState({
            reconciliation: {
                ...this.state.reconciliation,
                [event.target.name]: event.target.value
            }
        });
    }

    resetForm() {
        this.setState({reconciliation: this.props.reconciliation});
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.reconciliation.id === "+") {
            ReconciliationService
                .create(this.state.reconciliation)
                .then((response) => {
                    this.props.onUpdate()
                    toast.info(i18next.t("create-reconciliation-order"), {
                        icon: false
                      });
                }).catch((error) => Utils.error(error.data.message));
        } else {
            ReconciliationService
                .update(this.state.reconciliation)
                .then((response) => {
                    this.props.onUpdate()
                    toast.info(i18next.t("sending-date-set-success"), {
                        icon: false
                      });
                })
                .catch((error) => {
                    Utils.error(error.data.message);
                });
        }
    }

    disableForm() {
        let formDisabled = false;
        if (this.state.reconciliation.status !== 'GENERATED') {
            formDisabled = true;
        }
        return formDisabled;
    }

    onCustomerCodeChange(customerCode){
        this.setState(prevState => ({
            reconciliation: {
                ...prevState.reconciliation,
                customerCode: customerCode
            }
        }))
    }

    getCustomerFormControl() {
        if (this.state.loggedInUser.roles.some(e => e === "ROLE_SUPERUSER")) {
            return <CustomerCodeFormControl label={i18next.t("customer")}
                                            onChangeSuggestionId={this.onCustomerCodeChange}
                                            required={true}
                                            chosenCustomerCode={this.state.reconciliation.customerCode}/>;
        } else {
            return <FilteredCustomerCodeFormControl label={i18next.t("customer")}
                                                    onChangeSuggestionId={this.onCustomerCodeChange}
                                                    required={true}
                                                    chosenCustomerCode={this.state.reconciliation.customerCode}/>;
        }
    }

    renderReconciliationForm() {
        if (this.state.reconciliation.id === "+") {
            return (
                <form className="form-horizontal" id="reconciliationForm" style={this.props.style} onSubmit={this.onSubmit}>
                    <FormControl label={i18next.t("start-date")} name="dateStart" type="date"
                                 value={this.state.reconciliation.dateStart} onChange={this.onChange} required={true}/>
                    <FormControl label={i18next.t("end-date")} name="dateEnd" type="date"
                                 value={this.state.reconciliation.dateEnd} onChange={this.onChange} required={true}/>
                    {this.getCustomerFormControl()}
                    <div className="row">
                        <div className="col-sm-2">&nbsp;</div>
                        <FormButton type="submit" value={i18next.t("create-reconciliation")}/>
                    </div>
                </form>
            );
        } else {
            return (
                <form className="form-horizontal" id="reconciliationForm" style={this.props.style} onSubmit={this.onSubmit}>
                    <fieldset disabled={this.disableForm()}>
                        <FormControl label={i18next.t("sending-date")} name="sentDate" type="datetime-local"
                                     value={this.state.reconciliation.sentDate} onChange={this.onChange} onKeyPress={this.onChange}/>
                        <div className="row buttons-to-left btn-grey-container">
                            <div className="col-sm-2">&nbsp;</div>
                            <FormButton type="submit" value={i18next.t("set-sent-date")}/>
                        </div>
                    </fieldset>
                </form>
            );
        }
    }

    render() {
        if (this.state.isLoading) {
            return null
        }
        return this.renderReconciliationForm();
    }
}

export default ReconciliationForm;
