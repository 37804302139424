import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import PostalCodeService from '../../services/postalcode';
import Theme from "./autosuggestStyle";
import i18next from 'i18next';
import Utils from "../common/Utils";

class PostalCodeSuggestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            suggestedPostalCodes: [],
            value: this.props.postalCode
        };

        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.postalCode !== prevProps.postalCode) {
            this.setState({
                value: this.props.postalCode
            });
        }
    }

    getSuggestions = () => {
        if (this.props.settlement && this.props.street) {
            PostalCodeService
                .getPostalCodes(this.props.settlement, this.props.street)
                .then((response) => {
                    this.setState({
                        suggestedPostalCodes: response
                    })
                })
                .catch((error) => {
                    Utils.error(i18next.t("data-read-error") + JSON.stringify(error));
                })
        } else {
            this.setState({
                suggestedPostalCodes: []
            })
        }
    };

    getSuggestionValue = suggestion => suggestion.code;

    renderSuggestion = suggestion => {
        return (
            <div>
                <h6>{suggestion.code}</h6>
                <p>{suggestion.description}</p>
            </div>
        );
    };

    onSuggestChange = (event, {newValue}) => {
        this.setState({
            value: newValue
        }, () => {
            this.props.passCode(this.state.value);
        });
    };

    onSuggestionsFetchRequested = ({value}) => {
        this.getSuggestions(value);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected(event, {suggestion}) {
        this.props.passCode(suggestion.code);
    }

    getInputProps() {
        return {
            value : this.state.value,
            onChange: this.onSuggestChange
        }
    }


    render() {
        return (
            <div className="form-group row">
                <label className="col-sm-2 control-label" htmlFor="postalCode">{i18next.t("postalcode")}</label>
                <div className="col-sm-6">
                    <Autosuggest name="postalCode"
                                 label={i18next.t("postalcode")}
                                 suggestions={this.state.suggestedPostalCodes}
                                 onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                 onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                 getSuggestionValue={this.getSuggestionValue}
                                 renderSuggestion={this.renderSuggestion}
                                 inputProps={this.getInputProps()}
                                 onSuggestionSelected={this.onSuggestionSelected}
                                 theme={Theme}/>
                </div>
            </div>
        )
    }
}

export default PostalCodeSuggestion;