import React from "react";
import PropTypes from "prop-types";
import {Nav} from 'react-bootstrap';

class NavBarButton extends React.Component {

    isTabActive() {
        return this.props.value === this.props.tabValue;
    }

    render() {
        if (this.props.menu[this.props.value.toUpperCase()]) {
            return (
                <Nav.Link active={this.isTabActive()} eventKey={this.props.value}
                          name={this.props.name} title={this.props.tooltip}>{this.props.title}</Nav.Link>
            );
        }
        return null;
    }
}

NavBarButton.propTypes = {
    menu: PropTypes.object,
    onClick: PropTypes.func,
    className: PropTypes.string,
    name: PropTypes.string
};

export default NavBarButton;