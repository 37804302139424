import React, {Component} from 'react';
import PropTypes from "prop-types";
import i18next from 'i18next';

const options = [
    {id : "5", name : "5"},
    {id : "10", name : "10"},
    {id : "20", name : "20"},
    {id : "50", name : "50"}];

export const defaultPageSize = "50";

export default class PageSize extends Component {

    render() {
            return <>
                <div><label className="form-control-plaintext">{i18next.t("show")}</label></div>
                &nbsp;
                <div>
                    <select onChange={this.props.onChange}
                            value={this.props.value === undefined ? defaultPageSize : this.props.value}
                            className="form-control" name="findPageSize">
                        {options.map((option) =>
                            <option key={option.id} value={option.id}>{option.name}</option>)}
                    </select>
                </div>
                &nbsp;
                <div><label className="form-control-plaintext">{i18next.t("entries")}</label></div>

            </>

    }
}

PageSize.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};