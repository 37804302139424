import React, {Component} from 'react';
import FileStatusTableRow from './FileStatusTableRow.js';
import Label from '../../common/Label';
import UploadProcessService from "../../../services/uploadProcess.js";
import i18next from 'i18next';
import Utils from "../../common/Utils";
import FormButton from "../../common/FormButton";

class FileStatusTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadedFiles: [{fileTypeProcess: "TERC"}, {fileTypeProcess: "SIMC"}, {fileTypeProcess: "ULIC"}, {fileTypeProcess: "POSTAL"}]
        }

        this.selectRow = this.selectRow.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
    }

    componentDidMount() {
        UploadProcessService
            .getUploadProcesses()
            .then((result) => {
                this.setState({uploadedFiles: result})
            })
            .catch((error) => {
                Utils.error(i18next.t("data-read-error") + JSON.stringify(error));
            });
    }

    selectRow(type) {
        return this.state.uploadedFiles.find(p => p.fileTypeProcess === type);
    }

    onRefresh() {
        this.componentDidMount();
    }

    renderRefreshButton() {
        return (
            <FormButton type="button" onClick={this.onRefresh} value={i18next.t("refresh-button")}/>
        );
    }

    render() {
        return (
            <div className="row justify-content-around">
                <table border="1px solid" text-align="left" style={{marginBottom: "2rem"}}>
                    <tbody>
                        <tr>
                            <th scope="col"><Label messageKey={"administration-panel-tab-table"}/></th>
                            <th scope="col"><Label messageKey={"administration-panel-tab-last-correct-update"}/></th>
                            <th scope="col"><Label messageKey={"administration-panel-tab-last-attempt-update"}/></th>
                            <th scope="col"><Label messageKey={"administration-panel-tab-update-error"}/></th>
                            {/* <th scope="col" style={{borderRightStyle:"hidden", borderBottomStyle:"hidden", borderTopStyle:"hidden"}}></th> */}
                        </tr>
                        <FileStatusTableRow data={this.selectRow("TERC")} label="TERC"/>
                        <FileStatusTableRow data={this.selectRow("SIMC")} label="SIMC"/>
                        <FileStatusTableRow data={this.selectRow("ULIC")} label="ULIC"/>
                        <FileStatusTableRow data={this.selectRow("POSTAL")} label=<Label messageKey={"administration-postal-code"}/> />
                    </tbody>
                </table>
                {this.renderRefreshButton()}
            </div>
        );
    }
}

export default FileStatusTable;