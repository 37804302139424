import React, {Component} from 'react';
import OrderList from './OrderList.js';
import OrderService from '../../services/order.js';
import i18next from 'i18next';
import Utils from "../common/Utils";

class OrderFilteredList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orderData: [],
        }

        this.findOrders = this.findOrders.bind(this);
    }

    componentDidMount() {
        this.findOrders({});
    }

    findOrders(filter) {
        OrderService
            .find(filter)
            .then((orderData) => {
                this.setState({orderData});
            })
            .catch((error) => {
                Utils.error(i18next.t("data-read-error" + JSON.stringify(error)));
            });
    }

    render() {
        return (
            <OrderList orders={this.state.orderData} onFindOrders={this.findOrders}/>
        );
    }
}

export default OrderFilteredList;