import React from 'react';
import ProjectService from '../../services/project.js';
import Suggestion from '../common/Suggestion';
import i18next from "../common/UserSuggestion";

class TaskAssignerProjectFormControl extends Suggestion {



	componentDidMount() {
    	ProjectService
    	    .findAllActive()
    		.then((result) => {
    		    if(this.props.chosenProjectId !== null && this.props.chosenProjectId !== ""){
    		        const chosenItem = result.filter((resultItem) => resultItem.id === this.props.chosenProjectId)[0];
    		        this.setState({
                        value: chosenItem.name + " (" + chosenItem.code + ")",
                        items: result
                    })
                } else {
                    this.setState({items: result});
                }
    		});
    }



    renderSuggestion (suggestion){
        if(suggestion.id === null && this.props.chosenProjectId !== ""){
            return (
                <div>
                    &lt;{i18next.t("none")}&gt;
                </div>
            )
        } else {
            return (
                <div>
                    {suggestion.name}  &nbsp;  ({suggestion.code})
                </div>
            );
        }
    }

    onSuggestionsFetchRequested ({value}){
        const inputValue = value.trim().toLowerCase();
        this.setState({
            suggestions: this.state.items.filter((item) =>
            item.name.toLowerCase().includes(inputValue) || item.code.toLowerCase().includes(inputValue))
        })
    }



}

export default TaskAssignerProjectFormControl;
