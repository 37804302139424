import React, { Component } from 'react';
import FormCheckbox from '../common/FormCheckbox.js';
import DictionaryService from '../../services/dictionary.js';
import PropTypes from "prop-types";

class UserRoleForm extends Component {
  
    constructor(props) {
		super(props);
		this.state = {
			roles: []
		};

		this.onRoleChange = this.onRoleChange.bind(this);
	}	
	
	componentDidMount() {
		DictionaryService.getRoles()
			.then( (result) => {
				this.setState({roles: result});
			});
	}
	
  	render() {
		return (
			this.state.roles.map((role) =>
				<FormCheckbox key={role.id}
							  label={role.name}
							  value={role.id}
							  onChange={this.onRoleChange}
							  checked={this.props.userRoles !== null && this.props.userRoles.includes(role.id) ? "checked" : ""} />
			)
		);
    }

	onRoleChange(event) {
		let options = this.props.userRoles;
		if (event.target.checked) {
			options.push(event.target.value);
		} else {
			let index = options.indexOf(event.target.value);
			options.splice(index, 1);
		}
		this.props.onChange(options);
	}
}

UserRoleForm.propTypes = {
	onChange : PropTypes.func,
	userRoles: PropTypes.array
};

export default UserRoleForm;