import request from '../lib/request';

function get(id) {
    return request({
        url: `/programs/${id}`,
        method: 'GET'
    });
}

function find(searchParams) {
    return request({
        url: "/programs",
        method: 'GET',
        params: {
            name: searchParams.findName,
            code: searchParams.findCode,
            user: searchParams.findUserLogin,
            customerName: searchParams.findCustomerName,
            active: searchParams.findActive,
            purchaseOrderId: searchParams.findPurchaseOrderId,
            size: searchParams.findPageSize === undefined ? "50" : searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy + "," + searchParams.sortDirection
        }
    });
}

function create(program) {
    var data = Object.assign({}, program);
    data.id = null;
    return request({
        url: "/programs",
        method: 'POST',
        data: data
    });
}

function update(program) {
    return request({
        url: "/programs/" + program.id,
        method: 'PUT',
        data: program
    });
}

function del(id) {
    return request({
        url: `/programs/${id}`,
        method: 'DELETE'
    });
}

function findByManager() {
    return request({
        url: "/programs/manager",
        method: 'GET'
    });
}

const ProgramService = {
    get,
    find,
    create,
    update,
    del,
    findByManager
}

export default ProgramService;