import React, { Component } from 'react';
import DictionaryService from '../../services/dictionary.js';

class TaskSettlementTypeFormControl extends Component {

    constructor(props) {
		super(props);
		this.state = {
			settlementTypes: []
		};
	}

    componentDidMount() {
		DictionaryService
		    .getSettlementTypes()
			.then((result) => {
				this.setState({settlementTypes: result});
			});
	}

	classNameRequired() {
		if (this.props.required === true) {
			return "form-group row required";
		}
		return "form-group row";
	}

    render() {
        return (
            <div className={this.classNameRequired()}>
                <label className="col-sm-2 control-label" htmlFor={this.props.name}>{this.props.label}</label>
                <div className="col-sm-6">
		    	    <select name={this.props.name} className="form-control" onChange={this.props.onChange} value={this.props.value}>
		    	        {this.state.settlementTypes.map((settlementType) =>
                        	 <option key={settlementType.id} value={settlementType.id}>{settlementType.name}</option>)}
                    </select>
		    	</div>
		    </div>
        );
    }
}

export default TaskSettlementTypeFormControl;