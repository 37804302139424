import React, {Component} from 'react';
import WorkListItemHeaderItem from './WorkListItemHeaderItem';
import i18next from 'i18next';
import SortableColumn from "../common/SortableColumn";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import TaskService from '../../services/task';
import Utils from '../common/Utils';

export default class WorkListItemHeader extends Component {


    constructor(props){
        super(props);
        this.state = {
            zeroWidth: 0,
            firstWidth: 0,
            secondWidth: 0,
            thirdWidth: 0,
            fourthWidth: 0,
            fifthWidth: 0,
            timesheetConfirmedTo: this.props.modifiable
        };
        this.onChange = this.onChange.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
        this.setState({
            // zeroWidth: this.zeroColumn.offsetWidth - 1,
            // firstWidth: this.firstColumn.offsetWidth - 1,
            // secondWidth: this.secondColumn.offsetWidth - 1,
            // thirdWidth: this.thirdColumn.offsetWidth - 1,
            fourthWidth: this.fourthColumn.offsetWidth - 1,
            fifthWidth: this.fifthColumn.offsetWidth - 1
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.modifiable !== this.props.modifiable
            || prevProps.month !== this.props.month
            || prevProps.year !== this.props.year){
            this.setState({
                timesheetConfirmedTo: this.props.modifiable
            })
        }
    }


    styleElement (leftParam) {
        return {
            textAlign: 'left',
            position: 'sticky',
            left: leftParam,
            backgroundColor: '#d8e2ec',
            borderBottom: '1px solid #c3c8cc',
            borderTop: '1px solid #c3c8cc',
            marginLeft: 0,
            marginRight: 0,
            zIndex: 3000
        }
    }

    onChange(event){
        if(this.props.modifiable <= event.target.value){
            this.setState({
                timesheetConfirmedTo: event.target.value
            })
        }
    }

    onConfirm(){
        TaskService.changeReportingFrom({
            taskIds: this.props.taskIds,
            dateSetByUserAssignedToTimesheet: new Date(this.props.year, this.props.month - 1, Number(this.state.timesheetConfirmedTo) + 2)
        }).then((response) => {
            this.props.setMinModifiable(Number(response.dateSetByUserAssignedToTimesheet.split("-")[2]));
        })
            .catch((error) => Utils.error(error.data.message));
    }

    render() {


        return <thead>
        {/*<tr className="tablehead-worklist-row tablehead-worklist-row-1">
        <th colSpan="4" style={this.styleElement(0)}>{i18next.t("daily-settlement-type")}</th>
            <th colSpan="4" style={this.styleElement(0)}>{i18next.t("daily-raport")}</th>
            <th colSpan="3" style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth)}>
                <button className="btn btn-success" onClick={this.onConfirm}>{i18next.t("confirm")}</button>
            </th>
            <th className="slider-padding" colSpan={this.props.data.days.length} style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth + this.state.fifthWidth)}>
                <RangeSlider value={this.state.timesheetConfirmedTo === 0 ?
                    this.props.modifiable :
                    this.state.timesheetConfirmedTo}
                             onChange={this.onChange}
                             min={1}
                             max={this.props.data.days.length}
                             disabled={this.props.modifiable === 0}
                             />
            </th>
        </tr>
        */}
        <tr className="tablehead-worklist-row tablehead-worklist-row-1">

            <SortableColumn className="project-code-th" name="projectCode" 
            label={<OverlayTrigger overlay={<Tooltip>{i18next.t("project-code")}</Tooltip>}>
                    {/* <span>{i18next.t("project-code-label")}</span> */}
                    <img className="timesheets-icon" alt="" src={require("./img/project-id.png")} width="25"/>
                </OverlayTrigger>} 
            filter={this.props.filter} onClick={this.props.getSorting} style={this.styleElement(0)} getRefWidth={true} getWidth={(width)=> this.setState({zeroWidth: width})}>
                {i18next.t("project-code-label")}
            </SortableColumn>          

            <SortableColumn name="projectName" 
            label={<OverlayTrigger overlay={<Tooltip>{i18next.t("project-name")}</Tooltip>}>
                    {/* <span>{i18next.t("project-name-label")}</span> */}
                    <img className="timesheets-icon" alt="" src={require("./img/project-name.png")} width="25"/>
                </OverlayTrigger>} 
            filter={this.props.filter} onClick={this.props.getSorting} style={this.styleElement(this.state.zeroWidth)} getRefWidth={true} getWidth={(width)=> this.setState({firstWidth: width})}>
                {i18next.t("project-name")}
            </SortableColumn>

            <SortableColumn name="taskCode" 
            label={<OverlayTrigger overlay={<Tooltip>{i18next.t("task-code")}</Tooltip>}>
                    {/* <span>{i18next.t("task-code-label")}</span> */}
                    <img className="timesheets-icon" alt="" src={require("./img/task-id.png")} width="25"/>
                </OverlayTrigger>} 
            filter={this.props.filter} onClick={this.props.getSorting} style={this.styleElement(this.state.zeroWidth + this.state.firstWidth)} getRefWidth={true} getWidth={(width)=> this.setState({secondWidth: width})}>
                {i18next.t("task-code")}
            </SortableColumn>

            <SortableColumn name="taskName" 
            label={<OverlayTrigger overlay={<Tooltip>{i18next.t("task")}</Tooltip>}>
                {/* <span>{i18next.t("task-label")}</span> */}
                <img className="timesheets-icon" alt="" src={require("./img/task-name.png")} width="25"/>
            </OverlayTrigger>} 
            filter={this.props.filter} onClick={this.props.getSorting} style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth)} getRefWidth={true} getWidth={(width)=> this.setState({thirdWidth: width})}>
                {i18next.t("task")}
            </SortableColumn>

            <th scope="col" ref={(fourthColumn) => this.fourthColumn = fourthColumn}
            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth)}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("plan-h")}
                        </Tooltip>
                    }
                >
                    <img alt="" className="timesheets-icon" src={require("./img/Plan.png")} width="25"/>
                </OverlayTrigger>
            </th>
            <th scope="col" ref={(fifthColumn) => this.fifthColumn = fifthColumn}
            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth)}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("used-h")}
                        </Tooltip>
                    }
                >
                    <img className="timesheets-icon" alt="" src={require("./img/Progress.png")} width="25"/>
                </OverlayTrigger>
            </th>
            <th scope="col" style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth + this.state.fifthWidth)}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("in-total-h")}
                        </Tooltip>
                    }
                >
                    <img className="timesheets-icon" alt="" src={require("./img/sigma.png")} width="20"/>
                </OverlayTrigger>
            </th>

            {/* with image icons start */}

            {/* <th scope="col" ref={(zeroColumn) => this.zeroColumn = zeroColumn}
            style={this.styleElement(0)} getRefWidth={true} getWidth={(width)=> this.setState({zeroWidth: width})}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("project-code")}
                        </Tooltip>
                    }
                >
                    <img className="timesheets-icon" alt="" src={require("./img/id-project.png")} width="25"/>
                </OverlayTrigger>
            </th>

            <th scope="col" ref={(firstColumn) => this.firstColumn = firstColumn}
            style={this.styleElement(this.state.zeroWidth)}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("project-name")}
                        </Tooltip>
                    }
                >
                    <img className="timesheets-icon" alt="" src={require("./img/presentation.png")} width="25"/>
                </OverlayTrigger>
            </th>

            <th scope="col" ref={(secondColumn) => this.secondColumn = secondColumn}
            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth)}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("task-code")}
                        </Tooltip>
                    }
                >
                    <img className="timesheets-icon" alt="" src={require("./img/info.png")} width="25"/>
                </OverlayTrigger>
            </th>

            <th scope="col" ref={(thirdColumn) => this.thirdColumn = thirdColumn}
            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth)}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("task")}
                        </Tooltip>
                    }
                >
                    <img className="timesheets-icon" alt="" src={require("./img/task-2.png")} width="25"/>
                </OverlayTrigger>
            </th>

            <th scope="col" ref={(fourthColumn) => this.fourthColumn = fourthColumn}
            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth)}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("plan-h")}
                        </Tooltip>
                    }
                >
                    <img className="timesheets-icon" alt="" src={require("./img/Plan.png")} width="25"/>
                </OverlayTrigger>
            </th>

            <th scope="col" ref={(fifthColumn) => this.fifthColumn = fifthColumn}
            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth)}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("used-h")}
                        </Tooltip>
                    }
                >
                    <img className="timesheets-icon" alt="" src={require("./img/Progress.png")} width="25"/>
                </OverlayTrigger>
            </th>

            <th scope="col" style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth + this.state.fifthWidth)}>
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {i18next.t("in-total-h")}
                        </Tooltip>
                    }
                >
                    <img className="timesheets-icon" alt="" src={require("./img/sigma.png")} width="25"/>
                </OverlayTrigger>
            </th> */}

            {/* with image icons end */}



            {this.props.data.days.map((day) =>
                <WorkListItemHeaderItem key={day.id} headerText={day.id} day={day}/>)
            }
        </tr>
            <tr className="tablehead-worklist-row tablehead-worklist-row-2">
                <th scope="col" style={this.styleElement(0)}/>
                <th scope="col" style={this.styleElement(this.state.zeroWidth)}/>
                <th scope="col" style={this.styleElement(this.state.zeroWidth + this.state.firstWidth)}/>
                <th scope="col" style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth)}/>
                <th scope="col" style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth)}/>
                <th scope="col" style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth)}/>
                <th scope="col" style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth + this.state.fifthWidth)}/>
                {this.props.data.days.map((day) =>
                    <WorkListItemHeaderItem key={day.id} headerText={
                            i18next.t(`days.${day.day.toLowerCase()}`) }
                            day={day}
                    />)
                }
            </tr>
        </thead>
    }
}
