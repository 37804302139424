import React, {Component} from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import i18next from 'i18next';

class ReconciliationStatusFormControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            statuses: this.props.statuses,
            selectedOption: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.statuses !== prevProps.statuses) {
            this.setState({
                statuses: this.props.statuses
            });
        }
    }

    handleChange = (selectedOption) => {
        let reconciliationArray = [];
        for (let i = 0; i < selectedOption.length; i++) {
            reconciliationArray.push(selectedOption[i].value);
        }
        this.props.onChange(reconciliationArray);
        this.setState({selectedOption});
    }

    render() {
        let result = [];
        this.state.statuses.map((status) =>
            result.push({label: status.name, value: status.id}));
        const {selectedOption} = this.state;
        return (
            <div>
                <ReactMultiSelectCheckboxes value={selectedOption} options={result} onChange={this.handleChange}
                                            placeholderButtonLabel={i18next.t("all")}/>
            </div>
        );
    }
}

export default ReconciliationStatusFormControl;