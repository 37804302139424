import CustomerService from "../../services/customer";
import Suggestion from '../common/Suggestion';

class FilteredCustomerFormControl extends Suggestion {

    componentDidMount() {
        CustomerService
            .findByManager()
            .then((result) => {
                this.setState({items: result});
            });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.chosenCustomerId !== this.props.chosenCustomerId){
            CustomerService.get(this.props.chosenCustomerId)
                .then((response) => {
                    this.setState({
                        value: response.name
                    })
                })
        }
    }
}

export default FilteredCustomerFormControl;
