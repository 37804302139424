import React, {Component} from 'react';
import FormControl from '../common/FormControl.js';
import FormButton from '../common/FormButton.js';
import TaskAssignerStatusFormControl from './TaskAssignerStatusFormControl.js';
import TaskService from '../../services/task.js';
import TaskAssignerProjectFormControl from './TaskAssignerProjectFormControl.js';
import TaskSettlementTypeFormControl from './TaskSettlementTypeFormControl.js';
import TaskUserFormControl from './TaskUserFormControl';
import {toast} from 'react-toastify';
import PurchaseOrderFormControl from "../common/PurchaseOrderFormControl";
import i18next from 'i18next';
import Utils from "../common/Utils";
import TaskTeamFormControl from './TaskTeamFormControl';

class TaskForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            task: props.task,
            formForUser: true
        };

        this.onChange = this.onChange.bind(this);
        this.onHoursChange = this.onHoursChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.copying = this.copying.bind(this);
        this.onDeleteTask = this.onDeleteTask.bind(this);
        this.onChangeProjectId = this.onChangeProjectId.bind(this);
        this.onRadioButtonChange = this.onRadioButtonChange.bind(this);
        this.onPurchaseOrderCodeChange = this.onPurchaseOrderCodeChange.bind(this);
        this.onAssignedUserIdChange =this.onAssignedUserIdChange.bind(this);
    }

    componentDidMount() {
        if (this.props.task.id !== "+") {
            TaskService
                .get(this.props.task.id)
                .then((response) => {
                    this.setState({task: response});
                }).catch((error) => {
                    Utils.error(i18next.t("single-task-error") + JSON.stringify(error));
                });
        }
    }

    onChange(event) {
        let task = {...this.state.task};
        task[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({task});
    }

    onHoursChange(event) {
        let number = event.target.value;
        let task = {...this.state.task};
        if (number >= 0) {
            task.plannedHours = number;
            this.setState({task});
        } else {
            toast.error(i18next.t("task-non-negative-planned-hours"), {
                icon: false
              });
        }
    }

    resetForm() {
        this.setState({task: this.props.task});
    }

    copying() {
        this.props.copyAnItem(this.state.task);
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.task.id === "+") {
                TaskService
                    .create(this.state.task)
                    .then((response) => {
                        this.props.onCreate(response);
                        toast.info(i18next.t("task-save-success"), {
                            icon: false
                          });
                        this.props.onFindTasks(this.props.filter);
                    }).catch((error) => {
                    Utils.error(error.data.message);
                });
        } else {
            TaskService
                .update(this.state.task)
                .then((response) => {
                    this.setState({task: response});
					toast.info(i18next.t("task-update-success"), {
                        icon: false
                      });
                    this.props.onUpdate(response);
                }).catch((error) => {
                    Utils.error(error.data.message);
                });
        }
    }

    onDeleteTask() {
        TaskService
            .remove(this.state.task.id)
            .then(() => {
                this.props.onFindTasks(this.props.filter);
                toast.info(i18next.t("task-delete-success"), {
                    icon: false
                  });
            }).catch((error) => {
                Utils.error(error.data.message);
            });
    }

    renderDeleteButton() {
        if (this.props.task.id !== '+') {
            return (<FormButton onClick={this.onDeleteTask} type="button" value={i18next.t("delete-button")}/>);
        }
    }

    getProjectControl() {
        if (this.state.task.id === "+") {
            return <TaskAssignerProjectFormControl label={i18next.t("project")}
                                                   onChangeSuggestionId={this.onChangeProjectId}
                                                   inputPlaceholder={i18next.t("choose-the-project")}
                                                   chosenProjectId={this.state.task.projectId}/>
        } else {
            return <FormControl label={i18next.t("project")} disabled={true} value={this.state.task.projectName}/>
        }
    }

    onChangeProjectId(projectId){
        let task = {...this.state.task};
        task['projectId'] = projectId;
        this.setState({task
        })
    }

    onPurchaseOrderCodeChange(purchaseOrderCode){
        this.setState(prevState => ({
            task: {
                ...prevState.task,
                purchaseOrderCode: purchaseOrderCode
            }
        }))
    }

    onAssignedUserIdChange(assignedUserId){
        this.setState(prevState => ({
            task: {
                ...prevState.task,
                assignedUserId: assignedUserId
            }
        }))
    }

    onRadioButtonChange(event){
        let copyTask = {...this.state.task};
        copyTask.teamId = null;
        this.setState({
            formForUser: JSON.parse(event.target.value),
            task: copyTask
        })
    }



    getCopyButton() {
        if (this.state.task.id !== "+") {
            return <FormButton type="button" onClick={this.copying} value={i18next.t("clone")}/>
        }
    }

    render() {
        return (
            <form className="form-horizontal" id="taskForm" style={this.props.style} onSubmit={this.onSubmit}>
                {this.state.task.id === "+" &&
                    <div className="row" style={{height: 50}}>
                        <div className="col-sm-2">
                            <p>{i18next.t("task-for")}</p>
                        </div>
                        <div className="col-sm-5" style={{paddingLeft: 100}}>
                            <label>{i18next.t("user-genitive")} &nbsp;</label>
                            <input type="radio"
                                   name="formForUser"
                                   value={true}
                                   onChange={this.onRadioButtonChange}
                                   checked={this.state.formForUser}/>

                        </div>
                        <div className="col-sm-5">
                            <label>{i18next.t("team-genitive")} &nbsp;</label>
                            <input type="radio"
                                   name="formForUser"
                                   value={false}
                                   onChange={this.onRadioButtonChange}
                                   checked={!this.state.formForUser}/>
                        </div>
                    </div>

                }

                <FormControl label={i18next.t("task-name")} name="name" value={this.state.task.name} onChange={this.onChange} required = {true}/>
                <FormControl label={i18next.t("task-code")} name="externalCode" value={this.state.task.externalCode} onChange={this.onChange}/>
                <FormControl label={i18next.t("active-female-plural")} name="active" type="checkbox" checked={this.state.task.active}
                             onChange={this.onChange}/>
                <PurchaseOrderFormControl label={i18next.t("order")}
                                          onChangeSuggestionId={this.onPurchaseOrderCodeChange}
                                          chosenExternalId={this.state.task.purchaseOrderCode}
                                          inputPlaceholder={i18next.t("choose-the-order")}
                                          removePossible={true}/>
                {this.getProjectControl()}
                <TaskSettlementTypeFormControl name="settlementTypeId" label={i18next.t("settlement-type")} value={this.state.task.settlementTypeId}
                             onChange={this.onChange} required={true}/>
                <FormControl label={i18next.t("start-date")} name="startDate" type="date" value={this.state.task.startDate} onChange={this.onChange}/>
                <FormControl label={i18next.t("end-date")} name="endDate" type="date" value={this.state.task.endDate} onChange={this.onChange}/>
                <FormControl label={i18next.t("start-report-date")} name="reportingFrom" type="date" value={this.state.task.reportingFrom} onChange={this.onChange}/>
                <FormControl label={i18next.t("planned-hours")} type="number" name="plannedHours" value={this.state.task.plannedHours} onChange={this.onHoursChange}/>
                {this.state.formForUser ?
                    <TaskUserFormControl  label={i18next.t("assigned-to")}
                                          required={true}
                                          projectId={this.state.task.projectId}
                                          onChangeSuggestionId={this.onAssignedUserIdChange}
                                          chosenUserId={this.state.task.assignedUserId}/> :
                    <TaskTeamFormControl label={i18next.t("assigned-to-team")}
                                         name="teamId"
                                         value={this.state.teamId}
                                         onChange={this.onChange}
                                         projectId={this.state.task.projectId}/>
                }
                <TaskAssignerStatusFormControl label={i18next.t("status")} name="taskStatusId" value={this.state.task.taskStatusId}
                                     onChange={this.onChange} required={true} />
                <div className="row buttons-to-left btn-grey-container btn-overlap-container">
                    <div className="col-sm-2">&nbsp;</div>
                    <FormButton type="submit" value={i18next.t("save-button")}/>
                    <FormButton type="button" onClick={this.resetForm} value={i18next.t("cancel-button")}/>
                    {this.renderDeleteButton()}
                    {this.getCopyButton()}
                </div>
            </form>
        );
    }
}

export default TaskForm;
