import React, {Component} from 'react';
import i18next from 'i18next';

export default class UploadInstruction extends Component {

    render() {
        return <>
            <p>{i18next.t("update-TERYT-1")}<br/>
                {i18next.t("update-TERYT-2")}<br/>
                <a className="external-link" title="Follow link" href="http://eteryt.stat.gov.pl/eTeryt/rejestr_teryt/udostepnianie_danych/baza_teryt/uzytkownicy_indywidualni/pobieranie/pliki_pelne.aspx?contrast=default" rel="nofollow">
                    http://eteryt.stat.gov.pl/eTeryt/rejestr_teryt/udostepnianie_danych/baza_teryt/uzytkownicy_indywidualni/pobieranie/pliki_pelne.aspx?contrast=default
                </a><br/></p>
            <p>{i18next.t("update-TERYT-3")}<br/>
                <a className="external-link" title="Follow link" href="http://www.kody-pocztowe.dokladnie.com/kody.csv.zip" rel="nofollow">
                    http://www.kody-pocztowe.dokladnie.com/kody.csv.zip
                </a></p>
            <p>{i18next.t("update-TERYT-4")}<br/></p>
            <p>{i18next.t("update-TERYT-5")}</p>
        </>
    }
}