import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

export default class TimesheetButtons extends Component {



    setStyle(){
        return {
            position: 'sticky',
            left: 20
        }
    }


    render() {
        return <div className="btn-group" style={this.setStyle()}>
            <button className="btn btn-success" onClick={this.props.onSave}>{i18next.t("save-button")}</button>
            <button className="btn btn-outline-secondary" onClick={this.props.onReset}>{i18next.t("cancel-button")}</button>
        </div>;
    }
}

TimesheetButtons.propTypes = {
    onSave: PropTypes.func,
    onReset: PropTypes.func
};