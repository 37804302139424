import React, {Component} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import OrderForm from "./OrderForm";
import OrderAttachmentList from "./OrderAttachmentList";
import i18next from 'i18next';

class OrderTabs extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            activeTab: "1"
        };

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(selectedTab) {
        this.setState({
            activeTab: selectedTab
        });
    }

    render() {
        let attachmentsTab = null;
        let orderAttachmentList = null;
        let orderForm = null;
        if (this.state.activeTab === "1") {
            orderForm = <OrderForm order={this.props.order} onUpdate={this.props.onUpdate}/>
        } else if (this.state.activeTab === "2") {
            orderAttachmentList = <OrderAttachmentList eventKey="2" title={i18next.t("attachments")} order={this.props.order}/>
        }
        if (this.props.order.id !== '+') {
            attachmentsTab = <Tab eventKey="2" title={i18next.t("attachments")}>{orderAttachmentList}</Tab>
        }
        return (
            <tr>
                <td colSpan="8">
                    <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect} className="customer-container-form" >
                        <Tab eventKey="1" title={i18next.t("edit")}>
                            {orderForm}
                        </Tab>
                        {attachmentsTab}
                    </Tabs>
                </td>
            </tr>
        );
    }
}

export default OrderTabs;