import React, { Component } from 'react';
import ErrorDialog from "./ErrorDialog";

class ErrorHandlerComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
		    error: null
		};

		this.handleCloseModal = this.handleCloseModal.bind(this);
	}

	handleCloseModal() {
		this.setState({error: null});
	}

	render() {
		if (this.state.error !== undefined && this.state.error !== null) {
			return <ErrorDialog
                description={this.state.error.data !== undefined ? this.state.error.data.message : this.state.error}
                onClose={this.handleCloseModal}/>
		}
		return this.doRender();
	}

	doRender() {
		return null;
	};
}

export default ErrorHandlerComponent;