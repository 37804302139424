import request from '../lib/request';

function getUserRoles (){
    return request({
        url: 'auth/roles',
        method: 'GET'
    });
}

function getUserRolesDescriptions() {
    return request({
        url: "auth/rolesDescriptions",
        method: 'GET'
    });
}

function login(user) {
    const data = Object.assign({}, user);
    return request({
        url: "/auth/login",
        method: 'POST',
        data: data
    });
}

function changePassword(password) {
    var dt ={};
    dt["password"] = password;
    return request({
        url: "/auth/changePassword",
        method: 'POST',
        data: dt
    });
}

function resetPassword(password, token) {
    var dt ={};
    dt["password"] = password;
    dt["token"] = token;
    return request({
        url: "/auth/resetPassword",
        method: 'POST',
        data: dt
    });
}

function checkPassword(password) {
    var dt ={};
    dt["password"] = password;
    return request({
        url: "/auth/checkPassword",
        method: 'POST',
        data: dt
    });
}

function checkToken(token) {
    var dt ={};
    dt["token"] = token;
    return request({
        url: "/auth/token",
        method: 'POST',
        data: dt
    });
}

const SecurityService = {
    login,
    changePassword,
    checkPassword,
    checkToken,
    getUserRoles,
    resetPassword,
    getUserRolesDescriptions
};

export default SecurityService;