import React, { Component } from 'react';
import HolidayListItem from './HolidayListItem';
import HolidayService from '../../../services/holiday';
import i18next from 'i18next';
import Utils from "../../common/Utils";
import ListTitle from "../../common/ListTitle";

class HolidayList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowId: null,
            holidayData: [],
            addButtonLabel: i18next.t("add-new")
        };

        this.selectRow = this.selectRow.bind(this);
        this.findHolidays = this.findHolidays.bind(this);
        this.onCreateHoliday = this.onCreateHoliday.bind(this);
        this.onUpdateHoliday = this.onUpdateHoliday.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
    }

    componentDidMount() {
        this.findHolidays({});
    }

    selectRow(key) {
        this.setState((prevState) => {
                return {selectedRowId: key === prevState.selectedRowId ? null: key,
                        addButtonLabel: i18next.t("add-new")}
        });
    }

    findHolidays() {
        HolidayService
            .find()
            .then((holidayData) => {
                this.setState({holidayData: holidayData});
            })
            .catch((error) => {
                Utils.error(i18next.t("data-read-error") + JSON.stringify(error));
            });
    }

    onCreateHoliday(holiday) {
        this.setState({selectedRowId: null,
            addButtonLabel: i18next.t("add-new")});
    }

    onUpdateHoliday(holiday) {
        this.setState({holiday: holiday});
        this.findHolidays();
    }

    addNewItem(){
        this.setState(prevState => ({
            selectedRowId: prevState.selectedRowId === '+' ? null : '+',
            addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
        }))
    }

    render() {
        return (
            <table className="table table-sm">
                <thead>
                    <ListTitle sectionLabel={i18next.t("holiday-title")} span={3}
                           onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
                    <tr>
                        <th scope="col">{i18next.t("holiday-repeatable-label")}</th>
                        <th scope="col">{i18next.t("holiday-year-label")}</th>
                        <th scope="col">{i18next.t("holiday-month-label")}</th>
                        <th scope="col">{i18next.t("holiday-day-label")}</th>
                        <th scope="col">{i18next.t("holiday-description-label")}</th>
                    </tr>
                </thead>
                <tbody>
                <HolidayListItem key={'+'} selected={this.state.selectedRowId} holiday={{id: "+", repeatable: "", year: "", month: "", day: "", description: "", plus: "plus"}} onSelect={this.selectRow} onCreate={this.onCreateHoliday} onFindHolidays={this.findHolidays}/>
                    {this.state.holidayData.map((holiday) =>
                        <HolidayListItem key={holiday.id} selected={this.state.selectedRowId} holiday={holiday} onSelect={this.selectRow} onUpdate={this.onUpdateHoliday} onFindHolidays={this.findHolidays}/>)
                    }
                </tbody>
            </table>
        );
    }
}

export default HolidayList;