import CustomerCodeFormControl from "./CustomerCodeFormControl";
import CustomerService from "../../services/customer";

class FilteredCustomerCodeFormControl extends CustomerCodeFormControl {

    componentDidMount() {
        CustomerService
            .findByManager()
            .then((result) => {
                this.setState({items: result});
            });
    }
}

export default FilteredCustomerCodeFormControl;
