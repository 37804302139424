import React, {Component} from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import UserForm from './UserForm';
import UserSkillsList from './UserSkillsList';
import i18next from 'i18next';

class UserTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1"
        };

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(selectedTab) {
        this.setState({
            activeTab: selectedTab
        });
    }

    renderUserForm() {
        if (this.state.activeTab === "1") {
            return <UserForm
                    key={this.props.user.id}
                    user={this.props.user}
                    onCreate={this.props.onCreate}
                    onUpdate={this.props.onUpdate}
                    onFindUsers={this.props.onFindUsers}/>
        }
    }

    renderUserSkillsTab() {
        if (this.props.user.id !== "+") {
            let userskils = null;
            if (this.state.activeTab === "2") {
                userskils = <UserSkillsList userId={this.props.user.id}/>
            }
            return <Tab eventKey="2" title={i18next.t("skills")}>
                {userskils}
            </Tab>
        }
    }

    render() {
        return (
            <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect} className="customer-container-form">
                <Tab eventKey="1" title={i18next.t("edit")}>
                    {this.renderUserForm()}
                </Tab>
                {this.renderUserSkillsTab()}
            </Tabs>
        );
    }
}

export default UserTab;