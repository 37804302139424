import React, { Component } from 'react';
import UserFilter from './UserFilter.js';
import UserListItem from './UserListItem.js';
import PropTypes from "prop-types";
import i18next from 'i18next';
import SortableColumn from "../common/SortableColumn";
import PageableFilter from "../common/PageableFilter";
import ListTitle from "../common/ListTitle";

class UserList extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		    selectedRowId: null,
		    filter: {},
			addButtonLabel: i18next.t("add-new")
		};

		this.selectRow = this.selectRow.bind(this);
		this.findUsers = this.findUsers.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onCreateUser = this.onCreateUser.bind(this);
		this.onUpdateUser = this.onUpdateUser.bind(this);
		this.onSortOrder = this.onSortOrder.bind(this);
		this.addNewItem = this.addNewItem.bind(this);
	}

	selectRow(key) {
		this.setState((prevState) => {
				return {selectedRowId: key === prevState.selectedRowId ? null : key,
					    addButtonLabel: i18next.t("add-new")}
		});
	}
	
	findUsers() {
		this.props.onFindUsers(this.state.filter);
	}

	onFilterChange(event) {
        let targetName = event.target.name;
		let filter = Object.assign({}, this.state.filter); 
		filter[targetName] = event.target.value;
		if(!["findPageNumber", "sortBy", "sortDirection"].includes(event.target.name)){
			filter["findPageNumber"] = "0";
		}
		this.setState({filter}, () => this.findUsers());
	}
	
	onCreateUser(user) {
		this.setState({selectedRowId: null,
							 addButtonLabel: i18next.t("add-new")});
	}
	
	onUpdateUser(user) {
		this.setState({user: user}, () => this.findUsers());
	}

	onSortOrder(filter) {
        this.setState({filter: filter, selectedRowId: null}, () => this.findUsers());
    }

	addNewItem(){
		this.setState(prevState => ({
			selectedRowId: prevState.selectedRowId === '+' ? null : '+',
			addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
		}))
	}

    render() {
        return (
	    	<table className="table table-sm">
	    		<thead>
				<ListTitle sectionLabel={i18next.t("navbar-users")} span={4}
						   onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
	    		    <UserFilter filter={this.state.filter} resultSize={this.props.users.length} onFilterChange={this.onFilterChange}/>
	    		    <tr>
                        <SortableColumn name="id" label="Id" filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="firstName" label={i18next.t("first-name")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="lastName" label={i18next.t("last-name")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="login" label="Login" filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="email" label="E-mail" filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="active" label={i18next.t("active")} filter={this.state.filter} onClick={this.onSortOrder}/>
	    		    </tr>
	    		</thead>
	    		<tbody>
				<UserListItem key={'+'} selected={this.state.selectedRowId} onSelect={this.selectRow} onCreate={this.onCreateUser}
							  user={{id: "+", firstName: "", lastName: "", login: "", email: "", roles: [], plus: "plus"}}  onFindUsers={this.findUsers}/>
	    			{this.props.users.map((user) =>
	    				<UserListItem key={user.id} selected={this.state.selectedRowId} user={user} onSelect={this.selectRow} onUpdate={this.onUpdateUser}/>)}
				<PageableFilter colSpan="6" onFilterChange={this.onFilterChange}
								findPageSize={this.state.filter.findPageSize}
								findPageNumber={this.state.filter.findPageNumber}
								resultSize={this.props.users.length}
								hidePageSize={true}/>
	    		</tbody>
	    	</table>
        );
    }
}

UserList.propTypes = {
	onFindUsers: PropTypes.func,
	users: PropTypes.array
};

export default UserList;