import React, {Component} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import TeamMemberList from './TeamMemberList';
import TeamForm from './TeamForm';
import i18next from 'i18next';

class TeamTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1"
        };

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(selectedTab) {
        this.setState({
            activeTab: selectedTab
        });
    }

    renderTeamMembersTab() {
        if (this.props.data.id !== '+') {
            if (this.state.activeTab === "2") {
                return (
                    <Tab eventKey="2" title={i18next.t("members")}>
                        <TeamMemberList team={this.props.data}
                                        afterChange={this.props.afterChange}
                                        {...this.props}/>
                    </Tab>
                )
            } else {
                return (
                    <Tab eventKey="2" title={i18next.t("members")}>
                    </Tab>
                )
            }
        }
    }

    renderTeamForm() {
        if (this.state.activeTab === "1") {
            return <TeamForm team={this.props.data}
                             afterChange={this.props.afterChange}
                             {...this.props}/>
        }
    }

    render() {
        return (
            <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect} className="customer-container-form">
                <Tab eventKey="1" title={this.props.data.id !== '+' ? i18next.t("edit") : i18next.t("set-data")}>
                    {this.renderTeamForm()}
                </Tab>
                {this.renderTeamMembersTab()}
            </Tabs>
        );
    }
}

export default TeamTab;