import React, { Component } from 'react';
import CustomerFilter from "./CustomerFilter";
import CustomerListItem from "./CustomerListItem";
import SortableColumn from "../common/SortableColumn";
import i18next from "i18next";
import PageableFilter from "../common/PageableFilter";
import ListTitle from '../common/ListTitle';

class CustomerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowId: null,
            filter: {},
            addButtonLabel: i18next.t("add-new")
        };

        this.selectRow = this.selectRow.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onCreateCustomer = this.onCreateCustomer.bind(this);
        this.onUpdateCustomer = this.onUpdateCustomer.bind(this);
        this.onSortOrder = this.onSortOrder.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
    }

    selectRow(key) {
        this.setState((prevState) => {
            return {selectedRowId: key === prevState.selectedRowId ? null : key,
                    addButtonLabel: i18next.t("add-new")}
        });
    }

    onFilterChange(event) {
        let filter = {...this.state.filter};
        filter[event.target.name] = event.target.value;
        if(!["findPageNumber", "sortBy", "sortDirection"].includes(event.target.name)){
            filter["findPageNumber"] = "0";
        }
        this.setState({filter}, () => {
            this.props.onFindCustomers(this.state.filter)
        });
    }

    onCreateCustomer() {
        this.setState({selectedRowId: null,
                            addButtonLabel: i18next.t("add-new")})
    }

    onUpdateCustomer(customer) {
        this.setState({customer: customer})
        this.props.onFindCustomers(this.state.filter);
    }

    onSortOrder(filter) {
        this.setState({filter: filter, selectedRowId: null}, () => this.props.onFindCustomers(this.state.filter));
    }

    addNewItem(){
        this.setState(prevState => ({
            selectedRowId: prevState.selectedRowId === '+' ? null : '+',
            addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
        }))
    }

    render() {
        return (
            <table className="table table-sm">
                <thead>
                <ListTitle sectionLabel={i18next.t("navbar-customers")} span={3}
                onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
                    <CustomerFilter filter={this.state.filter} resultSize={this.props.customers.length}
                                    onFilterChange={this.onFilterChange}/>
                    <tr>
                        <SortableColumn name="c.id" label="Id" filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="c.name" label={i18next.t("customer-name")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="c.externalId" label={i18next.t("external-id")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="c.nip" label={i18next.t("nip")} filter={this.state.filter} onClick={this.onSortOrder}/>
                        <SortableColumn name="c.managingUser.firstName, c.managingUser.lastName, c.managingUser.login"
                                        label={i18next.t("managing-user")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    </tr>
                </thead>
                <tbody>
                    <CustomerListItem key={'+'} selected={this.state.selectedRowId}
                                      customer={{id: "+", name: "", externalId: "", nip: "", managingUserFullName: "",
                                          wwwSite: "", description: "", plus: "plus"}}
                                      onSelect={this.selectRow} onCreate={this.onCreateCustomer}
                                      onFindCustomers={this.props.onFindCustomers} filter={this.state.filter}/>
                    {this.props.customers.map((customer) =>
                        <CustomerListItem key={customer.id} selected={this.state.selectedRowId} customer={customer}
                                          onSelect={this.selectRow} onUpdate={this.onUpdateCustomer}/>)}
                    <PageableFilter colSpan="5" onFilterChange={this.onFilterChange}
                                    findPageSize={this.state.filter.findPageSize}
                                    findPageNumber={this.state.filter.findPageNumber}
                                    resultSize={this.props.customers.length}
                                    hidePageSize={true}/>
                </tbody>
            </table>
        );
    }
}

export default CustomerList;