import React, {Component} from 'react';
import ReconciliationFilter from './ReconciliationFilter.js';
import ReconciliationListItem from './ReconciliationListItem.js';
import PropTypes from 'prop-types';
import {Slide, ToastContainer} from "react-toastify";
import i18next from 'i18next';
import SortableColumn from '../common/SortableColumn';
import moment from "moment";
import PageableFilter from "../common/PageableFilter";
import ListTitle from "../common/ListTitle";

class ReconciliationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowId: null,
            filter: {},
            addButtonLabel: i18next.t("add-new")
        };

        this.selectRow = this.selectRow.bind(this);
        this.findReconciliations = this.findReconciliations.bind(this);
        this.onPageableFilterChange = this.onPageableFilterChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onSortOrder = this.onSortOrder.bind(this);
        this.onCalendarChangeStartDate = this.onCalendarChangeStartDate.bind(this);
        this.onCalendarChangeEndDate = this.onCalendarChangeEndDate.bind(this);
        this.onCalendarChangeGeneratedDate = this.onCalendarChangeGeneratedDate.bind(this);
        this.onCalendarChangeSentDate = this.onCalendarChangeSentDate.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
    }

    selectRow(key) {
        this.setState((prevState) => {
            return {selectedRowId: key === prevState.selectedRowId ? null : key,
                    addButtonLabel: i18next.t("add-new")}
        });
    }

    findReconciliations() {
        let filter = {...this.state.filter};
        this.findOnCalendarChangeStartDate(filter);
        this.findOnCalendarChangeEndDate(filter);
        this.findOnCalendarChangeGeneratedDate(filter);
        this.findOnCalendarChangeSentDate(filter);
        this.props.onFindReconciliations(filter);
    }

    findOnCalendarChangeStartDate(filter) {
        if (this.state.filter.dateStartLeftLimit !== undefined && this.state.filter.dateStartRightLimit !== undefined) {
            if (this.state.filter.dateStartLeftLimit !== null && this.state.filter.dateStartRightLimit !== null) {
                filter.dateStartLeftLimit = moment(this.state.filter.dateStartLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.dateStartRightLimit = moment(this.state.filter.dateStartRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.dateStartRightLimit == null && this.state.filter.dateStartLeftLimit !== null) {
                filter.dateStartLeftLimit = moment(this.state.filter.dateStartLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.dateStartRightLimit !== null && this.state.filter.dateStartLeftLimit == null) {
                filter.dateStartRightLimit = moment(this.state.filter.dateStartRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
    }

    findOnCalendarChangeEndDate(filter) {
        if (this.state.filter.dateEndLeftLimit !== undefined && this.state.filter.dateEndRightLimit !== undefined) {
            if (this.state.filter.dateEndLeftLimit !== null && this.state.filter.dateEndRightLimit !== null) {
                filter.dateEndLeftLimit = moment(this.state.filter.dateEndLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.dateEndRightLimit = moment(this.state.filter.dateEndRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.dateEndRightLimit == null && this.state.filter.dateEndLeftLimit !== null) {
                filter.dateEndLeftLimit = moment(this.state.filter.dateEndLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.dateEndRightLimit !== null && this.state.filter.dateEndLeftLimit == null) {
                filter.dateEndRightLimit = moment(this.state.filter.dateEndRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
    }

    findOnCalendarChangeGeneratedDate(filter) {
        if (this.state.filter.generatedDateLeftLimit !== undefined && this.state.filter.generatedDateRightLimit !== undefined) {
            if (this.state.filter.generatedDateLeftLimit !== null && this.state.filter.generatedDateRightLimit !== null) {
                filter.generatedDateLeftLimit = moment(this.state.filter.generatedDateLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.generatedDateRightLimit = moment(this.state.filter.generatedDateRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.generatedDateRightLimit == null && this.state.filter.generatedDateLeftLimit !== null) {
                filter.generatedDateLeftLimit = moment(this.state.filter.generatedDateLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.generatedDateRightLimit !== null && this.state.filter.generatedDateLeftLimit == null) {
                filter.generatedDateRightLimit = moment(this.state.filter.generatedDateRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
    }

    findOnCalendarChangeSentDate(filter) {
        if (this.state.filter.sentDateLeftLimit !== undefined && this.state.filter.sentDateRightLimit !== undefined) {
            if (this.state.filter.sentDateLeftLimit !== null && this.state.filter.sentDateRightLimit !== null) {
                filter.sentDateLeftLimit = moment(this.state.filter.sentDateLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.sentDateRightLimit = moment(this.state.filter.sentDateRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.sentDateRightLimit == null && this.state.filter.sentDateLeftLimit !== null) {
                filter.sentDateLeftLimit = moment(this.state.filter.sentDateLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.sentDateRightLimit !== null && this.state.filter.sentDateLeftLimit == null) {
                filter.sentDateRightLimit = moment(this.state.filter.sentDateRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
    }

    onCalendarChangeStartDate(event) {
        this.setState({
            filter: {
                ...this.state.filter,
                dateStartLeftLimit: event.startDate,
                dateStartRightLimit: event.endDate,
                findPageNumber: "0"
            }
        }, () => this.findReconciliations());
    }

    onCalendarChangeEndDate(event) {
        this.setState({
            filter: {
                ...this.state.filter,
                dateEndLeftLimit: event.startDate,
                dateEndRightLimit: event.endDate,
                findPageNumber: "0"
            }
        }, () => this.findReconciliations());
    }

    onCalendarChangeGeneratedDate(event) {
        this.setState({
            filter: {
                ...this.state.filter,
                generatedDateLeftLimit: event.startDate,
                generatedDateRightLimit: event.endDate,
                findPageNumber: "0"
            }
        }, () => this.findReconciliations());
    }

    onCalendarChangeSentDate(event) {
        this.setState({
            filter: {
                ...this.state.filter,
                sentDateLeftLimit: event.startDate,
                sentDateRightLimit: event.endDate,
                findPageNumber: "0"
            }
        }, () => this.findReconciliations());
    }

    onStatusChange(...findStatus) {
        this.setState({
            filter: {
                ...this.state.filter,
                findStatus,
                findPageNumber: "0"
            }
        }, () => this.findReconciliations());
    }

    onPageableFilterChange(event) {
        let filter = Object.assign({}, this.state.filter);
        filter[event.target.name] = event.target.value;
        if(event.target.name === "findPageSize"){
            filter["findPageNumber"] = "0";
        }
        this.setState({
            filter: filter
        }, () => this.findReconciliations());
    }

    onSortOrder(filter) {
        this.setState({
            filter: filter, selectedRowId: null
        }, () => this.findReconciliations());
    }

    renderAllItems() {
        return this.props.reconciliations.map((reconciliation) => this.renderItem(reconciliation));
    }

    renderItem(reconciliation) {
        return <ReconciliationListItem key={reconciliation.id} selected={this.state.selectedRowId}
                                       reconciliation={reconciliation}
                                       onSelect={this.selectRow}
                                       notify={this.notify}
                                       findReconciliations={this.findReconciliations}
                                       {...this.props}
        />
    }

    renderEmptyItem() {
        return <ReconciliationListItem key={'+'} selected={this.state.selectedRowId} onSelect={this.selectRow}
                                       findAllUsers={this.props.findAllUsers}
                                       findReconciliations={this.findReconciliations}
                                       reconciliation={{id: "+", name: "", leader: {firstName: "", lastName: ""}, plus: "plus"}}
                                       notify={this.notify}/>
    }

    addNewItem(){
        this.setState(prevState => ({
            selectedRowId: prevState.selectedRowId === '+' ? null : '+',
            addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
        }))
    }

    render() {
        return (
            <div>
                <ToastContainer hideProgressBar={true} closeButton={false} transition={Slide}/>
                <table className="table table-sm">
                    <thead>
                    <ListTitle sectionLabel={i18next.t("navbar-reconciliations")} span={5}
                               onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
                        <ReconciliationFilter filter={this.state.filter}
                                              onPageableFilterChange={this.onPageableFilterChange}
                                              onStatusChange={this.onStatusChange}
                                              onSubmit={this.findReconciliations}
                                              onCalendarChangeStartDate={this.onCalendarChangeStartDate}
                                              onCalendarChangeEndDate={this.onCalendarChangeEndDate}
                                              onCalendarChangeGeneratedDate={this.onCalendarChangeGeneratedDate}
                                              onCalendarChangeSentDate={this.onCalendarChangeSentDate}
                                              resultSize={this.props.reconciliations.length}
                                              {...this.props}/>
                        <tr>
                            <SortableColumn name="r.id" label="Id" filter={this.state.filter} onClick={this.onSortOrder}/>
                            <SortableColumn name="r.dateStart" label={i18next.t("start-date")} filter={this.state.filter}
                                            onClick={this.onSortOrder}/>
                            <SortableColumn name="r.dateEnd" label={i18next.t("end-date")} filter={this.state.filter}
                                            onClick={this.onSortOrder}/>
                            <th scope="col">{i18next.t("reconciliation-file")}</th>
                            <SortableColumn name="r.generatedDate" label={i18next.t("generation-date")}
                                            filter={this.state.filter} onClick={this.onSortOrder}/>
                            <SortableColumn name="r.sentDate" label={i18next.t("sending-date")} filter={this.state.filter}
                                            onClick={this.onSortOrder}/>
                            <SortableColumn name="r.status" label={i18next.t("status")} filter={this.state.filter}
                                            onClick={this.onSortOrder}/>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderEmptyItem()}
                        {this.renderAllItems()}
                        <PageableFilter colSpan="7" onFilterChange={this.onPageableFilterChange}
                                        findPageSize={this.state.filter.findPageSize}
                                        findPageNumber={this.state.filter.findPageNumber}
                                        resultSize={this.props.reconciliations.length}
                                        hidePageSize={true}/>
                    </tbody>
                </table>
            </div>
        );
    }
}

ReconciliationList.propTypes = {
    reconciliations: PropTypes.arrayOf(PropTypes.object),
    onFindReconciliations: PropTypes.func
}

export default ReconciliationList;