import React, {Component} from 'react';
import AddressService from '../../services/address';
import AddressListItem from './AddressListItem';
import i18next from 'i18next';
import Utils from "../common/Utils";

class CustomerAddresses extends Component {

    constructor(props) {
        super(props);
        this.state ={
            addresses : [],
            selectedRowId : null
        };

        this.onChange = this.onChange.bind(this);
        this.selectRow = this.selectRow.bind(this);
    }

    componentDidMount() {
        AddressService
            .findAddressesByCustomerId(this.props.customerId)
            .then((response) => {
                this.setState({
                    addresses : response
                })
            }).catch((error) => {
                Utils.error(i18next.t("data-read-error" + JSON.stringify(error)));
            })
    }

    onChange(address, type) {
        switch(type) {
            case  "create": {
                const copyAddress = Object.assign({}, address);
                copyAddress.fullStreetName = copyAddress.street + " "
                    + copyAddress.buildingNo + "/" + copyAddress.flatNo;
                this.state.addresses.push(copyAddress);
                this.setState({
                    selectedRowId : null
                });
                break;
            } case "update": {
                let addr = this.state.addresses;
                const copyAddress = Object.assign({}, address);
                copyAddress.fullStreetName = copyAddress.street + " "
                    + copyAddress.buildingNo + "/" + copyAddress.flatNo;
                const length = addr.length;
                for (let i = 0; i < length; i++) {
                    if (address.id === addr[i].id) {
                        addr[i] = copyAddress;
                        break;
                    }
                }
                this.setState({
                    addresses: addr
                });
                break;
            } case "delete": {
                const filtered = this.state.addresses.filter((value) =>
                     value.id !== address.id
                );
                this.setState({
                    addresses: filtered,
                    selectedRowId : null
                });
                break;
            } default:
        }
    }

    selectRow(key) {
        this.setState((prevState) => {
            return {selectedRowId: key === prevState.selectedRowId ? null: key}
        });
    }

    render() {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">{i18next.t("type-of-address")}</th>
                        <th scope="col">{i18next.t("street")}</th>
                        <th scope="col">{i18next.t("postalcode")}</th>
                        <th scope="col">{i18next.t("settlement")}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.addresses.map((address) =>
                        <AddressListItem key={address.id} selected={this.state.selectedRowId} address={address}
                                         onSelect={this.selectRow} onChange={this.onChange} customerId={this.props.customerId}
                                         addresses={this.state.addresses}/>)}
                    <AddressListItem key={'+'} selected={this.state.selectedRowId}
                                    address={{id: "+", addressUsageTypeName: "", fullStreetName: "", postalCode: "",
                                              street: "", buildingNo: "", flatNo: "", voivodeship: "", addressUsageTypeId: "", domestic: true,
                                              settlement: "", municipality: "", county: ""}}
                                    onSelect={this.selectRow} onChange={this.onChange} customerId={this.props.customerId}
                                    addresses={this.state.addresses}/>
                </tbody>
            </table>
        );
    }
}

export default CustomerAddresses;