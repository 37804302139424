import React, {Component} from 'react';
import i18next from 'i18next';
import TimesheetService from "../../services/timesheet";
import WorkList from './WorkList';
import TimesheetButtons from './TimesheetButtons';
import {toast} from 'react-toastify';
import Utils from '../common/Utils';

export default class DailyContainer extends Component{

    constructor(props){
        super(props);
        this.state = {
            timesheet: {tasks: [], monthDefinition: {days: []}},     //TimesheetDto lista Taskow + definicja miesiąca
            tmpTimesheet: {tasks: [], monthDefinition: {days: []}},
            filter : {sortBy: "taskName", sortDirection: "ASC"}
        };
        this.onSave = this.onSave.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onDataChanged = this.onDataChanged.bind(this);
        this.getSorting = this.getSorting.bind(this);
        this.getData = this.getData.bind(this);
    }
    //nie potrzeba calendar service
    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.month !== this.props.month || prevProps.year !== this.props.year){
            this.componentDidMount();
        }
    }

    getData (){
        TimesheetService.get(this.props.year, this.props.month, this.state.filter.sortBy, this.state.filter.sortDirection)
            .then((response) => {
                const tmp = JSON.parse(JSON.stringify(response));
                this.setState({
                    timesheet: response,
                    tmpTimesheet: tmp
                })
            })
            .catch((error) => {
                Utils.error(i18next.t("timesheet-error"));
            })
    }

    onSave() {
        TimesheetService.update(this.props.year, this.props.month, this.state.tmpTimesheet)
            .then((response) => {
                const tmp = JSON.parse(JSON.stringify(response));
                toast.info(i18next.t("timesheet-save-success"), {
                    icon: false
                  });
                this.setState({
                    timesheet: response,
                    tmpTimesheet: tmp
                }, this.forceUpdate(() => {
                    this.componentDidMount();
                }))
            }
            )
            .catch((error) => {
                Utils.error(i18next.t("timesheet-save-error") + JSON.stringify(error));
            })
    }

    onReset() {
        const tmp = JSON.parse(JSON.stringify(this.state.timesheet));
        this.setState({
            tmpTimesheet: tmp,
        })
    }

    onDataChanged(timesheetDto) {
        this.setState({
            tmpTimesheet: timesheetDto
        })
    }

    getSorting (sortingObject){

        this.setState({
            filter: sortingObject
        }, () => {
            this.getData();
        })
    }

    render () {

        if(this.state.timesheet.tasks.length !== 0){
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <WorkList data={this.state.tmpTimesheet}
                                      year={this.props.year} month={this.props.month}
                                      onChange={this.onDataChanged}
                                      getSorting={this.getSorting}
                                      filter={this.state.filter}
                                      refresh={this.getData}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TimesheetButtons onSave={this.onSave}
                                              onReset={this.onReset}
                            />
                        </div>
                    </div>
                </div>

            );
        }
        else {
            return <div><br/><h3 align="center">{i18next.t("task-no-tasks-to-report-daily")}</h3></div>
        }
    }


}
