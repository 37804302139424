import React, { Component } from 'react';
import PropTypes from "prop-types";
import UserRoleForm from "../user/UserRoleForm";
import i18next from 'i18next';

class FormControl extends Component {

    constructor(props) {
        super(props);

        this.getHelpInformation = this.getHelpInformation.bind(this);
    }

    getHelpInformation() {
        if (this.props.help !== undefined) {
            return <span className="help-block">{this.props.helpKey === undefined ? this.props.help : i18next.t(this.props.helpKey)}</span>
        }
    }

    classNameRequired() {
        if (this.props.required === true) {
            return "form-group row required";
        }
        return "form-group row";
    }

    render() {
        return (
            <div className={this.classNameRequired()}>
                <label className="col-sm-2 control-label"
                    htmlFor={this.props.name}>{this.props.labelKey === undefined ? this.props.label : i18next.t(this.props.labelKey)}</label>
                <div className="col-sm-6">
                    <input className={this.props.className === undefined ? "form-control" : this.props.className}
                        type={this.props.type === undefined ? "text" : this.props.type}
                        checked={this.props.checked}
                        name={this.props.name}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled}
                        maxLength={this.props.maxLength}
                        onKeyPress={this.props.onKeyPress}
                        style={this.props.style}
                        min={this.props.min}
                        max={this.props.max}
                    />
                    {this.getHelpInformation()}
                </div>
            </div>
        );
    }
}

UserRoleForm.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    type: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    labelKey: PropTypes.string,
    value: PropTypes.object
};

export default FormControl;