import request from '../lib/request';

function findVisibleTabs() {
    return request({
        url: "/menu",
        method: 'GET',
    });
}

const MenuService = {
    findVisibleTabs
};

export default MenuService;