import request from '../lib/request';
import requestData from '../lib/requestData';

function uploadOrderAttachment(file, orderId) {
    return request({
        url: "/files/uploadOrderAttachment",
        method: 'POST',
        data: file,
        params: {
            orderId: orderId
        }
    });
}

function downloadOrderAttachment(id) {
    return requestData({
        url: "/files/downloadOrderAttachment",
        method: 'GET',
        responseType: "blob",
        params: {
            id: id
        }
    });
}

function downloadReconciliationAttachment(id) {
    return requestData({
        url: "/files/downloadReconciliationAttachment",
        method: 'GET',
        responseType: "blob",
        params: {
            id: id
        }
    });
}

function uploadAttachment(year, month, taskId, file) {
    return requestData({
        url: "/files/monthly/" + year + "/" + month + "/tasks/" + taskId + "/attachments",
        method: 'POST',
        data: file
    });
}

function downloadAttachment(attachmentId) {
    return requestData({
        url: "/files/monthly/attachments/" + attachmentId,
        method: 'GET',
        responseType: "blob",
        params: {
            attachmentId: attachmentId
        }
    });
}
function removeAttachment(attachmentId) {
    return requestData( {
        url: "/files/monthly/attachments/" + attachmentId,
        method: "DELETE"
    })
}

const UploadService = {
    uploadOrderAttachment,
    downloadOrderAttachment,
    uploadAttachment,
    downloadAttachment,
    downloadReconciliationAttachment,
    removeAttachment
};

export default UploadService;
