import React, {Component} from 'react';
import LanguageButton from "../language/LanguageButton";

export default class LanguageChange extends Component{

    render() {
        return (
            <>
            {/* <h4 style={{marginBottom: 0}}>{i18next.t("change-language")}</h4> */}
            <LanguageButton/>
            {/* <h5>Timekeeper {i18next.t("version")} {process.env.REACT_APP_VERSION}</h5> */}
            {/* <hr className="my-4"/> */}
            </>
        );
    }

}
