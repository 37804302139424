import request from '../lib/request';

function findAddressesByCustomerId(customerId) {
    return request ({
        url: `/customers/${customerId}/addresses`,
        method: 'GET'
    });
}

function getAddressById(customerId, addressId) {
    return request({
        url: `/customers/${customerId}/addresses/${addressId}`,
        method: 'GET'
    });
}

function create(address, customerId) {
    const data = Object.assign({}, address);
    return request({
        url: `/customers/${customerId}/addresses`,
        method: 'POST',
        data: data
    });
}

function update(address, customerId) {
    const data = Object.assign({}, address);
    return request({
        url: `/customers/${customerId}/addresses/${address.id}`,
        method: 'PUT',
        data: data
    });
}

function del(customerId, addressId) {
    return request({
        url: `/customers/${customerId}/addresses/${addressId}`,
        method: 'DELETE'
    });
}

const AddressService = {
    findAddressesByCustomerId,
    getAddressById,
    create,
    update,
    del
};

export default AddressService;