import React from "react";
import {NavDropdown} from 'react-bootstrap';

class NavBarDropdownButton extends React.Component {

    isTabActive() {
        return this.props.value === this.props.tabValue;
    }

    render() {
        return (
            <NavDropdown.Item active={this.isTabActive()} eventKey={this.props.value} name={this.props.name}>{this.props.title}</NavDropdown.Item>
        );
    }
}

export default NavBarDropdownButton;