import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TeamTab from "./TeamTab";

export default class TeamListItem extends Component {

    renderDetailForm() {
        if (this.props.selected === this.props.data.id) {
            return (
                <tr>
                    <td colSpan="3">
                        <TeamTab {...this.props}/>
                    </td>
                </tr>
            );
        }
    }

    render() {
        if(this.props.data.id === "+" && this.props.selected !== this.props.data.id){
            return null;
        }
        return (
            <>
                <tr className={this.props.data.plus} onClick={() => this.props.onSelect(this.props.data.id)}>
                    <td>{this.props.data.id}</td>
                    <td>{this.props.data.name}</td>
                    <td>{this.props.data.leaderName}</td>
                </tr>
                {this.renderDetailForm()}
            </>
        );
    }
}

TeamListItem.propTypes = {
    data: PropTypes.object,
    onSelect: PropTypes.func
};