import React, {Component} from 'react';
import CustomerService from '../../services/customer';
import FormControl from '../common/FormControl';
import FormButton from '../common/FormButton';
import FormTextArea from '../common/FormTextarea';
import {toast} from 'react-toastify';
import RoleUSERUserFormControl from "../common/RoleUSERUserFormControl";
import i18next from 'i18next';
import UserService from "../../services/user";
import Utils from "../common/Utils";
import UserSuggestion from '../common/UserSuggestion';

class CustomerForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: this.props.customer,
            loggedInUser: {roles: []},
            managingUserId: 0
        };

        this.onChange = this.onChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onManagingUserIdChange = this.onManagingUserIdChange.bind(this);
    }

    componentDidMount() {
        UserService
            .getLoggedInUser()
            .then((response) => {
                this.setState({
                    managingUserId: response.id,
                    loggedInUser: response,
                    customer: {...this.state.customer, managingUserId: response.id}
                }, ()=> this.loadCustomer())
            }).catch((error) => {
            Utils.error(i18next.t("data-read-error" + JSON.stringify(error)));
        });
    }

    loadCustomer(){
        if (this.state.customer.id !== "+") {
            CustomerService
                .get(this.state.customer.id)
                .then((response) => {
                let customer = Object.assign({}, this.state.customer);
                customer.id = response.id;
                    this.setState({
                        customer: response,
                        managingUserId: response.managingUserId
                    })
                }).catch((error) => {
                    Utils.error(i18next.t("data-read-error" + JSON.stringify(error)));
                })
        }
    }

    onChange(event) {
        let customer = Object.assign({}, this.state.customer);
        customer[event.target.name] = event.target.value;
        this.setState({customer});
    }

    onReset(event) {
        event.preventDefault();
        let copiedCustomer = Object.assign(this.props.customer);
        copiedCustomer.managingUserId = this.state.managingUserId;
        this.setState({customer: copiedCustomer});
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.customer.id === "+") {
            CustomerService
                .create(this.state.customer)
                .then((response) => {
                    this.setState({customer: response})
                    this.props.onFindCustomers(this.props.filter);
                    this.props.onCreate();
                    toast.info(i18next.t("customer-add-success"), {
                        icon: false
                      });
                }).catch((error) => {
                     Utils.error(error.data.message);
            });
        } else {
            CustomerService
                .update(this.state.customer)
                .then((response) => {
                    this.props.onUpdate(this.state.customer);
                    toast.info(i18next.t("customer-update-success"), {
                        icon: false
                      });
					this.setState({
                        customer: response
                    });
                }).catch((error) => {
                     Utils.error(error.data.message);
            })
        }
    }

    onManagingUserIdChange(managingUserId){
        this.setState(prevState => ({
            customer: {
                ...prevState.customer,
                managingUserId: managingUserId
            }
        }))
    }

    getUserFormControl() {
        if (this.state.loggedInUser.roles.some(e => e === "ROLE_SUPERUSER")) {
            return <UserSuggestion label={i18next.t("managing-user")}
                                   onChangeSuggestionId={this.onManagingUserIdChange}
                                   inputPlaceholder={i18next.t("choose-the-user")}
                                   chosenUserId={this.state.customer.managingUserId}
                                   required={true}/>
        } else {
            return <RoleUSERUserFormControl label={i18next.t("managing-user")} name="managingUserId" value={this.state.customer.managingUserId} disabled={true} onChange={this.onChange}/>
        }
    }

    render() {
        return (
            <form id="customerForm" style={this.props.style} onReset={this.onReset} onSubmit={this.onSubmit}>
                <FormControl label={i18next.t("customer-name")} name="name" value={this.state.customer.name} onChange={this.onChange} required={true} />
                <FormControl label={i18next.t("external-id")} name="externalId" value={this.state.customer.externalId} onChange={this.onChange} required={true} />
                <FormControl label="NIP" name="nip" value={this.state.customer.nip} onChange = {this.onChange} help = {i18next.t("nip-help")}/>
                <FormTextArea label={i18next.t("description")} name="description" value={this.state.customer.description} onChange={this.onChange}/>
                <FormControl label={i18next.t("website")} name="wwwSite" value={this.state.customer.wwwSite} onChange={this.onChange}/>
                {this.getUserFormControl()}
                <div className="row buttons-to-left btn-grey-container btn-overlap-container">
                    <div className="col-sm-2">&nbsp;</div>
                    <FormButton type="submit"  value={i18next.t("save-button")}/>
                    <FormButton type="reset" value={i18next.t("cancel-button")}/>
                </div>
            </form>
        );
    }
}

export default CustomerForm;
