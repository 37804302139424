import React, {Component} from 'react';
import PropTypes from "prop-types";
import i18next from 'i18next';

export default class FilterField extends Component {

    render() {
        return (
            <th scope="col">
                <input className="form-control"
                       type={this.props.type}
                       pattern={this.props.pattern}
                       name={this.props.name}
                       value={this.props.value}
                       onChange={this.props.onChange}
                       placeholder={i18next.t("all")}/>
            </th>
        );
    }
}

FilterField.propTypes = {
    type: PropTypes.string,
    pattern: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func
};