import React, { Component } from 'react';
import PageableFilter from "../../common/PageableFilter";
import i18next from "i18next";

class SkillFilter extends Component {

	constructor(props) {
		super(props);

		this.onKeyDown = this.onKeyDown.bind(this);
	}

	onKeyDown(event) {
		if (event.keyCode === 13) {
			this.props.onSubmit();
		}
	}
	
    render() {
        return (
	    	<>
	    		<PageableFilter colSpan="3"
	    						onFilterChange={this.props.onFilterChange}
	    						findPageSize={this.props.filter.findPageSize}
	    						findPageNumber={this.props.filter.findPageNumber}
	    						resultSize={this.props.resultSize} />
	    		<tr>
					<th scope="col"/>
	    			<th scope="col"><input className="form-control" type="text" name="findName" value={this.props.filter.findName} onChange={this.props.onFilterChange}
	    								   onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
	    			<th scope="col"><input className="form-control" type="text" name="findSkillCategoryName" value={this.props.filter.findSkillCategoryName} onChange={this.props.onFilterChange}
	    								   onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
	    		</tr>
	    	</>
        );
    }
}

export default SkillFilter;