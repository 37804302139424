import React, { Component } from 'react';
import SkillForm from './SkillForm.js';
import UserService from "../../../services/user";
import i18next from "./SkillForm";
import Utils from "../../common/Utils";

class SkillListItem extends Component {
  
	constructor(props) {
		super(props);

		this.state = {
			loggedIn: {}
		};
		this.onCreateSkill = this.onCreateSkill.bind(this);
		this.onUpdateSkill = this.onUpdateSkill.bind(this);
	}	

	componentDidMount() {

		UserService.getLoggedInUser()
			.then((response) => {
				this.setState({
					loggedIn: response
				})
			})
			.catch(() => {
				Utils.error(i18next.t("logged-in-user-read-error"));
			})
	}

	onCreateSkill(skill) {
		this.props.onCreate(skill);
	}
	
	onUpdateSkill(skill) {
		this.props.onUpdate(skill);
	}

 	render() {
		if(this.props.skill.id === '+' && this.props.selected !== this.props.skill.id){
			return null;
		}
		let skillForm = null;
		if (this.props.selected === this.props.skill.id) {
		  	skillForm =
				<tr>
				  <td colSpan="3">
					  <SkillForm
						  key={this.props.skill.id}
						  skill={this.props.skill}
						  onCreate={this.onCreateSkill}
						  onUpdate={this.onUpdateSkill}
						  onFindSkills={this.props.onFindSkills}
						  filter={this.props.filter}
					      loggedIn={this.state.loggedIn}/>
				  </td>
				</tr>;
	  }
	  return (
    <>
		<tr className={this.props.skill.plus} onClick={() => this.props.onSelect(this.props.skill.id)}>
			<td>{this.props.skill.id}</td>
			<td>{this.props.skill.name}</td>
			<td>{this.props.skill.skillCategoryName}</td>
		</tr>{skillForm}</>
    );
  }
}

export default SkillListItem;