import React, {Component} from 'react';
import 'react-dates/initialize';
import PageableFilter from "../common/PageableFilter";
import DateRangePickerWrapper from "../common/DateRangePickerWrapper";
import ReconciliationStatusFormControl from "./ReconciliationStatusFormControl";

class ReconciliationFilter extends Component {

    render() {
        return (
            <>
                <PageableFilter colSpan="7" onFilterChange={this.props.onPageableFilterChange}
                                findPageSize={this.props.filter.findPageSize}
                                findPageNumber={this.props.filter.findPageNumber}
                                resultSize={this.props.resultSize}/>
                <tr>
                    <th scope="col"/>
                    <th scope="col">
                        <DateRangePickerWrapper onChange={this.props.onCalendarChangeStartDate}
                                                dateLeftLimit={this.props.filter.dateStartLeftLimit}
                                                dateRightLimit={this.props.filter.dateStartRightLimit}/>
                    </th>
                    <th scope="col">
                        <DateRangePickerWrapper onChange={this.props.onCalendarChangeEndDate}
                                                dateLeftLimit={this.props.filter.dateEndLeftLimit}
                                                dateRightLimit={this.props.filter.dateEndRightLimit}/>
                    </th>
                    <th scope="col"/>
                    <th scope="col">
                        <DateRangePickerWrapper onChange={this.props.onCalendarChangeGeneratedDate}
                                                dateLeftLimit={this.props.filter.generatedDateLeftLimit}
                                                dateRightLimit={this.props.filter.generatedDateRightLimit}/>
                    </th>
                    <th scope="col">
                        <DateRangePickerWrapper onChange={this.props.onCalendarChangeSentDate}
                                                dateLeftLimit={this.props.filter.sentDateLeftLimit}
                                                dateRightLimit={this.props.filter.sentDateRightLimit}/>
                    </th>
                    <th scope="col">
                        <ReconciliationStatusFormControl {...this.props} className="form-control" name="findStatus"
                                                         value={this.props.filter.findStatus}
                                                         onChange={this.props.onStatusChange}/>
                    </th>
                </tr>
            </>
        );
    }
}

export default ReconciliationFilter;