import React from 'react';
import PropTypes from 'prop-types';
import WorkListItemHeader from "./WorkListItemHeader";
import WorkListItem from "./WorkListItem";
import i18next from 'i18next';
import {toast} from 'react-toastify';
import {propTypes as prevState} from "react-csv/src/metaProps";

export default class WorkList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            zeroWidth: 0,
            firstWidth: 0,
            secondWidth: 0,
            thirdWidth: 0,
            fourthWidth: 0,
            fifthWidth: 0,
            minModifiable: 0,
            toastDisplayed: false,
            reloadKey: 0,
            daysInMonth: this.props.data.monthDefinition.days.length,
            totalSum: null,
            lastWidth: window.innerWidth
        };

        this.onChange = this.onChange.bind(this);
        this.setMinModifiable = this.setMinModifiable.bind(this);
    }

    componentDidMount() {
        this.calculateTotalSum();
        const zeroWidth = this.zeroColumn.offsetWidth - 1;
        const firstWidth = this.firstColumn.offsetWidth - 1;
        const secondWidth = this.secondColumn.offsetWidth - 1;
        const thirdWidth = this.thirdColumn.offsetWidth - 1;
        const fourthWidth = this.fourthColumn.offsetWidth - 1;
        const fifthWidth = this.fifthColumn.offsetWidth - 1;

        let minModifiable = this.props.data.monthDefinition.days.length;
        this.props.data.tasks.forEach((task) => {
            task.modifiable.forEach((mod, index) => {
                if(mod === true && index < minModifiable){
                    minModifiable = index + 1;
                }
            })
        });
        this.setState({
            zeroWidth: zeroWidth,
            firstWidth: firstWidth,
            secondWidth: secondWidth,
            thirdWidth: thirdWidth,
            fourthWidth: fourthWidth,
            fifthWidth: fifthWidth,
            minModifiable: minModifiable,
        },);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newDaysInMonth = this.props.data.monthDefinition.days.length;

        //Odświeżanie stylu timesheet po skalowaniu
        window.addEventListener('resize', (this.handleResize));

        if (newDaysInMonth !== this.state.daysInMonth){
            this.setState({
                reloadKey: this.state.reloadKey + 1,
                daysInMonth: newDaysInMonth
            })
        }
        if (prevProps.data !== this.props.data || prevProps.year !== this.props.year || prevProps.month !== this.props.month) {
            this.setState({
                data: this.props.data,
            }, () => {this.componentDidMount();});
        }
        if (prevState.data !== this.state.data) {
            this.calculateTotalSum();
        }
    }

    //Odświeżanie wizualne Worklist po skalowaniu
    handleResize = () => {
        const {lastWidth} = this.state;
        const currentWidth = window.innerWidth;
        const threshold = 75;

        if (Math.abs(currentWidth - lastWidth) >= threshold) {

            const zeroWidth = this.zeroColumn.offsetWidth - 1;
            const firstWidth = this.firstColumn.offsetWidth - 1;
            const secondWidth = this.secondColumn.offsetWidth - 1;
            const thirdWidth = this.thirdColumn.offsetWidth - 1;
            const fourthWidth = this.fourthColumn.offsetWidth - 1;
            const fifthWidth = this.fifthColumn.offsetWidth - 1

            this.setState({
                reloadKey: this.state.reloadKey + 1,
                lastWidth: currentWidth,
                zeroWidth: zeroWidth,
                firstWidth: firstWidth,
                secondWidth: secondWidth,
                thirdWidth: thirdWidth,
                fourthWidth: fourthWidth,
                fifthWidth: fifthWidth,
            });
        }
    }

    onChange(timesheetTaskDto) {
        let copyOfTasks = JSON.parse(JSON.stringify(this.state.data));

        for (let i = 0; i < copyOfTasks.tasks.length; i++) {
            if (copyOfTasks.tasks[i].taskId === timesheetTaskDto.taskId) {
                copyOfTasks.tasks[i] = timesheetTaskDto;
                break;
            }
        }
        this.setState({
            data: copyOfTasks,
            toastDisplayed: false
        }, () => {
            this.props.onChange(this.state.data);
        });
    }


    styleElement(leftParam){
        return {
            position: 'sticky',
            left: leftParam,
            backgroundColor: 'white',
            marginLeft: 0,
            marginRight: 0
        }
    }

    setMinModifiable(minModifiable){
        this.setState({
            minModifiable
        }, () => this.props.refresh())
    }

    calculateTotalSum() {
        let totalSum = 0;

        for (let i = 0; i < this.state.data.monthDefinition.days.length; i++) {
            let singleSum = 0;

            this.state.data.tasks.forEach((task) => {
                singleSum += Number(task.workedHours[i] * 1000) || 0;
            });

            totalSum += singleSum / 1000;
        }

        this.setState({ totalSum });
    }



    render() {
        let results = [];

        const style = {
            textAlign: 'center',
             border: '1px solid #c3c8cc'
        };
        const styleError = {
            textAlign: 'center',
            border: '1px solid #c3c8cc',
            backgroundColor: 'red'
        }
        for (let i = 0; i < this.state.data.monthDefinition.days.length; i++) {
            let singleSum = 0;
            this.state.data.tasks.forEach((task) => {
                singleSum += Number(task.workedHours[i]*1000) || 0;
            });

            if (singleSum/1000 <= 24 && singleSum/1000 >= 0) {
                results.push(<td style={style}>{singleSum/1000}</td>);
            } else {
                results.push(<td style={styleError}>0</td>);
                if (!this.state.toastDisplayed) {
                    toast.error(i18next.t("daily-h-error"), {icon: false});
                    this.setState({ toastDisplayed: true });
                }
            }
        }

        return (
                <table key={this.state.reloadKey} className="table table-sm table-worklist">
                    <WorkListItemHeader data={this.props.data.monthDefinition}
                                        getSorting={this.props.getSorting}
                                        filter={this.props.filter}
                                        modifiable={this.state.minModifiable}
                                        taskIds={this.props.data.tasks.map(task => task.taskId)}
                                        year={this.props.year}
                                        month={this.props.month}
                                        setMinModifiable={this.setMinModifiable}
                    />
                    <tbody>
                    {this.state.data.tasks.map((timesheetTaskDto) =>
                        <WorkListItem
                            key={timesheetTaskDto.taskId}
                            data={timesheetTaskDto}
                            monthDefinition={this.props.data.monthDefinition}
                            onChange={() => this.onChange(timesheetTaskDto)}
                        />)
                    }
                    <tr className="worklist-total-result-hours">
                        <td ref={(zeroColumn) => this.zeroColumn = zeroColumn}
                            style={this.styleElement(0)}>{i18next.t("in-total")}</td>
                        <td ref={(firstColumn) => this.firstColumn = firstColumn}
                            style={this.styleElement(this.state.zeroWidth)}></td>
                        <td ref={(secondColumn) => this.secondColumn = secondColumn}
                            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth)}></td>
                        <td ref={(thirdColumn) => this.thirdColumn = thirdColumn}
                            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth)}></td>
                        <td ref={(fourthColumn) => this.fourthColumn = fourthColumn}
                            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth)}></td>
                        <td ref={(fifthColumn) => this.fifthColumn = fifthColumn}
                            style={this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth)}></td>
                        <td style={{...this.styleElement(this.state.zeroWidth + this.state.firstWidth + this.state.secondWidth + this.state.thirdWidth + this.state.fourthWidth + this.state.fifthWidth),
                            textAlign: 'center',
                            border: "1px solid #c3c8cc",
                            backgroundColor: 'rgb(37, 161, 255)'
                        }}>
                            {this.state.totalSum}
                        </td>
                        {results}
                    </tr>
                    </tbody>
                </table>



        )


    }
    }

    WorkList.propTypes = {
                data: PropTypes.object.isRequired
            };
