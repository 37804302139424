import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageableFilter from "../common/PageableFilter";
import i18next from 'i18next';

class ProgramFilter extends Component {

    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.props.onSubmit();
        }
    }

    render() {
        return (
            <>
                <PageableFilter colSpan="7" onFilterChange={this.props.onFilterChange} onSubmit={this.props.onSubmit}
                    findPageSize={this.props.filter.findPageSize} findPageNumber={this.props.filter.findPageNumber}
                    resultSize={this.props.resultSize} />
                <tr>
                    <th />
                    <th scope="col"><input className="form-control" type="text" name="findCustomerName" value={this.props.filter.findCustomerName}
                        onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")} /></th>
                    <th scope="col"><input className="form-control" type="text" name="findName" value={this.props.filter.findName}
                        onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")} /></th>
                    <th scope="col"><input className="form-control" type="text" name="findCode" value={this.props.filter.findCode}
                        onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")} /></th>
                    <th scope="col"><select className="custom-select" name="findActive" value={this.props.filter.findActive} onChange={this.props.onFilterChange}>
                        <option value="">{i18next.t('all')}</option>
                        <option value="true">{i18next.t('active-female-plural')}</option>
                        <option value="false">{i18next.t('inactive')}</option>
                    </select></th>
                    <th scope="col"><input className="form-control" type="text" name="findManagingUser" value={this.props.filter.findManagingUser}
                        onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")} /></th>
                    <th scope="col"><input className="form-control" type="text" name="findPurchaseOrderId" value={this.props.filter.findPurchaseOrderId}
                        onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")} /></th>
                    {/* <th/> */}
                </tr>
            </>
        );
    }
}

ProgramFilter.propTypes = {
    onFilterChange: PropTypes.func
};

export default ProgramFilter;