import request from '../lib/request';

function find(searchParams) {
    return request({
        url: "/reconciliations",
        method: 'GET',
        params: {
            status: searchParams.findStatus,
            dateStartLeftLimit: searchParams.dateStartLeftLimit,
            dateStartRightLimit: searchParams.dateStartRightLimit,
            dateEndLeftLimit: searchParams.dateEndLeftLimit,
            dateEndRightLimit: searchParams.dateEndRightLimit,
            generatedDateLeftLimit: searchParams.generatedDateLeftLimit,
            generatedDateRightLimit: searchParams.generatedDateRightLimit,
            sentDateLeftLimit: searchParams.sentDateLeftLimit,
            sentDateRightLimit: searchParams.sentDateRightLimit,
            size: searchParams.findPageSize === undefined ? "50" : searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy+","+searchParams.sortDirection
        }
    });
}

function create(reconciliation) {
    var data = Object.assign({}, reconciliation);
    data.id = null;
    return request({
        url: "/reconciliations",
        method: 'POST',
        data: data
    });
}

function update(reconciliation) {
    return request({
        url: "/reconciliations",
        method: 'PUT',
        data: reconciliation
    });
}

function get(id) {
    return request({
        url: `/reconciliations/${id}`,
        method: 'GET'
    });
}

const ReconciliationService = {
    find,
    create,
    update,
    get
}

export default ReconciliationService;