import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import ReportIssueForm from "./ReportIssueForm";
import emailService from "../../services/email";
import {toast} from "react-toastify";
import Label from "../common/Label";
// import PrimaryButton from "../common/PrimaryButton";
import FormButton from "../common/FormButton";
import i18next from 'i18next';
import Utils from "../common/Utils";

class ReportIssue extends Component {

    state = {
        modal: false,
        disableButton: false
    };

    handleClose = () => {
        this.setState({
            modal: false,
            disableButton: false
        });
    }

    handleShow = () => {
        this.setState({modal: true});
    }

    handleUpload = (files, message) => {
        this.buttonStateChange();
        let formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });
        emailService
            .sendEmailWithAttachment(message, formData)
            .then(() => {
                this.handleClose();
                toast.info(i18next.t("email-send"), {
                    icon: false
                  });
            }).catch((error) => {
                this.buttonStateChange();
                Utils.error(i18next.t("email-send-fail"));
                console.error(error);
            })
    };

    buttonStateChange = () => {
        this.setState({disableButton: !this.state.disableButton})
    };

    render() {
        return (
            <div>

                <Modal show={this.state.modal} onHide={this.handleClose}>
                    <ReportIssueForm handleUpload={this.handleUpload} handleClose={this.handleClose}
                                     disableButton={this.state.disableButton}/>
                </Modal>
                <div className="profile-box-container">
                    <h4 className="profile-main-headline"><Label messageKey="profile-issue-title"/></h4>
                    <hr className="my-headline"/>
                    <FormButton valueKey="profile-issue-button" onClick={this.handleShow} />
                </div>
            </div>
        );
    }
}

export default ReportIssue;