import React, {Component} from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import PageableFilter from '../common/PageableFilter';
import SortableColumn from "../common/SortableColumn";
import FilterField from "./FilterField";
import ListTitle from "../common/ListTitle";

class TeamFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
		    filter: {},
            addButtonLabel: i18next.t("add-new")
		};

		this.onFilterChange = this.onFilterChange.bind(this);
		this.saveFilter = this.saveFilter.bind(this);
		this.addNewItem = this.addNewItem.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
	    if(prevProps.listItemClickChange !== this.props.listItemClickChange){
	        this.setState({
                addButtonLabel: i18next.t("add-new")
            })
        }
    }

    onFilterChange(event) {
        let filter = Object.assign({}, this.state.filter);
        filter[event.target.name] = event.target.value;
        if(!["findPageNumber", "sortBy", "sortDirection"].includes(event.target.name)){
            filter["findPageNumber"] = "0";
        }
        this.saveFilter(filter);
    }

    saveFilter(filter) {
        this.setState({
            filter
        }, () => this.props.onSubmit(this.state.filter));
    }

    addNewItem(){
	    this.props.addNewItem();
        this.setState(prevState => ({
            addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
        }))
    }

    render() {
        return (
            <thead>
            <ListTitle sectionLabel={i18next.t("navbar-teams")} span={1}
                       onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
		    	<PageableFilter colSpan="3"
                                onFilterChange={this.onFilterChange}
		    					findPageSize={this.state.filter.findPageSize}
		    					findPageNumber={this.state.filter.findPageNumber}
		    					resultSize={this.props.resultSize}/>
		    	<tr>
                    <th scope="col"/>
                    <FilterField type="text" name="findName" value={this.state.filter.findName} onChange={this.onFilterChange}/>
                    <FilterField type="text" name="findLeader" value={this.state.filter.findLeader} onChange={this.onFilterChange}/>
		    	</tr>
                <tr>
                    <SortableColumn name="t.id"
                                    label="Id"
                                    filter={this.state.filter}
                                    onClick={this.saveFilter}/>
                    <SortableColumn name="t.name"
                                    label={i18next.t("team-name")}
                                    filter={this.state.filter}
                                    onClick={this.saveFilter}/>
                    <SortableColumn name = "u.firstName, u.lastName, u.login"
                                    label={i18next.t("leader")}
                                    filter={this.state.filter}
                                    onClick={this.saveFilter}/>
                </tr>
            </thead>
        );
    }
}

TeamFilter.propTypes = {
    resultSize: PropTypes.number,
    onSubmit: PropTypes.func
}

export default TeamFilter;