import React, { Component } from 'react';
import i18next from 'i18next';
import BusinessReportListItem from './BusinessReportListItem';
import BusinessReportAdditionalFilter from './BusinessReportAdditionalFilter';
import SortableColumn from "../../common/SortableColumn";

class BusinessReportList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            additionalFilter: {},
            list: this.props.reports
        };

        this.onFilterChange = this.onFilterChange.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
        this.sortBusinessReports = this.sortBusinessReports.bind(this);
        this.filterRecords = this.filterRecords.bind(this);
    }

    onFilterChange(event) {
        let additionalFilter = Object.assign({}, this.state.additionalFilter);
        const name = event.target.name;
        additionalFilter[name] = event.target.value;
        this.setState({
            additionalFilter: additionalFilter
        }, ()=> {
            this.filterRecords();
            this.onClickSort(this.state.additionalFilter);
        });
    }

    filterRecords(){
        let copyAddFilter = Object.assign({}, this.state.additionalFilter);
        delete copyAddFilter.sortBy;
        delete copyAddFilter.sortDirection;
        const list = this.props.reports.filter ((item) => {
            for(const[key, value] of Object.entries(copyAddFilter)){
                if(!item[key].toString().toLowerCase().includes(value.toString().toLowerCase())){
                    return false;
                }
            }
            return true;
        });
        this.setState({
            list: list
        })
    }

    onClickSort(filter){
        this.setState(prevState => ({
            additionalFilter: {
                ...prevState.additionalFilter,
                sortBy: filter.sortBy,
                sortDirection: filter.sortDirection
            }
        }), () => {
            this.sortBusinessReports(this.state.additionalFilter.sortBy,
                this.state.additionalFilter.sortDirection);
        })
    }


    sortBusinessReports (sortBy, sortDirection) {
        let copyList = JSON.parse(JSON.stringify(this.state.list));
        let direction = 1;
        if (sortDirection !== "ASC") {
            direction = -1;
        }
        copyList.sort((x, y) =>  direction*((x[sortBy] === y[sortBy]) ? 0 : ((x[sortBy] > y[sortBy]) ? 1 : -1)));
        this.setState({
            list: copyList
        });
    }

    render() {
        return (
            <>
	    	    <table className="table">
	    	        <thead>
                        <BusinessReportAdditionalFilter filter={this.state.additionalFilter} onFilterChange={this.onFilterChange}
                                                        onFindReports={this.props.findReports} reports={this.state.list}/>
                        <tr>
                            <SortableColumn label={i18next.t("customer-name")} filter={this.state.additionalFilter}
                            name="customerName" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("order-no")} filter={this.state.additionalFilter}
                                            name="orderId" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("portfolio-name")} filter={this.state.additionalFilter}
                                            name="portfolioName" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("project-name")} filter={this.state.additionalFilter}
                                            name="projectName" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("project-no")} filter={this.state.additionalFilter}
                                            name="projectId" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("planned-hours")} filter={this.state.additionalFilter}
                                            name="plannedHours" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("logged-hours")} filter={this.state.additionalFilter}
                                            name="loggedHours" onClick={this.onClickSort}/>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.list.map((report) =>
                            <BusinessReportListItem key={report.id} report={report}/>)}
                    </tbody>
                </table>
            </>
        );
    }
}

export default BusinessReportList;