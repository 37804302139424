import React, {Component} from 'react';
import ReconciliationList from './ReconciliationList.js';
import ReconciliationService from '../../services/reconciliation.js';
import i18next from 'i18next';
import DictionaryService from "../../services/dictionary";
import Utils from "../common/Utils";

class ReconciliationFilteredList extends Component {

    interval = null;

    constructor(props) {
        super(props);
        this.state = {
            reconciliationData: [],
            reconciliationDataHasNewStatus: "no",
            statuses: [],
            filter: {}
        };

        this.findReconciliations = this.findReconciliations.bind(this);
        this.setRefreshInterval = this.setRefreshInterval.bind(this);
        this.setStatusesDictionary = this.setStatusesDictionary.bind(this);
    }

    componentDidMount() {
        this.findReconciliations({});
        this.setStatusesDictionary();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setRefreshInterval() {
        if (this.state.reconciliationData.some(e => e.status === 'NEW')) {
            if (this.state.reconciliationDataHasNewStatus !== "yes") {
                this.setState({
                    reconciliationDataHasNewStatus: "yes"
                });
                this.interval = setInterval(() => this.findReconciliations(this.state.filter), 2000);
            }
        } else if (this.state.reconciliationDataHasNewStatus === "yes") {
            this.setState({
                reconciliationDataHasNewStatus: "no"
            });
            clearInterval(this.interval);
        }
    }

    findReconciliations(filter) {
        this.setState({filter: filter}, () =>
            ReconciliationService
                .find(this.state.filter)
                .then((reconciliationData) => {
                    this.setState({reconciliationData}, () => this.setRefreshInterval());
                }).catch((error) => {
                    Utils.error(i18next.t("reconciliation-error") + JSON.stringify(error));
                    clearInterval(this.interval);
                })
        );
    }

    setStatusesDictionary() {
        DictionaryService
            .getReconciliationStatuses()
            .then((result) => {
                this.setState({statuses: result});
            });
    }

    render() {
        return <ReconciliationList reconciliations={this.state.reconciliationData} onFindReconciliations={this.findReconciliations}
                                   statuses={this.state.statuses}/>
    }
}

export default ReconciliationFilteredList;