import React, { Component } from 'react';
import SecurityService from "../../services/security";
import FormControl from "../common/FormControl";
import FormButton from "../common/FormButton";
import { ACCESS_TOKEN, USE_ACTIVE_DIRECTORY } from "../common/Constants";
import ResetPasswordForm from "./ResetPasswordForm";
import i18next from 'i18next';
import Button from 'react-bootstrap/Button';

export default class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: { login: "", password: "", reset: false, errorInfo: null }
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onReset = this.onReset.bind(this);
    }

    onChange(event) {
        let user = Object.assign({}, this.state.user);
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }

    componentDidMount() {
        if (this.props.tokenStatus !== undefined && this.props.tokenStatus.valid === false) {
            this.setState({
                error: "⚠ " + i18next.t("password-reset-link-is-not-valid")
            })
        }
    }

    onSubmit(event) {
        event.preventDefault();
        const loginRequest = Object.assign({}, this.state.user);
        SecurityService
            .login(loginRequest)
            .then(response => {
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                this.props.onLogin();
                document.body.style.backgroundColor = "#ffffff";
            }).catch(error => {
                let info = "";
                if (error.data !== undefined) {
                    info = error.data.message;
                } else {
                    info = JSON.stringify(error);
                }
                this.setState({
                    error: "⚠ " + info
                });
            });
    }

    onReset() {
        this.setState({
            reset: true
        });
    }

    renderLoginFields() {
        if (this.props.tokenStatus === undefined) {
            return <>
                {/* <FormControl label={i18next.t("login")} name="login" value={this.state.user.login} onChange={this.onChange}
                                    type="text" style={{width: "300px", backgroundColor: "#ffffff"}}/>
                    <FormControl label={i18next.t("password")} name="password" value={this.state.user.password} onChange={this.onChange} type="password" style={{width: "300px", backgroundColor: "#ffffff"}}/> */}
                {/* <th><div style={{width: "52px", backgroundColor: "#ffffff"}}></div></th> */}
                {/* <th><FormButton type="submit" value={i18next.t("sign-in-button")}/></th>
                                                        <th><Button variant="link" onClick={this.onReset}>{i18next.t("reset-password-button")}</Button></th> */}
                <FormControl label={i18next.t("login")} name="login" value={this.state.user.login} onChange={this.onChange}
                    type="text" style={{ backgroundColor: "#ffffff" }} />

                <FormControl label={i18next.t("password")} name="password" value={this.state.user.password} onChange={this.onChange} type="password" style={{ backgroundColor: "#ffffff" }} />

                <div className="log-window-btns">
                    <FormButton type="submit" value={i18next.t("sign-in-button")} />
                    {USE_ACTIVE_DIRECTORY ||
                        <Button variant="link" onClick={this.onReset}>{i18next.t("reset-password-button")}</Button>}
                </div>
            </>
        } else {
            return <div><Button variant="link" onClick={this.onReset}>{i18next.t("reset-password-button")}</Button></div>
        }
    }

    render() {
        if (this.state.reset) {
            return <ResetPasswordForm token={this.props.tokenStatus} />
        }
        return (
            <div className="log-window-container">
                <h2 className="log-window-headline">{i18next.t("sign-in")}</h2>
                <tr><h6 style={{ color: '#FD1C03' }}>{this.state.error}</h6></tr>
                <form id="loginForm" className="justify-content-center log-window-form" onSubmit={this.onSubmit}>
                    {this.renderLoginFields()}
                </form>
            </div>
        );
    }
}