import React from "react";
import Container from "./Container";
import NavBarButton from "./NavBarButton";
import NavBarDropdownButton from "./NavBarDropdownButton";
import LanguageChange from "./components/profile/LanguageChange";
import i18next from 'i18next';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {ACCESS_TOKEN} from "./components/common/Constants";
import UserService from "./services/user";
import MenuService from "./services/menu";
import Utils from "./components/common/Utils";

class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabValue: "users",
            menu: {},
            loggedInUser: {}
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(eventKey, event) {
        if (eventKey !== "logout") {
            this.setState({[event.currentTarget.name]: eventKey});
        } else {
            NavBar.clearData()
        }
    }

    static clearData() {
        localStorage.removeItem(ACCESS_TOKEN)
        window.location.reload()
    }

    componentDidMount() {
        UserService
            .getLoggedInUser()
            .then((response) => {
                this.setState({
                    loggedInUser: response,
                    tabValue: (response.roles.includes("ROLE_USER") ? "timesheets" : "users")
                }, ()=> this.checkTabAccess())
            }).catch((error) => {
                Utils.error(i18next.t("user-read-error") + JSON.stringify(error));
            });
    }

    checkTabAccess() {
        MenuService
            .findVisibleTabs()
            .then((response) => {
                this.setState({
                    menu: response
                })
            }).catch((error) => {
                Utils.error("ERROR " + JSON.stringify(error));
            });
    }



    render() {
        return (
            <div>
                <Navbar fluid="true" variant="dark" collapseOnSelect expand="lg" fixed="top" onSelect={(this.handleChange)}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        <Navbar.Brand>
                        <a href="https://partner.rockitworks.pl" target='_blank' rel='noopener noreferrer'>
                            <img alt="logo"
                                src='/Logos.gif'
                                width="150"
                                height="40"
                                className="d-inline-block align-top"/>
                        </a>
                        </Navbar.Brand>
                            <NavBarButton name="tabValue" value="customers" title={i18next.t("navbar-customers")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="orders" title={i18next.t("navbar-orders")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="programs" title={i18next.t("navbar-programs")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="projects" title={i18next.t("navbar-projects")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="tasks" title={i18next.t("navbar-tasks")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="timesheets" title={i18next.t("navbar-timesheets")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="reports" title={i18next.t("reports")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="reconciliations" title={i18next.t("navbar-reconciliations")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="teams" title={i18next.t("navbar-teams")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="users" title={i18next.t("navbar-users")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                            <NavBarButton name="tabValue" value="adminpanel" title={i18next.t("navbar-configuration")}
                                          menu={this.state.menu} tabValue={this.state.tabValue}/>
                        </Nav>
                        <Nav>
                            <LanguageChange/>
                            <NavDropdown  title={<img src="/Symbol.gif" alt={this.state.loggedInUser.firstName + " " +  this.state.loggedInUser.lastName}
                            width={30} />}  id="basic-nav-dropdown">
                                <NavBarDropdownButton name="tabValue" value="profile"
                                                      title={i18next.t("navbar-profile")}
                                                      roles={this.state.roles} tabValue={this.state.tabValue}/>
                                <NavBarDropdownButton name="tabValue" value="logout"
                                                      title={i18next.t("profile-logout")}
                                                      tabValue={this.state.tabValue}/>
                            </NavDropdown>
                            <NavBarButton name="tabValue" value="logout" title={<img src="/Clipboard01.gif" alt="Log out"
                            width={30} />} menu={this.state.menu} tabValue={this.state.tabValue}
                            tooltip={i18next.t("profile-logout")}/>
                        </Nav>
                        <Navbar.Text>&nbsp;</Navbar.Text>
                    </Navbar.Collapse>

                </Navbar>
                <Container name={this.state.tabValue} roles={this.state.loggedInUser.roles}/>
            </div>
        );
    }
}

export default NavBar;