import UserFormControl from "../common/UserFormControl";
import UserService from "../../services/user";

class RoleUSERUserFormControl extends UserFormControl {

    componentDidMount() {
        UserService
            .findUsersRoleUser()
            .then((result) => {
                this.setState({users: result});
            });
    }
}

export default RoleUSERUserFormControl;