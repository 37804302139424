import React from 'react';
import SkillList from './SkillList.js';
import SkillService from '../../../services/skill.js';
import ErrorHandlerComponent from "../../common/error/ErrorHandlerComponent";
import i18next from 'i18next';
import Utils from "../../common/Utils";

class SkillFilteredList extends ErrorHandlerComponent {
	
	constructor(props) {
		super(props);
		this.state = {
		    skillData: []
		};

		this.findSkills = this.findSkills.bind(this);
	}

    componentDidMount() {
        this.findSkills({});
    }

	findSkills(filter) {
		SkillService
		    .find(filter)
			.then((skillData) => {
				this.setState({skillData});				
			})
			.catch((error) => {
				Utils.error(i18next.t("data-read-error") + JSON.stringify(error));
				this.setState({error});
			});
	}
	
    doRender() {
        return (
	    	<SkillList skills={this.state.skillData} onFindSkills={this.findSkills}/>
        );
    }
}

export default SkillFilteredList;