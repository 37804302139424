import React , {Component} from 'react';
import TaskReportFilteredList from './TaskReportFilteredList';

class TaskReportContainer extends Component{

    render() {
        return <TaskReportFilteredList {...this.props}/>
    }
}

export default TaskReportContainer;
