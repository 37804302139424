import React, { Component } from 'react';

class InactiveListItem extends Component {

	constructor(props) {
		super(props);

        this.onCheckItem = this.onCheckItem.bind(this);
	}

    onCheckItem(event) {
        this.props.onCheckItem(event.target.name, event.target.checked);
    }

    	renderProjectData() {
    	    if (this.props.object.projectCode != null){
                return <>
                    <td>{this.props.object.projectCode}</td>
                	 <td>{this.props.object.projectName}</td>
                </>
    	    }
    	}

 	render() {
	    return (
            <>
		        <tr>
		        	<td><input name={this.props.object.id} type="checkbox" checked={this.props.selected} onChange={this.onCheckItem} /></td>
		        	<td>{this.props.object.id}</td>
		        	<td>{this.props.object.name}</td>
		        	<td>{this.props.object.code}</td>
		        	<td>{this.props.object.active ? "✔" : ""}</td>
		        	{this.renderProjectData()}
		        </tr>
		    </>
        );
    }
}


export default InactiveListItem;