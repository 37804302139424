import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import StreetService from "../../services/street";
import Theme from "./autosuggestStyle";
import i18next from 'i18next';
import Utils from "../common/Utils";

class StreetSuggestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            suggestedStreets: [],
            value: this.props.street
        };

        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.street !== prevProps.street) {
            this.setState({
                value: this.props.street
            });
        }
    }


    getSuggestions = value => {
        const inputLength = value.length;
        if (inputLength > 1) {
            StreetService
                .findSuggestedStreets(value)
                .then((response) => {
                    this.setState({
                        suggestedStreets: response
                    })
                }).catch((error) => {
                    Utils.error(i18next.t("data-read-error") + JSON.stringify(error));
                })
        } else {
            this.setState({
                suggestedStreets: []
            })
        }
    };

    getSuggestionValue = suggestion => suggestion.streetName;

    renderSuggestion = suggestion => {
        const style = {
            display: "inline-block"
        };
        return(
            <div>
                <h6 style={style}>{suggestion.streetName}</h6> &nbsp;
                <p style={style}>{suggestion.settlementName}/{suggestion.municipalityName}/{suggestion.countyName}/{suggestion.voivodeshipName}</p>
            </div>)
    };

    onSuggestChange = (event, {newValue}) => {
        if(!(newValue.includes(';') || newValue.includes('\\') || newValue.includes('/'))) {
            this.setState({
                value: newValue
            }, () => {
                this.props.passStreet(this.state.value);
            });
        }
    };

    onSuggestionsFetchRequested = ({value}) => {
        if(!(value.includes(';') || value.includes('\\') || value.includes('/'))) {
            this.getSuggestions(value);
        }
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected(event, {suggestion}) {
        this.props.passData(suggestion.streetName, suggestion.settlementName,
            suggestion.municipalityName, suggestion.countyName,
            suggestion.voivodeshipName);
    }

    getInputProps() {
        return {
            value: this.state.value,
            onChange: this.onSuggestChange
        }
    }

    render() {
        return (
            <div className="form-group row">
                <label className="col-sm-2 control-label" htmlFor="Street">{i18next.t("street")}</label>
                <div className="col-sm-6">
                    <Autosuggest label={i18next.t("street")}
                                 name="street"
                                 suggestions={this.state.suggestedStreets}
                                 onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                 onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                 getSuggestionValue={this.getSuggestionValue}
                                 renderSuggestion={this.renderSuggestion}
                                 inputProps={this.getInputProps()}
                                 onSuggestionSelected={this.onSuggestionSelected}
                                 theme={Theme}/>
                </div>
            </div>
        )
    }
}

export default StreetSuggestion;