import React from 'react';
import SkillCategoryList from './SkillCategoryList.js';
import SkillCategoryService from '../../../services/skillCategory.js';
import ErrorHandlerComponent from "../../common/error/ErrorHandlerComponent";
import i18next from 'i18next';
import Utils from "../../common/Utils";

class SkillCategoryFilteredList extends ErrorHandlerComponent {
	
	constructor(props) {
		super(props);
		this.state = {
		    skillCategoryData: []
		};

		this.findSkillCategories = this.findSkillCategories.bind(this);
	}

    componentDidMount() {
        this.findSkillCategories({});
    }

	findSkillCategories(filter) {
		SkillCategoryService
		    .find(filter)
			.then((skillCategoryData) => {
				this.setState({skillCategoryData});
			})
			.catch((error) => {
				Utils.error(i18next.t("skill-category-reading-error" + JSON.stringify(error)));
				this.setState({error});
			});
	}
	
    doRender() {
        return (
	    	<SkillCategoryList skillCategories={this.state.skillCategoryData} onFindSkillCategories={this.findSkillCategories}/>
        );
    }
}

export default SkillCategoryFilteredList;