import React, {Component} from 'react';
import TaskReportContainer from './task/TaskReportContainer';
import TaskGroupReportContainer from './taskGroup/TaskGroupReportContainer';
import BusinessReportContainer from './business/BusinessReportContainer';
import InactiveContainer from './inactive/InactiveContainer';
import { Tab, Tabs } from 'react-bootstrap';
import i18next from 'i18next';
import BusinessReportService from "../../services/businessReport";
import TaskReportService from "../../services/taskReport";
import InactiveService from "../../services/inactive";

class ReportContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            activeTab : "1",
            taskReportsVisible: false,
            businessReportsVisible: false,
            inactiveReportsVisible: false
        };

        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        BusinessReportService
            .getRoles()
            .then((result) => {
                this.setState({businessReportsVisible: (result.length > 0)});
            });

        TaskReportService
            .getRoles()
            .then((result) => {
                this.setState({taskReportsVisible: (result.length > 0)});
            });

        InactiveService
            .getRoles()
            .then((result) => {
                this.setState({inactiveReportsVisible: (result.length > 0)});
            });
    }

    handleSelect (selectedTab){
        this.setState({
            activeTab: selectedTab
        });
    }

    getTaskReportContainer() {
        if (this.state.activeTab === "1") {
            return <TaskReportContainer detailed={false}/>
        }
    }

    getTaskReportDetailedContainer() {
        if (this.state.activeTab === "2") {
            return <TaskReportContainer detailed={true}/>
        }
    }

    getTaskGroupReportContainer() {
        if (this.state.activeTab === "3"){
            return <TaskGroupReportContainer/>
        }
    }

    getBusinessReportContainer() {
        if (this.state.activeTab === "4") {
            return <BusinessReportContainer/>
        }
    }

    getInactiveContainer() {
        if (this.state.activeTab === "5") {
            return <InactiveContainer/>
        }
    }


    renderTaskReportTab() {
        if (this.state.taskReportsVisible) {
            return (
                <Tab eventKey="1" title={i18next.t("task-reports")}>
                    {this.getTaskReportContainer()}
                </Tab>
            );
        }
    }

    renderTaskDetailedReportTab() {
        if (this.state.taskReportsVisible) {
            return (
                <Tab eventKey="2" title={i18next.t("task-detailed-reports")}>
                    {this.getTaskReportDetailedContainer()}
                </Tab>
            );
        }
    }

    renderTaskGroupReportTab() {
        if(this.state.taskReportsVisible) {
            return (
                <Tab eventKey="3" title={i18next.t("task-group-reports")}>
                    {this.getTaskGroupReportContainer()}
                </Tab>
            );
        }
    }

    renderBusinessReportTab() {
        if (this.state.businessReportsVisible) {
            return (
                <Tab eventKey="4" title={i18next.t("business-reports")}>
                    {this.getBusinessReportContainer()}
                </Tab>
            );
        }
    }

    renderInactiveTab() {
        if (this.state.inactiveReportsVisible) {
            return (
                <Tab eventKey="5" title={i18next.t("inactivity")}>
                    {this.getInactiveContainer()}
                </Tab>
            );
        }

    }

    render() {
        return (
            <>
            <h2 className="headline-main-style">{i18next.t("reports")}</h2>
            <Tabs className="nav-tabs-exception" activeKey = {this.state.activeTab} onSelect ={this.handleSelect}>
                {this.renderTaskReportTab()}
                {this.renderTaskDetailedReportTab()}
                {this.renderTaskGroupReportTab()}
                {this.renderBusinessReportTab()}
                {this.renderInactiveTab()}
            </Tabs>
            </>
        );
    }
}

export default ReportContainer;
