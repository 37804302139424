import React, {Component} from 'react';
import TaskStatusFilter from './TaskStatusFilter.js';
import TaskStatusListItem from './TaskStatusListItem.js';
import SortableColumn from "../../common/SortableColumn";
import i18next from 'i18next';
import PageableFilter from "../../common/PageableFilter";
import ListTitle from "../../common/ListTitle";

class TaskStatusList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedRowId: null,
			filter: {},
			addButtonLabel: i18next.t("add-new")
		};

		this.selectRow = this.selectRow.bind(this);
		this.findTaskStatuses = this.findTaskStatuses.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onCreateTaskStatus = this.onCreateTaskStatus.bind(this);
		this.onUpdateTaskStatus = this.onUpdateTaskStatus.bind(this);
		this.onSortOrder = this.onSortOrder.bind(this);
		this.addNewItem =this.addNewItem.bind(this);
	}

	selectRow(key) {
		this.setState((prevState) => {
				return {selectedRowId: key === prevState.selectedRowId ? null : key,
					    addButtonLabel: i18next.t("add-new")}
			});
	}

	findTaskStatuses() {
		this.props.onFindTaskStatuses(this.state.filter);
	}

	onFilterChange(event) {
	    let filter = {...this.state.filter};
		let targetName = event.target.name;
		filter[targetName] = event.target.value;
		if(!["findPageNumber", "sortBy", "sortDirection"].includes(targetName)){
			filter["findPageNumber"] = "0";
		}
		this.setState({filter}, () => {
				this.props.onFindTaskStatuses(this.state.filter)
		});
	}

	onCreateTaskStatus(taskStatus) {
    	this.setState({selectedRowId: null,
							addButtonLabel: i18next.t("add-new")});
    }

    onUpdateTaskStatus(taskStatus) {
    	this.setState({taskStatus: taskStatus});
    	this.props.onFindTaskStatuses(this.state.filter);
    }

    onSortOrder(filter) {
        this.setState({filter: filter, selectedRowId: null}, () => this.findTaskStatuses());
    }

	addNewItem(){
		this.setState(prevState => ({
			selectedRowId: prevState.selectedRowId === '+' ? null : '+',
			addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
		}))
	}

	render() {
	  	return (
		    <table className="table table-sm">
		    	<thead>
					<ListTitle sectionLabel={i18next.t("task-status")} span={0}
						   onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
		    	    <TaskStatusFilter resultSize={this.props.taskStatuses.length} filter={this.state.filter} onFilterChange={this.onFilterChange} onSubmit={this.findTaskStatuses} />
		    	    <tr>
		    	    	<SortableColumn name="t.id" label="Id" filter={this.state.filter} onClick={this.onSortOrder} />
		    	    	<SortableColumn name="v.value" label={i18next.t("name")} filter={this.state.filter} onClick={this.onSortOrder} />
		    	    </tr>
		    	</thead>
		    	<tbody>
					{this.renderEmptyItem()}
		    		{this.renderAllItems()}
					<PageableFilter colSpan="2" onFilterChange={this.onFilterChange}
									findPageSize={this.state.filter.findPageSize}
									findPageNumber={this.state.filter.findPageNumber}
									resultSize={this.props.taskStatuses.length}
									hidePageSize={true}/>
		    	</tbody>
		    </table>
		);
  	}

  	renderAllItems() {
	  	return this.props.taskStatuses.map((taskStatus) => this.renderItem(taskStatus));
	}

  	renderItem(taskStatus) {
		return <TaskStatusListItem key={taskStatus.id}
		                        selected={this.state.selectedRowId}
		                        taskStatus={taskStatus}
		                        onSelect={this.selectRow}
		                        onUpdate={this.onUpdateTaskStatus}
		                        onFindTaskStatuses={this.props.onFindTaskStatuses}
		                        filter={this.state.filter} />
	}

  	renderEmptyItem() {
		return <TaskStatusListItem key={'+'}
								selected={this.state.selectedRowId}
								onSelect={this.selectRow}
								onCreate={this.onCreateTaskStatus}
								taskStatus={{id: "+", name: "", plus: "plus"}}
								onFindTaskStatuses={this.props.onFindTaskStatuses} filter={this.state.filter} />
		}
}

export default TaskStatusList;