import React, { Component } from 'react';
import CustomerList from "./CustomerList";
import CustomerService from "../../services/customer";
import i18next from 'i18next';
import Utils from "../common/Utils";

class CustomerFilteredList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerData: []
        };

        this.findCustomers = this.findCustomers.bind(this);
    }

    componentDidMount() {
        this.findCustomers({});
    }

    findCustomers(filter) {
        CustomerService.find(filter)
            .then((response) => {
                this.setState({
                    customerData : response
                })
            }).catch((error) => {
                Utils.error(i18next.t("data-read-error" + JSON.stringify(error)));
        })
    }

    render() {
        return (
            <CustomerList customers={this.state.customerData} onFindCustomers={this.findCustomers}/>
        );
    }
}

export default CustomerFilteredList;