import React, {Component} from 'react';
import TaskForm from './TaskForm.js';
import { Tab, Tabs } from 'react-bootstrap';
import i18next from 'i18next';

class TaskListItem extends Component {

	constructor(props) {
		super(props);

		this.onCreateTask = this.onCreateTask.bind(this);
		this.onUpdateTask = this.onUpdateTask.bind(this);
	}

	onCreateTask(task) {
        this.props.onCreate(task);
    }

    onUpdateTask(task) {
        this.props.onUpdate(task);
    }

	render() {
		if(this.props.task.id === '+' && this.props.selected !== this.props.task.id){
			return null;
		}
	    let taskForm = null;
	    if (this.props.selected === this.props.task.id) {
    	    taskForm =
    		    <tr>
    		        <td colSpan="10">
						<Tabs className="tab-container-editform">
							<Tab eventKey="1" title={i18next.t("edit")}>
							</Tab>
						</Tabs>
    		    		<TaskForm key={this.props.task.id}
    		    		    task={this.props.task}
    		    		    onCreate={this.onCreateTask}
    		    		    onUpdate={this.onUpdateTask}
    		    		    onFindTasks={this.props.onFindTasks}
    		    		    filter={this.props.filter}
			    			copyAnItem={this.props.copyAnItem}/>
    		    	</td>
    		    </tr>;
    	  }
	    return (
	        <>
                <tr className={this.props.task.plus} onClick={() => this.props.onSelect(this.props.task.id)}>
                    <td>{this.props.task.id}</td>
					<td>{this.props.task.externalCode}</td>
                    <td>{this.props.task.name}</td>
                    <td>{this.props.task.assignedUserName}</td>
                    <td>{this.props.task.taskStatusName}</td>
                    <td>{this.props.task.active ? "✔" : ""}</td>
					<td>{this.props.task.projectCode}</td>
                    {this.renderProjectColumn(this.props.task.projectName)}
					<td>{this.props.task.programCode}</td>
					<td>{this.props.task.programName}</td>
                </tr>
                {taskForm}
	        </>
	    );
	}

	renderProjectColumn(projectName) {
		return <td>{projectName}</td>
	}
}

export default TaskListItem;