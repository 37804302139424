import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class FormButton extends Component {
  
    render() {
        return (
	    	// <div className="col-sm-2">
	    	// 	<input className={this.props.notVisible? "btn btn-primary not-visible" : "btn btn-primary"}
            //            type={this.props.type}
            //            value={this.props.valueKey === undefined ? this.props.value : i18next.t(this.props.valueKey)}
            //            name={this.props.name}
            //            onClick={this.props.onClick}/>
	    	// </div>
            <div className="col-sm-2">
            <input className={this.props.notVisible? "btn btn-success not-visible" : "btn btn-success"}
                   type={this.props.type}
                   value={this.props.valueKey === undefined ? this.props.value : i18next.t(this.props.valueKey)}
                   name={this.props.name}
                   onClick={this.props.onClick}/>
        </div>
        );
    }
}

FormButton.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    valueKey: PropTypes.string,
    onClick: PropTypes.func
}

export default FormButton;