import request from '../lib/request';

function getSkillsByUserId(id) {
    return request({
        url: `/userskill/${id}`,
        method: 'GET'
    });
}

function updateUserSkills(id, skills) {
    return request({
        url: `userskill/${id}`,
        method: 'PUT',
        data: skills
    });
}

const UserSkillService = {
    getSkillsByUserId,
    updateUserSkills
};

export default UserSkillService;