import React, {Component} from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {DateRangePicker} from 'react-dates';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import {LANGUAGE} from "../common/Constants";
import "moment/locale/pl"
import "moment/locale/en-gb"
import moment from "moment";
import i18next from 'i18next';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
    reactDates: {
        ...DefaultTheme.reactDates,
        sizing: {
            // inputWidth: 60,
            arrowWidth: 8
        },
        font: {
            size: 14,
            captionSize: 18,
            input: {
                size: 9,
                lineHeight: '16px'
            },
        },
    },
});

class DateRangePickerWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: props.dateLeftLimit,
            endDate: props.dateRightLimit,
            prevStart: null,
            focusedInput: null
        };

        if (localStorage.getItem(LANGUAGE)) {
            moment.locale(localStorage.getItem(LANGUAGE));
        } else {
            moment.locale("en-gb");
        }

        this.onFocusChange = this.onFocusChange.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dateLeftLimit !== this.props.dateLeftLimit ||
            prevProps.dateRightLimit !== this.props.dateRightLimit) {

            this.setState({
                prevStart: prevProps.dateLeftLimit,
                prevEnd: prevProps.dateRightLimit,
                startDate: this.props.dateLeftLimit,
                endDate: this.props.dateRightLimit
            })
        }
    }

    onDatesChange(start, end) {
        this.props.onChange({
            startDate: start,
            endDate: end
        });
    }

    onFocusChange = focusedInput => this.setState({focusedInput});

    render() {
        const{focusedInput, startDate, endDate} = this.state;
            let start = null;
            if (this.state.startDate === null) {
                if (this.state.prevStart === null) {
                    if (this.props.dateLeftLimit === null) {
                        start = new moment();
                    } else {
                        start = moment(this.props.dateLeftLimit)
                    }
                } else {
                    start = moment(endDate)
                }
            } else {
                start = moment(this.props.dateLeftLimit)
            }

        return (
            <DateRangePicker
                startDate={startDate}
                startDateId="dateLeftLimit"
                endDate={endDate}
                endDateId="dateRightLimit"
                onDatesChange={({startDate, endDate}) => this.onDatesChange(startDate, endDate)}
                focusedInput={focusedInput}
                onFocusChange={this.onFocusChange}
                startDatePlaceholderText={i18next.t('from')}
                endDatePlaceholderText={i18next.t('to')}
                displayFormat='DD.MM'
                isOutsideRange={() => {}}
                minimumNights={0}
                hideKeyboardShortcutsPanel={true}
                orientation="vertical"
                anchorDirection="left"
                initialVisibleMonth={() => start}
            />
        );
    }
}

export default DateRangePickerWrapper;