import request from '../lib/request';

function find(searchParams) {
     return request({
         url: "/inactive",
         method: 'GET',
         params: {
             objectType: searchParams.objectType,
             inactiveFromDays: searchParams.inactiveFromDays,
             showOnlyActive: searchParams.showOnlyActive
         }
     });
 }

 function deactivate(objectType, list) {
     return request({
     	url: "/inactive",
     	method: 'POST',
     	data: list,
     	params: {
            objectType: objectType
        }
     });
 }

 function getRoles() {
     return request({
         url: "/inactive/roles",
         method: 'GET'
     });
 }

const InactiveService = {
    find,
    deactivate,
    getRoles
}

export default InactiveService;