import React, { Component } from 'react';
import ProjectList from './ProjectList.js';
import ProjectService from '../../services/project.js';
import i18next from 'i18next';
import Utils from "../common/Utils";

class ProjectFilteredList extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		    projectData: []
		}

		this.findProjects = this.findProjects.bind(this);
	}

    componentDidMount() {
        this.findProjects({});
    }

	findProjects(filter) {
		ProjectService
		    .find(filter)
			.then((projectData) => {
				this.setState({projectData});
			})
			.catch((error) => {
				Utils.error(i18next.t("project-error") + JSON.stringify(error));
			});
	}
	
    render() {
        return (
	    	<ProjectList projects={this.state.projectData} onFindProjects={this.findProjects}/>
        );
    }
}

export default ProjectFilteredList;