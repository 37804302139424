import React, { Component } from 'react';
import i18next from 'i18next';
import BusinessReportList from './BusinessReportList';
import BusinessReportService from '../../../services/businessReport.js';
import FormButton from '../../common/FormButton.js';
import BusinessReportRoleList from './BusinessReportRoleList';
import DateRangePickerWrapper from '../../common/DateRangePickerWrapper';
import { SyncLoader } from 'react-spinners';
import { css } from '@emotion/core';
import moment from "moment";
import Utils from "../../common/Utils";

const override = css`
    display: block;
    margin: 2 auto;
    border-color: red;
    z-index: 1;
`;

class BusinessReportFilteredList extends Component {

	constructor(props) {
		super(props);
        this.state = {
            filter: {},
            roles: [],
            reportData: [],
            reportList: [],
            loading: false
        };

        this.onCalendarChange = this.onCalendarChange.bind(this);
        this.onGenerate = this.onGenerate.bind(this);
        this.findReports = this.findReports.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
	}

    componentDidMount() {
		BusinessReportService.getRoles()
			.then((result) => {
				this.setState({roles: result,
				filter: {
				    role: result.length > 0 ? result[0].id: "",
                    startDateLeftLimit: moment().startOf('month'), startDateRightLimit: moment().endOf('month')}
				});
			});
    }

    onCalendarChange(eve) {
        this.setState({
                filter: {
                    ...this.state.filter,
                    startDateLeftLimit: eve.startDate,
                    startDateRightLimit: eve.endDate
                }
        });
    }

    onRoleChange(event) {
        let filter = Object.assign({}, this.state.filter);
        const name = event.target.name;
        filter[name] = event.target.value;
        this.setState({filter});
    }

    onGenerate(event) {
        let filter = {...this.state.filter};
        if (this.state.filter.startDateLeftLimit !== undefined && this.state.filter.startDateRightLimit !== undefined) {
            if (this.state.filter.startDateLeftLimit !== null && this.state.filter.startDateRightLimit !== null) {
                filter.startDateLeftLimit = moment(this.state.filter.startDateLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.startDateRightLimit = moment(this.state.filter.startDateRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.startDateRightLimit == null && this.state.filter.startDateLeftLimit !== null) {
                filter.startDateLeftLimit = moment(this.state.filter.startDateLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.startDateRightLimit !== null && this.state.filter.startDateLeftLimit == null) {
                filter.startDateRightLimit = moment(this.state.filter.startDateRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
        this.findReports(filter);
    }

	findReports(filter) {
	    this.setState({loading: true});
        BusinessReportService
		    .findReports(filter)
		    .then((reportData) => {
			    this.setState({reportData: reportData, reportList: <BusinessReportList reports={reportData}/>, loading: false});
		    }).catch((error) => {
			    Utils.error(i18next.t("reports-error") + JSON.stringify(error));
			    this.setState({loading: false})
		    });
	}

	render() {
        let data;
        if (this.state.loading) {
            data =  <div class="row align-items-center justify-content-center">
                        <SyncLoader
                            css={override}
                            sizeUnit={"px"}
                            size={15}
                            color={'#d0e1e1'}
                            loading={this.state.loading}/>
                    </div>
        } else {
            data = this.state.reportList
        }
        return (
            <>
				<table className="table">
				    <thead className="thead-t-report">
                        <tr className="tr-t-report">
        		    	<th scope="col">{i18next.t("time-interval")} <DateRangePickerWrapper onChange={this.onCalendarChange}
                                                                                  dateLeftLimit={this.state.filter.startDateLeftLimit}
                                                                                  dateRightLimit={this.state.filter.startDateRightLimit}/></th>
        		    	<th scope="col">{i18next.t("report-for")} <BusinessReportRoleList onRoleChange={this.onRoleChange} name="role"
        		    	                                                          value={this.state.filter.role} roles={this.state.roles}/></th>
        		    	<th scope="col"><FormButton type="button" onClick={this.onGenerate} value={i18next.t("generate-button")}/></th>
        		    	</tr>
                    </thead>
                </table>
                {data}
        	</>
        );
    }
}

export default BusinessReportFilteredList;