import React , {Component} from 'react';
import BusinessReportFilteredList from './BusinessReportFilteredList';

class BusinessReportContainer extends Component {

    render() {
        return <BusinessReportFilteredList/>
    }
}

export default BusinessReportContainer;