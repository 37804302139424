import React, {Component} from 'react';
import TeamService from '../../services/team';
import i18next from 'i18next';

export default class TaskTeamFormControl extends Component{

    constructor(props){
        super(props);
        this.state = {
            teams: []
        };
        this.readTeams = this.readTeams.bind(this);
    }

    componentDidMount() {
        this.readTeams();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.projectId !== this.props.projectId){
            this.readTeams();
        }
    }

    readTeams(){
        if(this.props.projectId){
            TeamService.findByProjectId(this.props.projectId)
                .then((response) => {
                    this.setState({
                        teams: response
                    })
                })
        } else {
            this.setState({
                teams: []
            })
        }
    }

    render() {

        return(
            <div className="form-group row required">
                <label className="col-sm-2 control-label"
                       htmlFor={this.props.name}>
                    {this.props.label}
                </label>
                <div className="col-sm-6">
                    <select name={this.props.name}
                            className="form-control"
                            value={this.props.value}
                            onChange={this.props.onChange}>
                        <option key={null} value={null}>&lt;{i18next.t("choose-the-team")}&gt;</option>
                        {this.state.teams.map((team) =>
                            <option key={team.id} value={team.id}>{team.name}</option>)
                        }
                    </select>
                </div>
            </div>
        );
    }

}
