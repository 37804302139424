import request from '../lib/request';

function get(id) {
    return request({
        url: `/orders/${id}`,
        method: 'GET'
    });
}

function getPurchaseOrders() {
    return request({
        url: "/orders",
        method: 'GET'
    });
}

function create(order) {
    let data = Object.assign({}, order);
    data.id = null;
    return request({
        url: "/orders",
        method: 'POST',
        data: data
    });
}

function update(program) {
    return request({
        url: "/orders",
        method: 'PUT',
        data: program
    });
}

function find(searchParams) {
    return request({
        url: "/orders",
        method: 'GET',
        params: {
            customerName: searchParams.customerName,
            userFullName: searchParams.userFullName,
            startDateLeftLimit: searchParams.startDateLeftLimit,
            startDateRightLimit: searchParams.startDateRightLimit,
            endDateLeftLimit: searchParams.endDateLeftLimit,
            endDateRightLimit: searchParams.endDateRightLimit,
            customerId: searchParams.customerId,
            size: searchParams.findPageSize === undefined ? "50": searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy+","+searchParams.sortDirection
        }
    });
}

function getPurchaseAttachments(orderId) {
    return request({
        url: `/orders/${orderId}/attachments`,
        method: 'GET'
    });
}

function deletePurchaseAttachment(attachmentId, orderId) {
    return request({
        url: `/orders/${orderId}/attachments`,
        method: 'DELETE',
        params: {
            attachmentId: attachmentId
        }
    });
}

function findOrdersByCustomerManagingUser() {
    return request({
        url: "/orders/customer/manager",
        method: 'GET'
    });
}

const OrderService = {
    find,
    get,
    create,
    update,
    getPurchaseAttachments,
    deletePurchaseAttachment,
    getPurchaseOrders,
    findOrdersByCustomerManagingUser
}

export default OrderService;