import React, { Component } from 'react';
import TimesheetMonthlyTaskItem from "./TimesheetMonthlyTaskItem";
import i18next from 'i18next';
import SortableColumn from "../common/SortableColumn";

class TimesheetMonthlyTaskList extends Component {

    constructor(props) {
        super(props);

        this.onUpdate = this.onUpdate.bind(this);
    }

    onUpdate(timesheetMonthlyTask) {
        let copyOfTasks = JSON.parse(JSON.stringify(this.props.data));
        for(let i = 0; i < copyOfTasks.length; i++){
            if(copyOfTasks[i].taskId === timesheetMonthlyTask.taskId){
                copyOfTasks[i] = timesheetMonthlyTask;
                break;
            }
        }
        this.props.onUpdate(copyOfTasks);
    }

    render() {
        return (
            <table className="table table-sm">
                <thead>
                <tr>
                    <th colSpan="100%">{i18next.t("monthly-settlement-type")}</th>
                </tr>
                <tr>
                    <SortableColumn label={i18next.t("task")}
                                    name="taskNameMonthly"
                                    filter={this.props.filter}
                                    onClick={this.props.getSorting}>{i18next.t("task")}</SortableColumn>
                    <SortableColumn label={i18next.t("task-code")}
                                    name="taskCodeMonthly"
                                    filter={this.props.filter}
                                    onClick={this.props.getSorting}>{i18next.t("task-code")}</SortableColumn>
                    <th scope="col">{i18next.t("plan-h")}</th>
                    <th scope="col">{i18next.t("used-h")}</th>
                    <th scope="col">{i18next.t("in-total-h")}</th>
                    <th scope="col">{i18next.t("attachment")}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {this.props.data.map((timesheetMonthlyTask) =>
                    <TimesheetMonthlyTaskItem key={timesheetMonthlyTask.taskId} data={timesheetMonthlyTask} onUpdate={this.onUpdate}
                                              year={this.props.year} month={this.props.month}/>)}
                </tbody>
            </table>
        );
    }
}

export default TimesheetMonthlyTaskList;
