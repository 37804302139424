import React, {Component} from 'react';
import TeamService from "../../services/team";
import i18next from "i18next";
import TeamList from "./TeamList";
import Utils from "../common/Utils";

export default class TeamFilteredList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        };

        this.onFindTeams = this.onFindTeams.bind(this);
    }

    componentDidMount() {
        this.onFindTeams({})
    }

    onFindTeams(filter) {
        TeamService
            .find(filter)
            .then((data) => this.setState({ data }))
            .catch((error) => {
                Utils.error(i18next.t("team-error") + JSON.stringify(error));
                this.setState({data: []});
            });
    }

    render() {
        return <TeamList data={this.state.data} onSubmit={this.onFindTeams}/>
    }
}