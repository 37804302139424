import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from "./App";
import ResetPassword from "./components/login/ResetPassword";
import {BrowserRouter as Router, Route} from 'react-router-dom';
import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import {LANGUAGE} from "./components/common/Constants";
import detectBrowserLanguage from 'detect-browser-language';
import {ToastContainer} from "react-toastify";

function checkBrowserLanguage () {
    if (detectBrowserLanguage().includes("pl")) {
        return "pl";
    }
    else return "en";
}

i18next.use(Backend).init({
    lng: localStorage.getItem(LANGUAGE) ? localStorage.getItem(LANGUAGE) : checkBrowserLanguage(),
    debug: false,
    backend: {
        loadPath: '/locales/{{lng}}/translation.json'
    }
}, function(err, t) {
    // init set content
    updateContent()
});

i18next.on('languageChanged', () => {
    updateContent();
});

function updateContent() {
    ReactDOM.render(<Index/>, document.getElementById('root'));
}

function Index() {
    return (
        <>
        <ToastContainer position="top-center" autoClose={2500} closeButton={true} hideProgressBar={true}
                        newestOnTop={false} closeOnClick={true} rtl={false} pauseOnVisibilityChange={false} draggable={false}
                        pauseOnHover={false} theme={'colored'}/>
        <Router>
            <Route exact path="/" component={App}/>
            <Route path="/reset/:token" component={ResetPassword}/>
        </Router>
            </>
    )
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
