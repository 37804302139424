import React, {Component} from 'react';
import LoginForm from './components/login/LoginForm';
import NavBar from './NavBar.js';
import {ACCESS_TOKEN} from "./components/common/Constants";
import 'react-toastify/dist/ReactToastify.css';
import i18next from 'i18next';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #000',
        background: 'linear-gradient(rgba(29,181,254,1) 0%, rgb(2, 20, 82) 150%)'
    }
};

//npm install --save bootstrap
//npm install --save axios
//npm install --save react-router
//npm install --save prop-types
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: localStorage.getItem(ACCESS_TOKEN) !== null
        };

        this.loggedIn = this.loggedIn.bind(this);
    }

    loggedIn() {
        this.setState({
            isAuthenticated: localStorage.getItem(ACCESS_TOKEN) !== null
        });
    }

    render() {
        if (this.state.isAuthenticated) {
            return (
                <div className="p-4">
                    <NavBar/>

                </div>
            );
        } else {
            return (
                <section id="cover">

                    <div className="intro-container">
                        <div className="intro">
                            <div><img alt="logo" src='/logo-timepeeker-blue.png'/></div>
                            {/*<div><p className="intro-desc">{i18next.t("intro-sign-up")} <a className="intro-desc-signup" href="#">{i18next.t("link-sign-up")}</a></p></div>*/}
                        </div>
                    </div>
                    
                    <Modal isOpen={true} style={customStyles}>
                        
                        <LoginForm onLogin={this.loggedIn} tokenStatus={this.props.location.tokenStatus} />

                    </Modal>

                    <div className="copyrights-container">
                        <div><p className="copyrights-desc">{i18next.t("intro-copyrights")}</p></div>
                    </div>

                </section>

            );
        }
    }
}

export default App;