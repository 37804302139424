import React, {Component} from 'react';
import ProjectTeamTags from "./ProjectTeamTags";
import i18next from 'i18next';

class ProjectTeamList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teams: this.props.teams === null ? [] : this.props.teams
        };

        this.handleAdd = this.handleAdd.bind(this);
        this.handleDel = this.handleDel.bind(this);
        this.convertToTag = this.convertToTag.bind(this);
    }

    componentDidMount() {
        this.setState({
            teams: this.props.teams === null ? [] : this.props.teams
        }, () => this.convertToTag())

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.teams !== this.props.teams){
            this.setState({
                teams: this.props.teams === null ? [] : this.props.teams
            }, () => this.convertToTag()
            )
        }
    }


    handleAdd(team) {
        this.setState(state => ({ teams: [...state.teams, team] }), ()=> {this.props.onChange(this.state.teams)});
    }

    handleDel(i) {
        const {teams} = this.state;
        this.setState({
            teams: teams.filter((team, index) => index !== i),
        }, ()=> {this.props.onChange(this.state.teams)});
    }


    convertToTag() {
        const tags = this.state.teams.map((team) => {
            return {
                ...team,
                id: team.id.toString(),
                text: team.name,
                className: 'react-tagsinput--focused'
            };
        });
        this.setState({teams: tags})
    }

    render() {
        return (
            <div className="form-group row">
                <label className="col-sm-2 control-label">{i18next.t("project-teams")}</label>
                <div className="col-sm-6">
                    <ProjectTeamTags teams={this.state.teams} handleAdd={this.handleAdd} handleDel={this.handleDel} onChange={this.props.onChange}/>
                </div>
            </div>
        );
    }
}

export default ProjectTeamList;