import React, { Component } from 'react';
import FormControl from '../../common/FormControl.js';
import FormButton from '../../common/FormButton.js';
import SkillCategoryService from '../../../services/skillCategory.js';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Utils from "../../common/Utils";

class SkillCategoryForm extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			skillCategory: props.skillCategory 
		};
		
		this.onChange = this.onChange.bind(this);
		this.onReset = this.onReset.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onDeleteSkillCategory = this.onDeleteSkillCategory.bind(this);
	}
	
	componentDidMount() {
		if (this.props.skillCategory.id !== "+") {
			SkillCategoryService
			    .get(this.props.skillCategory.id)
				.then((response) => {
					this.setState({skillCategory: response});					
				})
				.catch((error) => {
					Utils.error(i18next.t(("save-error") + JSON.stringify(error)));
				});
		}
	}

	onChange(event) {
		let skillCategory = Object.assign({}, this.state.skillCategory);
		skillCategory[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		this.setState({skillCategory});
	}
	
	onReset(event) {
		this.setState({	skillCategory: this.props.skillCategory });
	}
	
    onDeleteSkillCategory() {
        if(this.state.skillCategory.id !== "+"){
                SkillCategoryService
                    .remove(this.state.skillCategory.id)
                    .then(() => {
                        this.props.onFindSkillCategories(this.props.filter);
						toast.info(i18next.t("skill-category-delete-success"), {
							icon: false
						  });
                    })
                    .catch((error) => {
						Utils.error(i18next.t("skill-category-delete-error"), {
							icon: false
						  });
                    });
        }
    }

	onSubmit(event) {
		event.preventDefault();
		if (this.state.skillCategory.id === "+") {
			SkillCategoryService
				.create(this.state.skillCategory)
				.then((response) => {
					this.props.onCreate(response);
					toast.info(i18next.t("skill-category-add-success"), {
						icon: false
					  });
					this.props.onFindSkillCategories(this.props.filter);
				})
				.catch((error) => {
					this.setState({error});
					Utils.error(error.data.message);
				});
		} else {
			SkillCategoryService
				.update(this.state.skillCategory)
				.then((response) => {				
					this.setState({skillCategory: response});	
					this.props.onUpdate(response);
					toast.info(i18next.t("skill-category-update-success"), {
						icon: false
					  });
				})
				.catch((error) => {
					this.setState({error});
					Utils.error(error.data.message);
				});
		}
	}
	
    render() {
        return (
	    	<form id="skillCategoryForm" style={this.props.theme} onReset={this.onReset} onSubmit={this.onSubmit}>
	    		<FormControl label={i18next.t("skill-category")} name="name" value={this.state.skillCategory.name} onChange={this.onChange} />
	    		<div className="row buttons-to-left btn-grey-container btn-overlap-container">
	    			<div className="col-sm-2">&nbsp;</div>
	    			<FormButton type="submit" value={i18next.t("save-button")}/>
	    			<FormButton type="reset" value={i18next.t("cancel-button")}/>
                    <FormButton type="button" value={i18next.t("delete-button")} onClick={this.onDeleteSkillCategory}/>
	    		</div>
	    	</form>
        );
    }
}

export default SkillCategoryForm;