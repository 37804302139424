import React, { Component } from 'react';
import HolidayForm from './HolidayForm';
import Label from "../../common/Label";

class HolidayListItem extends Component {

    constructor(props) {
        super(props);

        this.onCreateHoliday = this.onCreateHoliday.bind(this);
        this.onUpdateHoliday = this.onUpdateHoliday.bind(this);
    }

    onCreateHoliday(holiday) {
        this.props.onCreate(holiday);
    }

    onUpdateHoliday(holiday) {
        this.props.onUpdate(holiday);
    }

    getYear() {
        if (this.props.holiday.id !== "+") {
            return <td>{this.props.holiday.year ? this.props.holiday.year : "-"}</td>
        } else {
        return <td></td>
        }
    }

    getRepeatable() {
        if (this.props.holiday.id === "+") {
            return <td>+</td>
        } else if (this.props.holiday.year === null) {
            return <td><Label messageKey="holiday-repeatable-yes"/></td>
        } else {
        return <td><Label messageKey="holiday-repeatable-no"/></td>
        }
    }

    getHolidayForm() {
        if (this.props.selected === this.props.holiday.id) {
            return (
                <tr>
                    <td colSpan="5">
                        <HolidayForm
                            key={this.props.holiday.id}
                            holiday={this.props.holiday}
                            style={this.props.selected === this.props.holiday.id ? {display: 'block'} : {display: 'none'}}
                            onCreate={this.onCreateHoliday}
                            onUpdate={this.onUpdateHoliday}
                            onFindHolidays={this.props.onFindHolidays}/>
                    </td>
                </tr>
            )
        }
    }


    render() {
        if(this.props.holiday.id === '+' && this.props.selected !== this.props.holiday.id){
            return null;
        }
        return (
            <>
                <tr className={this.props.holiday.plus} onClick={() => this.props.onSelect(this.props.holiday.id)}>
                    {this.getRepeatable()}
                    {this.getYear()}
                    <td>{this.props.holiday.month}</td>
                    <td>{this.props.holiday.day}</td>
                    <td>{this.props.holiday.description}</td>
                </tr>
                {this.getHolidayForm()}
            </>
        );
    }
}

export default HolidayListItem;