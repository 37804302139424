import React, { Component } from 'react';
import 'react-dates/initialize';
import DateRangePickerWrapper from '../common/DateRangePickerWrapper';
import i18next from 'i18next';
import PageableFilter from "../common/PageableFilter";

class ProjectFilter extends Component {

	constructor(props){
		super(props);

		this.onKeyDown = this.onKeyDown.bind(this);
	}

	onKeyDown(event) {
		if (event.keyCode === 13) {
			this.props.onSubmit();
		}
	}

  render() {

    return (
		<>
                <PageableFilter colSpan="11" onFilterChange={this.props.onFilterChange}
                                            onSubmit={this.props.onSubmit}
                                            findPageSize={this.props.filter.findPageSize}
                                            findPageNumber={this.props.filter.findPageNumber}
                                            resultSize={this.props.resultSize} />
			<tr>
				<th/>
				<th scope="col"><input className="form-control" type="text" name="findName" value={this.props.filter.findName} onChange={this.props.onFilterChange}
									   onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
                <th scope="col"><input className="form-control" type="text" name="findCode" value={this.props.filter.findCode} onChange={this.props.onFilterChange}
                                       onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
                <th scope="col"><input className="form-control" type="text" name="findManager" value={this.props.filter.findManager} onChange={this.props.onFilterChange}
                                       onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
				<th scope="col"><select className="custom-select"  name="findActive" value={this.props.filter.findActive} onChange={this.props.onFilterChange}>
                                			<option value="">{i18next.t('all')}</option>
                                			<option value="true">{i18next.t('active-female-plural')}</option>
                                			<option value="false">{i18next.t('inactive')}</option>
                                			</select></th>
				<th scope="col"><input className="form-control" type="text" name="findCreatedBy" value={this.props.filter.findCreatedBy} onChange={this.props.onFilterChange}
									   onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
				<th scope="col"><input className="form-control" type="text" name="findProgram" value={this.props.filter.findProgram} onChange={this.props.onFilterChange}
                                       onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
				<th scope="col " className="data-picker-container"><DateRangePickerWrapper onChange={this.props.onCalendarChangeStartDate}
                                                          dateLeftLimit={this.props.filter.startDateLeftLimit}
                                                          dateRightLimit={this.props.filter.startDateRightLimit} /></th>
                <th scope="col" className="data-picker-container" ><DateRangePickerWrapper onChange={this.props.onCalendarChangeEndDate}
                                                          dateLeftLimit={this.props.filter.endDateLeftLimit}
                                                          dateRightLimit={this.props.filter.endDateRightLimit} /></th>
				<th/>
				<th/>
			</tr>
		</>
    );
  }
}

export default ProjectFilter;