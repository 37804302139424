import React, { Component } from 'react';
import CustomerTab from "./CustomerTab";

class CustomerListItem extends Component {

    constructor(props) {
        super(props);

        this.onCreateCustomer = this.onCreateCustomer.bind(this);
        this.onUpdateCustomer = this.onUpdateCustomer.bind(this);
    }

    onCreateCustomer(customer) {
        this.props.onCreate(customer);
    }

    onUpdateCustomer(customer) {
        this.props.onUpdate(customer);
    }

    render() {
        if(this.props.customer.id === '+' && this.props.selected !== this.props.customer.id){
            return null;
        }
        let customerTab;
        if (this.props.selected === this.props.customer.id) {
            customerTab =
                <tr>
                    <td colSpan="5">
                    <CustomerTab customer={this.props.customer} onCreateCustomer={this.onCreateCustomer} onUpdateCustomer={this.onUpdateCustomer}
                                 onFindCustomers={this.props.onFindCustomers} filter={this.props.filter}/>
                    </td>
                </tr>
        }
        return <>
            <tr className={this.props.customer.plus} onClick={() => this.props.onSelect(this.props.customer.id)}>
                <td>{this.props.customer.id}</td>
                <td>{this.props.customer.name}</td>
                <td>{this.props.customer.externalId}</td>
                <td>{this.props.customer.nip}</td>
                <td>{this.props.customer.managingUserFullName}</td>
            </tr>
            {customerTab}
        </>
    }
}

export default CustomerListItem;