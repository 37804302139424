import { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class Label extends Component {

    render() {
        return (i18next.t(this.props.messageKey));
    }
}

Label.propTypes = {
    messageKey: PropTypes.string
};

export default Label;