import React, {Component} from 'react';

class InactiveRoleList extends Component {

    render() {
        return (
				<select name={this.props.name} className="form-control" value={this.props.value} onChange={this.props.onRoleChange}>
					{this.props.roles.map((role) =>
						<option key={role.id} value={role.id}>{role.name}</option>)}
				</select>
        );
    }
}

export default InactiveRoleList;
