import React, {Component} from 'react';
import FormControl from '../../common/FormControl.js';
import FormButton from '../../common/FormButton.js';
import TaskStatusService from '../../../services/taskstatus.js';
import {toast} from 'react-toastify';
import i18next from 'i18next';
import Utils from "../../common/Utils";

class TaskStatusForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            taskStatus: props.taskStatus
        }

        this.onChange = this.onChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.removeTaskStatus = this.removeTaskStatus.bind(this);
    }

    componentDidMount() {
        if (this.props.taskStatus.id !== "+") {
            TaskStatusService.get(this.props.taskStatus.id)
                .then((response) => {
                    this.setState({taskStatus: response});
                })
                .catch((error) => {
                    Utils.error(i18next.t("data-read-error") + JSON.stringify(error));
                });
        }
    }

    onChange(event) {
        let taskStatus = {...this.state.taskStatus};
        taskStatus[event.target.name] = event.target.value;
        this.setState({taskStatus});
    }

    resetForm() {
        this.setState({taskStatus: this.props.taskStatus});
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.taskStatus.id === "+") {
            TaskStatusService
                .create(this.state.taskStatus)
                .then((response) => {
                    this.props.onCreate(response);
                    toast.info(i18next.t("add-status"), {
                        icon: false
                      });
                    this.props.onFindTaskStatuses(this.props.filter);
                }).catch((error) => {
                    Utils.error(error.data.message);
                });
        } else {
            TaskStatusService
                .update(this.state.taskStatus)
                .then((response) => {
                    this.setState({taskStatus: response});
                    toast.info(i18next.t("update-status"), {
                        icon: false
                      });
                    this.props.onUpdate(response);
                }).catch((error) => {
                    Utils.error(error.data.message);
                });
        }
    }

    removeTaskStatus() {
        if (this.state.taskStatus.id !== "+") {
            TaskStatusService
                .remove(this.state.taskStatus.id)
                .then(() => {
                    this.props.onFindTaskStatuses(this.props.filter);
                    toast.info(i18next.t("delete-status"), {
                        icon: false
                      });
                })
                .catch((error) => {
                    Utils.error(i18next.t("taskstatus-delete-error"))
                });
        }
    }

    removeButton() {
        if (this.state.taskStatus.id !== "+") {
            return <FormButton type="button" value={i18next.t("delete-button")} onClick={this.removeTaskStatus} />
        }
    }

    render() {
        return (
            <form className="form-horizontal" id="taskStatusForm" style={this.props.style} onSubmit={this.onSubmit} onReset={this.resetForm}>
                <FormControl label="Nazwa" name="name" value={this.state.taskStatus.name} onChange={this.onChange} />
                <div className="row buttons-to-left btn-grey-container btn-overlap-container">
                    <div className="col-sm-2">&nbsp;</div>
                    <FormButton type="submit" value={i18next.t("save-button")} />
                    <FormButton type="reset" value={i18next.t("cancel-button")} onClick={this.resetForm} />
                    {this.removeButton()}
                </div>
            </form>
        );
    }
}

export default TaskStatusForm;