import React, {Component} from 'react';
import Autosuggest from 'react-autosuggest';
import Theme from "../customer/autosuggestStyle";

export default class Suggestion extends Component{

    constructor(props){
        super(props);
        this.state = {
            items: [],
            suggestions: [],
            value: ''
        };
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSuggestionSelected =this.onSuggestionSelected.bind(this);
    }


    classNameRequired() {
        if (this.props.required === true) {
            return "form-group row required"
        }
        return "form-group row"
    }

    //do zmiany w niektórych komponentach
    getSuggestionValue (suggestion)  {
        return suggestion.name
    }


    //do zmiany w niektórych komponentach
    renderSuggestion (suggestion){
        return (
            <div>
                {suggestion.name}
            </div>
        );
    }
    // do zmiany wnk
    onSuggestionsFetchRequested ({value}){
        const inputValue = value.trim().toLowerCase();
        this.setState({
            suggestions: this.state.items.filter((item) =>
                item.name.toLowerCase().includes(inputValue))
        })
    }

    onSuggestionsClearRequested (){
        this.setState({
            suggestions: []
        });
    }

    onSuggestionSelected (event, {suggestion}){
        this.props.onChangeSuggestionId(suggestion.id);
    }

    onChange(event, {newValue}){
        this.setState({
            value: newValue
        });
        if(newValue.length === 0){
            this.setState({
                suggestions: this.state.items
            })
        }
    }

    render() {
        const inputProps = {
            value: this.state.value,
            onChange: this.onChange,
            placeholder: this.props.inputPlaceholder
        };

        return(
            <div className={this.classNameRequired()}>
                <label className="col-sm-2 control-label" htmlFor={this.props.name}>{this.props.label}</label>
                <div className="col-sm-6">
                    <Autosuggest inputProps={inputProps}
                                 suggestions={this.state.suggestions}
                                 onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                 onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                 getSuggestionValue={this.getSuggestionValue}
                                 renderSuggestion={this.renderSuggestion}
                                 onSuggestionSelected={this.onSuggestionSelected}
                                 theme={Theme}
                                 shouldRenderSuggestions={() => true}
                    />
                </div>
            </div>
        );
    }


}
