import React, {Component} from 'react';
import TaskStatusForm from './TaskStatusForm.js';

class TaskStatusListItem extends Component {

	constructor(props) {
		super(props);

		this.onCreateTaskStatus = this.onCreateTaskStatus.bind(this);
		this.onUpdateTaskStatus = this.onUpdateTaskStatus.bind(this);
	}

	onCreateTaskStatus(taskStatus) {
        this.props.onCreate(taskStatus);
    }

    onUpdateTaskStatus(taskStatus) {
        this.props.onUpdate(taskStatus);
    }

    renderTaskStatusForm() {
        if (this.props.selected === this.props.taskStatus.id) {
            return (
        	    <tr>
        	        <td colSpan="6">
        	    		<TaskStatusForm
        	    		    key={this.props.taskStatus.id}
        	    		    taskStatus={this.props.taskStatus}
        	    		    onCreate={this.onCreateTaskStatus}
        	    		    onUpdate={this.onUpdateTaskStatus}
        	    		    onRemove={this.onRemoveTaskStatus}
        	    		    onFindTaskStatuses={this.props.onFindTaskStatuses}
        	    		    filter={this.props.filter} />
        	    	</td>
        	    </tr>
        	)
        }
    }

	render() {
		if(this.props.taskStatus.id === '+' && this.props.selected !== this.props.taskStatus.id){
			return null;
		}
	    return (
	        <>
	  	        <tr className={this.props.taskStatus.plus} onClick={() => this.props.onSelect(this.props.taskStatus.id)}>
	  	        	<td>{this.props.taskStatus.id}</td>
	  	        	<td>{this.props.taskStatus.name}</td>
	  	        </tr>
	  	        {this.renderTaskStatusForm()}
	        </>
	    );
	}
}

export default TaskStatusListItem;