import React, {Component} from 'react';
import FormControl from "../common/FormControl";
import FormButton from "../common/FormButton";
import TeamService from "../../services/team";
import TeamLeaderControl from "./TeamLeaderControl";
import i18next from 'i18next';
import { toast } from 'react-toastify';
import Utils from "../common/Utils";
import TeamCopyMembersFromAnotherTeamList from './TeamCopyMembersFromAnotherTeamList'
import UserService from '../../services/user';

class TeamForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            team: this.props.team
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeTeam = this.onChangeTeam.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeAutosuggest = this.onChangeAutosuggest.bind(this);
        this.onClearLeader = this.onClearLeader.bind(this);
        this.onDeleteTeam = this.onDeleteTeam.bind(this);
    }

    onChange(event) {
        let team = Object.assign({}, this.state.team);
        team[event.target.name] = event.target.value;
        this.setState({team});
    }

    onChangeTeam (event){
        let copyTeam = Object.assign({}, this.state.team);
        UserService.findByTeamId(event.target.value)
            .then((response) => {
                copyTeam.members = response;
                this.setState({
                    team: copyTeam
                });
            });
    }

    onClearLeader() {
        this.setState({
            team: {...this.state.team, leaderId: null}
        });
    }

    onChangeAutosuggest(event, {suggestion}) {
        this.setState({
            team: {...this.state.team, leaderId: suggestion.id}
        });
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.team.id !== '+') {
            TeamService
                .update(this.state.team)
                .then((result) => {
                    this.setState({ team: result }, () => {
                        toast.info(i18next.t("team-update-success") + this.state.team.name, {
                            icon: false
                          });
                        this.props.afterChange();
                    })
                }).catch(error => {
                    Utils.error(i18next.t("team-update-error" + JSON.stringify(error)))
                });
        } else {
            TeamService
                .create(this.state.team)
                .then(() => {
                    toast.info(i18next.t("team-create-success") + this.state.team.name, {
                        icon: false
                      })
                    this.props.afterChange();
                })
                .catch(error => {
                    Utils.error(i18next.t("team-create-error" + JSON.stringify(error)))
                });
        }
    }

    onDeleteTeam() {
        TeamService
            .del(this.state.team.id)
            .then(() => {
                toast.info(i18next.t("team-delete-success"), {
                    icon: false
                  })
                this.props.afterChange()
            }).catch((error) => {
                Utils.error(error.data.message);
            });
    }

    renderDeleteButton() {
        if (this.props.team.id !== '+' && this.props.loggedInUser.roles.some(e => e === "ROLE_SUPERUSER")) {
            return (<FormButton onClick={this.onDeleteTeam} type="button" value={i18next.t("delete-button")}/>);
        }
    }

    renderTeamLeader() {
        if (this.props.loggedInUser.roles.some(e => e === "ROLE_SUPERUSER" || e === "ROLE_DELIVERY_MANAGER")) {
            return <TeamLeaderControl team={this.props.team} changeState={this.onChangeAutosuggest} leaderClear={this.onClearLeader}/>
        }
        else {
            return <FormControl label={i18next.t("leader")} disabled={true} value={this.props.team.leaderName}/>
        }
    }

    renderListOfTeamsToAssignTheUsers (){
        if(this.props.team.id === "+"){
            return <TeamCopyMembersFromAnotherTeamList onChange={this.onChangeTeam}
            name="members" label={i18next.t("copy-members-from-another-team")}/>;
        }
    }

    render() {
        let value = i18next.t("save-button");
        if (this.props.team.id === "+") {
            value = i18next.t("create-a-team");
        }
        return (
            <form className="form-horizontal" id="teamForm" onSubmit={this.onSubmit}>
                <FormControl name="name" label={i18next.t("team-name")} value={this.state.team.name} onChange={this.onChange}/>
                {this.renderTeamLeader()}
                {this.renderListOfTeamsToAssignTheUsers()}
                <br/>
                <div className="row buttons-to-left btn-grey-container btn-overlap-team-container">
                    <div className="col-sm-2 "></div>
                    <FormButton type="submit" value={value}/>
                    {this.renderDeleteButton()}
                </div>
            </form>
        );
    }
}

export default TeamForm;