import React, {Component} from 'react';
import TaskStatusFormControl from './TaskStatusFormControl.js';
import PageableFilter from "../common/PageableFilter";
import i18next from "i18next";

class TaskFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeProjectFilter: this.props.activeProjectFilter
        };

		this.onTaskStatusChange = this.onTaskStatusChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.props.onSubmit();
        }
    }

    onTaskStatusChange(statuses) {
        let filter = Object.assign({}, this.state.filter);
        filter["findTaskStatusId"] = statuses;
        this.setState({filter});
        this.props.onFilterTaskStatusChange(statuses);
    }

    render() {
        return (
	    	<>
                <PageableFilter colSpan="10" onFilterChange={this.props.onFilterChange}
                                            findPageSize={this.props.filter.findPageSize}
                                            findPageNumber={this.props.filter.findPageNumber}
                                            resultSize={this.props.resultSize} />
	    		<tr>
	    			<th scope="col"/>
	    			<th scope="col"><input className="form-control" type="text" name="findExternalCode" value={this.props.filter.findExternalCode}
	    			                       onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
	    			<th scope="col"><input className="form-control" type="text" name="findName" value={this.props.filter.findName}
	    			                       onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
	    			<th scope="col"><input className="form-control" type="text" name="findAssignedUser" value={this.props.filter.findAssignedUser}
	    			                       onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
	    			<th scope="col"><TaskStatusFormControl className="form-control" name="findTaskStatusId" value={this.props.filter.findTaskStatusId}
	    			                       onChange={this.onTaskStatusChange}/></th>
                    <th scope="col">
                        <select className="custom-select"  name="findActive" value={this.props.filter.findActive} onChange={this.props.onFilterChange}>
                            <option value="">{i18next.t('all')}</option>
                            <option value="true">{i18next.t('active-female-plural')}</option>
                            <option value="false">{i18next.t('inactive')}</option>
                        </select>
                    </th>
                    <th scope="col"><input className="form-control" type="text" name="findProjectCode" value={this.props.filter.findProjectCode}
                                                          onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
	    			<th scope="col"><input className="form-control" type="text" name="findProjectName" value={this.props.filter.findProjectName}
                                                          onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
                    <th scope="col"><input className="form-control" type="text" name="findProgramCode" value={this.props.filter.findProgramCode}
                                                          onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
	    		    <th scope="col"><input className="form-control" type="text" name="findProgramName" value={this.props.filter.findProgramName}
                                                          onChange={this.props.onFilterChange} onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
                </tr>
	    	</>
        );
    }

}

export default TaskFilter;