import React, { Component } from 'react';
import SkillCategoryFilter from './SkillCategoryFilter.js';
import SkillCategoryListItem from './SkillCategoryListItem.js';
import i18next from 'i18next';
import SortableColumn from "../../common/SortableColumn";
import PageableFilter from "../../common/PageableFilter";
import ListTitle from "../../common/ListTitle";

class SkillCategoryList extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
				selectedRowId: null,
				filter: {},
				addButtonLabel: i18next.t("add-new")
		};

		this.selectRow = this.selectRow.bind(this);
		this.findSkillCategories = this.findSkillCategories.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onCreateSkillCategory = this.onCreateSkillCategory.bind(this);
		this.onUpdateSkillCategory = this.onUpdateSkillCategory.bind(this);
		this.onSortOrder = this.onSortOrder.bind(this);
		this.addNewItem = this.addNewItem.bind(this);
	}

	selectRow(key) {
		this.setState((prevState) => {
		    return {selectedRowId: key === prevState.selectedRowId ? null: key,
				    addButtonLabel: i18next.t("add-new")}
		});
	}
	
	findSkillCategories(filter) {
		this.props.onFindSkillCategories(this.state.filter);
	}

	onFilterChange(event) {
		let filter = {...this.state.filter};
		let targetName = event.target.name;
		filter[targetName] = event.target.value;
		if(!["findPageNumber", "sortBy", "sortDirection"].includes(event.target.name)){
			filter["findPageNumber"] = "0";
		}
		this.setState({filter}, () => {
				this.props.onFindSkillCategories(this.state.filter)
		});
	}
	
	onCreateSkillCategory(skillCategory) {
		this.setState({selectedRowId: null,
			addButtonLabel: i18next.t("add-new")});
	}
	
	onUpdateSkillCategory(skillCategory) {
		this.props.onFindSkillCategories(this.state.filter);
	}
	
	onSortOrder(filter) {
		this.setState({filter: filter, selectedRowId: null}, () => this.props.onFindSkillCategories(this.state.filter));
	}

	addNewItem(){
		this.setState(prevState => ({
			selectedRowId: prevState.selectedRowId === '+' ? null : '+',
			addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
		}))
	}

    render() {
        return (
	    	<table className="table table-sm">
	    		<thead>
				<ListTitle sectionLabel={i18next.t("configurations-tab-skill")} span={0}
						   onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
	    		    <SkillCategoryFilter resultSize={this.props.skillCategories.length} filter={this.state.filter} onFilterChange={this.onFilterChange} onSubmit={this.findSkillCategories} />
					<tr>
						<SortableColumn name="s.id" label="Id" filter={this.state.filter} onClick={this.onSortOrder}/>
						<SortableColumn name="s.name" label={i18next.t("skill-category")} filter={this.state.filter} onClick={this.onSortOrder}/>
					</tr>
	    		</thead>
	    		<tbody>
				<SkillCategoryListItem key={'+'} selected={this.state.selectedRowId} onSelect={this.selectRow} onCreate={this.onCreateSkillCategory}
									   skillCategory={{id: "+", name: "", plus: "plus"}} onFindSkillCategories={this.props.onFindSkillCategories} filter={this.state.filter}/>
	    			{this.props.skillCategories.map((skillCategory) =>
	    				<SkillCategoryListItem key={skillCategory.id} selected={this.state.selectedRowId} skillCategory={skillCategory} onSelect={this.selectRow} onUpdate={this.onUpdateSkillCategory}/>)
	    			}
				<PageableFilter colSpan="2" onFilterChange={this.onFilterChange}
								findPageSize={this.state.filter.findPageSize}
								findPageNumber={this.state.filter.findPageNumber}
								resultSize={this.props.skillCategories.length}
								hidePageSize={true}/>
	    		</tbody>
	    	</table>
        );
    }
}

export default SkillCategoryList;