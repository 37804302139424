import React, {Component} from "react";
import {Table} from "reactstrap";
import TeamFilter from "./TeamFilter";
import TeamListItem from "./TeamListItem";
import UserService from "../../services/user";
import i18next from 'i18next';
import Utils from "../common/Utils";
import PageableFilter from "../common/PageableFilter";

const emptyTeamData = {id: "+", name: "", leader: {firstName: "", lastName: ""}, plus: "plus"};

export default class TeamList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowId: null,
            filter: {},
            loggedInUser: {roles: []},
            listItemClickChange: true
        };

        this.onSelectRow = this.onSelectRow.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.getTeamListItem = this.getTeamListItem.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.onBottomPageableFilterChange = this.onBottomPageableFilterChange.bind(this);
    }

    onSelectRow(key) {
        this.setState((prevState) => {
            return {selectedRowId: key === prevState.selectedRowId ? null : key,
                    listItemClickChange: !prevState.listItemClickChange};
        });
    }

    onCreate(){
        this.setState((prevState) => {
            return {listItemClickChange: !prevState.listItemClickChange}
        })
    }

    componentDidMount() {
        UserService
            .getLoggedInUser()
            .then((response) => {
                this.setState({
                    loggedInUser: response
                });
            }).catch((error) => {
                Utils.error(i18next.t("user-read-error") + JSON.stringify(error));
        });
    }

    onFilterChanged(filter) {
        this.setState({selectedRowId: null, filter: filter});
        this.props.onSubmit(filter);
    }

    onBottomPageableFilterChange(event){
        let copyFilter = Object.assign({}, this.state.filter);
        copyFilter[event.target.name] = event.target.value;
        if(event.target.name === "findPageSize"){
            copyFilter["findPageNumber"] = 0
        }
        this.setState({
            filter: copyFilter
        }, () => this.props.onSubmit(this.state.filter))
    }

    getTeamListItem() {
        if (this.state.loggedInUser.roles.some(e => e === "ROLE_SUPERUSER" || e === "ROLE_DELIVERY_MANAGER")) {
            return <TeamListItem key={'+'}
                                 selected={this.state.selectedRowId}
                                 data={emptyTeamData}
                                 onSelect={this.onSelectRow}
                                 afterChange={() => {this.onFilterChanged(this.state.filter);
                                 this.onCreate()}}
                                 loggedInUser={this.state.loggedInUser}/>
        }
    }

    addNewItem(){
        this.setState(prevState => ({
            selectedRowId: prevState.selectedRowId === '+' ? null : '+'
        }))
    }

    render() {
        return (
            <Table className="table table-sm">
                <TeamFilter onSubmit={this.onFilterChanged} resultSize={this.props.data.length}
                addNewItem={this.addNewItem} listItemClickChange={this.state.listItemClickChange}/>
                <tbody>
                    {this.getTeamListItem()}
                    {this.props.data.map((team) =>
                        <TeamListItem key={team.id}
                                      selected={this.state.selectedRowId}
                                      data={team}
                                      onSelect={this.onSelectRow}
                                      afterChange={() => this.props.onSubmit(this.state.filter)}
                                      loggedInUser={this.state.loggedInUser}/>)}
                    <PageableFilter colSpan="3" onFilterChange={this.onBottomPageableFilterChange}
                                    findPageSize={this.state.filter.findPageSize}
                                    findPageNumber={this.state.filter.findPageNumber}
                                    resultSize={this.props.data.length}
                                    hidePageSize={true}/>
                </tbody>
            </Table>
        );
    }
}