import {ACCESS_TOKEN} from "../components/common/Constants";
import qs from "qs";
import axios from "axios";

const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_SERVER
});

const requestData = function(options) {
    const onSuccess = function(response) {
        console.debug('Request Successful!', response);
        return response;
    }

    const onError = function(error) {
        console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }
        return Promise.reject(error.response || error.message);
    };

    let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        "Access-Control-Allow-Headers": "X-Requested-With,content-type",
        "Access-Control-Allow-Credentials": true
    };

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers["Authorization"] = "Bearer " + localStorage.getItem(ACCESS_TOKEN);
    }

    //const defaults = {headers: headers};
    options = Object.assign({}, {headers: headers}, {
        paramsSerializer: params => {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }, options);

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default requestData;