import React, { Component } from 'react';

export default class SortableColumn extends Component {

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.getSortingChar = this.getSortingChar.bind(this);
    }

    componentDidMount() {
        if(this.props.getRefWidth){
            this.props.getWidth(this.column.offsetWidth - 1);
        }
    }

    onClick() {
        let actualDirection = (this.props.filter.sortBy === this.props.name ? this.props.filter.sortDirection : "DESC");
        let filter = {
            ...this.props.filter,
            sortBy: this.props.name,
            sortDirection: actualDirection === "ASC" ? "DESC" : "ASC"
        }
        this.props.onClick(filter);
    }

    getSortingChar() {
        if (this.props.filter.sortBy === this.props.name) {
            if (this.props.filter.sortDirection === "ASC") {
                // return "⬆";
                return <span className="arrow-sort-filter"> ▲</span>;
                
            } else {
                // return "⬇";
                return <span className="arrow-sort-filter"> ▼</span>;
            }
        }
    }

    render() {
        return <th scope="col" onClick={this.onClick} className={this.props.className}
        style={this.props.style ? this.props.style: {}}
        ref={(column) => this.column = column}>{this.props.label}&nbsp;{this.getSortingChar()}</th>
    }
}