import React, { Component } from 'react';
import 'react-dates/initialize';
import i18next from 'i18next';
import { CSVLink } from "react-csv";

class BusinessReportAdditionalFilter extends Component {

    render() {
        return (
	    	<>
	    		<th scope="col"><input className="form-control" type="text" name="customerName" value={this.props.filter.customerName} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
	    		<th scope="col"><input className="form-control" type="text" name="orderId" value={this.props.filter.orderId} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
	    		<th scope="col"><input className="form-control" type="text" name="portfolioName" value={this.props.filter.portfolioName} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
	    		<th scope="col"><input className="form-control" type="text" name="projectName" value={this.props.filter.projectName} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
	    		<th scope="col"><input className="form-control" type="text" name="projectId" value={this.props.filter.projectId} onChange={this.props.onFilterChange}
									   placeholder={i18next.t("all")}/></th>
	    		<th scope="col"></th>
	    		<th scope="col"><CSVLink data={this.props.reports}>{i18next.t("download-csv")}</CSVLink></th>
	    	</>
        );
    }
}

export default BusinessReportAdditionalFilter;