import React, {Component} from 'react';
import OrderAttachment from "./OrderAttachment";
import OrderService from "../../services/order";
import uploadService from "../../services/upload";
import OrderAttachmentsDropzoneForm from "./OrderAttachmentsDropzoneForm";
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Utils from "../common/Utils";

class OrderAttachmentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attachments: []
        };

        this.onUpdate = this.onUpdate.bind(this);
    }

    componentDidMount() {
        this.onUpdate();
    }

    onUpdate() {
        OrderService
            .getPurchaseAttachments(this.props.order.id)
            .then((response) => {
                this.setState({attachments: response});
            })
            .catch((error) => {
				Utils.error(i18next.t("order-attachment-load-error"));
            });
    }

    handleUploadFile = (files) => {
        files.forEach(file => {
            let formData = new FormData();
            formData.append('file', file);
            uploadService
                .uploadOrderAttachment(formData, this.props.order.id).then(() => {
                    this.onUpdate();
                    toast.info(i18next.t("order-attachment-update-success"), {
                        icon: false
                      });
                }).catch((error) => {
                    Utils.error(i18next.t("upload-error"));
                });
        })
    };

    render() {
        return (
            <div>
                <table className="table table-sm">
                    <tbody>
                    <tr>
                        <th>{i18next.t("file-name")}</th>
                        <th scope="col">{i18next.t("delete-file")}</th>
                        <th scope="col">
                            <OrderAttachmentsDropzoneForm handleUploadFile={this.handleUploadFile}/>
                        </th>
                    </tr>
                        {this.state.attachments.map((attachment) =>
                            <OrderAttachment key={attachment.id} attachment={attachment} order={this.props.order}
                                             onUpdate={this.onUpdate}/>)}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default OrderAttachmentList;