import React, {Component} from 'react';
import i18next from 'i18next';
import {Nav} from 'react-bootstrap';
import {LANGUAGE} from "../common/Constants";

class LanguageButton extends Component {

    constructor(props) {
        super(props);
        if (localStorage.getItem(LANGUAGE)) {
            this.state = {lang: localStorage.getItem(LANGUAGE)}
        } else {
            this.state = {lang: "en"};
            i18next.changeLanguage(this.state.lang);
            localStorage.setItem(LANGUAGE, this.state.lang);
        }
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    changeLanguage() {
        this.setState((prevState) => {
            return {lang: prevState.lang === "pl" ? "en" : "pl"}
        }, () => {
            i18next.changeLanguage(this.state.lang);
            window.location.reload();
            localStorage.setItem(LANGUAGE, this.state.lang);
        });
    }

    languageIconPolish(isDisabled) {
        let icon = null;

        icon = <img
            src="/Poland-Flag-icon.png"
            width="30"
            height="25"
            alt="Polish flag"
            style={{opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? 'pointer' : 'not-allowed'}}
            onClick={!isDisabled ? this.changeLanguage : null}

        />

        return icon;
    }
    languageIconEnglish(isDisabled) {
        let icon = null;

        icon = <img
            src="/United-Kingdom-flag-icon.png"
            width="30"
            height="25"
            alt="English flag"
            style={{opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? 'pointer' : 'not-allowed'}}
            onClick={!isDisabled ? this.changeLanguage : null}
        />
        return icon;
    }

    render() {
        return (
            <Nav.Link onClick={this.changeLanguage}>
                {this.languageIconPolish(this.state.lang  === "en")}
                {this.languageIconEnglish(this.state.lang  === "pl")}
            </Nav.Link>
        )
    }
}

export default LanguageButton;