import React, {Component} from 'react';
import FormControl from '../common/FormControl';
import FormButton from '../common/FormButton.js';
import ProgramService from '../../services/program';
import RoleUSERUserFormControl from "../common/RoleUSERUserFormControl";
import ProgramPurchaseOrderFormControl from "./ProgramPurchaseOrderFormControl";
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import PurchaseOrderFormControl from "../common/PurchaseOrderFormControl.js";
import i18next from 'i18next';
import UserService from "../../services/user";
import Utils from "../common/Utils";
import {DATE_MAX, DATE_MIN, STRING_LENGTH} from "../common/Constants";
import UserSuggestion from '../common/UserSuggestion';

class ProgramForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedInUser: {roles: []},
            program: this.props.program
        };

        this.onChange = this.onChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.copying = this.copying.bind(this);
        this.onManagingUserIdChange = this.onManagingUserIdChange.bind(this);
        this.onPurchaseOrderCodeChange = this.onPurchaseOrderCodeChange.bind(this);
    }

    componentDidMount() {
        UserService
            .getLoggedInUser()
            .then((response) => {
                this.setState({
                    loggedInUser: response,
                }, ()=> this.loadProgram())
            }).catch((error) => {
                Utils.error(i18next.t("user-read-error") + error.data.message);
        });
    }

    loadProgram() {
        if (this.state.program.id !== "+") {
            ProgramService
                .get(this.state.program.id)
                .then((response) => {
                    let program = Object.assign({}, this.state.program);
                    program.id = response.id;
                    this.setState({program: response});
                }).catch((error) => {
                    Utils.error(i18next.t("single-portfolio-error") + error.data.message);
            });
        } else {
        let data = {...this.state.program, managingUserId: this.state.loggedInUser.id};
            this.setState({program: data})
        }
    }

    onChange(event) {
        let program = Object.assign({}, this.state.program);
        program[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({program});
    }

    onReset(event) {
        this.loadProgram();
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.program.id === "+") {
            ProgramService
                .create(this.state.program)
                .then((response) => {
                    this.props.onCreate(response);
                    this.props.onFindPrograms(this.props.filter);
                    toast.info(i18next.t("portfolio-save-success"), {
                        icon: false
                      });
                }).catch((error) => {
                    Utils.error(i18next.t("portfolio-save-error") + error.data.message);
                });
        } else {
            ProgramService
                .update(this.state.program)
                .then((response) => {
                    this.setState({program: response});
                    this.props.onUpdate(response);
                    toast.info(i18next.t("portfolio-update-success"), {
                        icon: false
                      });
                }).catch((error) => {
                    Utils.error(i18next.t("portfolio-save-error") + error.data.message);
                });
        }
    }

    onManagingUserIdChange(managingUserId){
        this.setState(prevState => ({
            program: {
                ...prevState.program,
                managingUserId: managingUserId
            }
        }))
    }

    onPurchaseOrderCodeChange(purchaseOrderCode){
        this.setState(prevState => ({
            program: {
                ...prevState.program,
                purchaseOrderCode: purchaseOrderCode
            }
        }))
    }

    copying() {
        this.props.copyAnItem(this.state.program);
    }

    getCopyButton() {
        if (this.state.program.id !== "+") {
            return <FormButton type="button" onClick={this.copying} value={i18next.t("clone")}/>
        }
    }

    getPurchaseOrderFormControl() {
        if (this.state.loggedInUser.roles.some(e => e === "ROLE_SUPERUSER")) {
            return <PurchaseOrderFormControl label={i18next.t("order")}
                                             onChangeSuggestionId={this.onPurchaseOrderCodeChange}
                                             inputPlaceholder={i18next.t("choose-the-order")}
                                             chosenExternalId={this.state.program.purchaseOrderCode}
                                             removePossible={true}/>
        } else {
            return <ProgramPurchaseOrderFormControl label={i18next.t("order")}
                                                    onChangeSuggestionId={this.onPurchaseOrderCodeChange}
                                                    inputPlaceholder={i18next.t("choose-the-order")}
                                                    chosenExternalId={this.state.program.purchaseOrderCode}
                                                    removePossible={true}/>
        }
    }

    getUserFormControl() {
        if (this.state.loggedInUser.roles.some(e => e === "ROLE_SUPERUSER")) {
            return <UserSuggestion label={i18next.t("managing-user")}
                                   onChangeSuggestionId={this.onManagingUserIdChange}
                                   inputPlaceholder={i18next.t("choose-the-user")}
                                   chosenUserId={this.state.program.managingUserId}
                                   superUser={true}/>
        } else {
                return <RoleUSERUserFormControl label={i18next.t("managing-user")} name="managingUserId" value={this.state.program.managingUserId} disabled={true}/>
        }

    }

    render() {
        return (
            <form id="programForm" style={this.props.style} onReset={this.onReset} onSubmit={this.onSubmit}>
                <FormControl label={i18next.t("portfolio-name")} name="name" value={this.state.program.name} onChange={this.onChange} maxLength={STRING_LENGTH}/>
                <FormControl label={i18next.t("portfolio-code")} name="code" value={this.state.program.code} onChange={this.onChange} maxLength={STRING_LENGTH}/>
                <FormControl label={i18next.t("createdBy")} name="createdBy" value={this.props.program.createdBy}
                             className="form-control-plaintext" onChange = {() => null}/>
                {this.getUserFormControl()}
                <FormControl label={i18next.t("active")} name="active" type="checkbox" checked={this.state.program.active} onChange={this.onChange} required={true}/>
			    <FormControl label={i18next.t("start-date")} name="startDate" type="date" value={this.state.program.startDate} onChange={this.onChange} max={DATE_MAX} min={DATE_MIN}/>
			    <FormControl label={i18next.t("end-date")} name="endDate" type="date" value={this.state.program.endDate} onChange={this.onChange} max={DATE_MAX} min={DATE_MIN}/>
			    <FormControl label={i18next.t("start-report-date")} name="startReportDate" type="date" value={this.state.program.startReportDate} onChange={this.onChange} max={DATE_MAX} min={DATE_MIN}/>
				{this.getPurchaseOrderFormControl()}
                <div className="row buttons-to-left btn-grey-container btn-overlap-container">
                    <div className="col-sm-2">&nbsp;</div>
                    <FormButton type="submit" value={i18next.t("save-button")}/>
                    <FormButton type="reset" value={i18next.t("cancel-button")}/>
                    {this.getCopyButton()}
                </div>
            </form>
        );
    }
}

ProgramForm.propTypes = {
    program: PropTypes.object,
    style: PropTypes.object,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func
};

export default ProgramForm;
