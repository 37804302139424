import React, {Component} from 'react';
import PageableFilter from "../../common/PageableFilter";
import i18next from "i18next";

class TaskStatusFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: this.props.filter
        };

        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onKeyDown(event){
        if (event.keyCode === 13) {
            this.props.onSubmit();
        }
    }

    render() {
        return (
	    	<>
	    		<PageableFilter colSpan="3"
	    						onFilterChange={this.props.onFilterChange}
	    						findPageSize={this.props.filter.findPageSize}
	    						findPageNumber={this.props.filter.findPageNumber}
	    						resultSize={this.props.resultSize} />
	    		<tr>
	    			<th scope="col"/>
                    <th scope="col"><input className="form-control" type="text" name="findName" value={this.state.filter.findName} onChange={this.props.onFilterChange}
                                           onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
	    		</tr>
	    	</>
        );
    }
}

export default TaskStatusFilter;