import React, {Component} from 'react';

export default class TaskGroupReportListItem extends Component {

    getRealReports() {
        return this.props.allReports.filter((report) => {
            return report.projectCode === this.props.report.projectCode
        })
    }

    render() {
        return (
            <tr>
                <td>
                    {this.props.report.projectName}
                </td>
                <td>
                    {this.props.report.projectCode}
                </td>

                    {this.props.users.map((user) => {
                        if (this.getRealReports().map(report => report.assignedUserName)
                            .includes(user)) {
                            return <td> {this.getRealReports().filter(rep => rep.assignedUserName === user)[0].loggedInPeriodHours} </td>
                        } else {
                            return <td/>;
                        }
                    })}

                 <th>
                     {this.getRealReports()
                         .map(report => report.loggedInPeriodHours)
                         .reduce((previousValue, currentValue) => {
                             return Number(previousValue) + Number(currentValue)
                         }, 0)}
                 </th>



            </tr>
        );
    }
}