import React, { Component } from 'react';
import OrderFilter from './OrderFilter.js';
import OrderListItem from './OrderListItem.js';
import moment from "moment";
import SortableColumn from "../common/SortableColumn";
import i18next from "i18next";
import PageableFilter from "../common/PageableFilter";
import ListTitle from "../common/ListTitle";

class OrderList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowId: null,
            filter: {},
            addButtonLabel: i18next.t("add-new")
        };

        this.selectRow = this.selectRow.bind(this);
        this.findOrders = this.findOrders.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onCalendarChangeStartDate = this.onCalendarChangeStartDate.bind(this);
        this.onCalendarChangeEndDate = this.onCalendarChangeEndDate.bind(this);
        this.onSortOrder = this.onSortOrder.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
    }

    componentDidMount() {
        this.findOrders();
    }

    selectRow(key) {
        this.setState((prevState) => {
            return {
                selectedRowId: key === prevState.selectedRowId ? null : key,
                addButtonLabel: i18next.t("add-new")
            };
        });
    }

    findOrders() {
        let filter = { ...this.state.filter };
        if (this.state.filter.startDateLeftLimit !== undefined && this.state.filter.startDateRightLimit !== undefined) {
            if (this.state.filter.startDateLeftLimit !== null && this.state.filter.startDateRightLimit !== null) {
                filter.startDateLeftLimit = moment(this.state.filter.startDateLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.startDateRightLimit = moment(this.state.filter.startDateRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.startDateRightLimit == null && this.state.filter.startDateLeftLimit !== null) {
                filter.startDateLeftLimit = moment(this.state.filter.startDateLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.startDateRightLimit !== null && this.state.filter.startDateLeftLimit == null) {
                filter.startDateRightLimit = moment(this.state.filter.startDateRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
        if (this.state.filter.endDateLeftLimit !== undefined && this.state.filter.endDateRightLimit !== undefined) {
            if (this.state.filter.endDateLeftLimit !== null && this.state.filter.endDateRightLimit !== null) {
                filter.endDateLeftLimit = moment(this.state.filter.endDateLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.endDateRightLimit = moment(this.state.filter.endDateRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.endDateRightLimit == null && this.state.filter.endDateLeftLimit !== null) {
                filter.endDateLeftLimit = moment(this.state.filter.endDateLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.endDateRightLimit !== null && this.state.filter.endDateLeftLimit == null) {
                filter.endDateRightLimit = moment(this.state.filter.endDateRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
        this.props.onFindOrders(filter);
    }

    onCalendarChangeStartDate(event) {
        this.setState({
            filter: {
                ...this.state.filter,
                startDateLeftLimit: event.startDate,
                startDateRightLimit: event.endDate,
                findPageNumber: "0"
            }
        }, () => this.findOrders()
        )
    }

    onCalendarChangeEndDate(event) {
        this.setState({
            filter: {
                ...this.state.filter,
                endDateLeftLimit: event.startDate,
                endDateRightLimit: event.endDate,
                findPageNumber: "0"
            }
        }, () => this.findOrders()
        )
    }

    onFilterChange(event) {
        let filter = Object.assign({}, this.state.filter);
        const name = event.target.name;
        filter[name] = event.target.value;
        if (!["findPageNumber", "sortBy", "sortDirection"].includes(name)) {
            filter["findPageNumber"] = "0";
        }
        this.setState({ filter }, () => {
            this.findOrders();
        });
    }

    onSortOrder(filter) {
        this.setState({
            filter: filter, selectedRowId: null
        }, () => this.findOrders());
    }

    addNewItem() {
        this.setState(prevState => ({
            selectedRowId: prevState.selectedRowId === '+' ? null : '+',
            addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
        }))
    }

    render() {
        return (
            <div>
                <table className="table table-sm">
                    <thead>
                        <ListTitle sectionLabel={i18next.t("navbar-orders")} span={6}
                            onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel} />
                        <OrderFilter filter={this.state.filter} onCalendarChangeStartDate={this.onCalendarChangeStartDate}
                            onCalendarChangeEndDate={this.onCalendarChangeEndDate} onFilterChange={this.onFilterChange} onSubmit={this.findOrders}
                            resultSize={this.props.orders.length} />
                        <tr>
                            <SortableColumn name="p.id" label="Id" filter={this.state.filter} onClick={this.onSortOrder} />
                            <SortableColumn name="p.customer.name" label={i18next.t("customer-name")}
                                filter={this.state.filter} onClick={this.onSortOrder} />
                            <SortableColumn name="p.externalId" label={i18next.t("external-id")}
                                filter={this.state.filter} onClick={this.onSortOrder} />
                            {/* <SortableColumn name= "p.startDate" label= {i18next.t("start-date")} filter = {this.state.filter}
                                            onClick = {this.onSortOrder} className = {"text-center"}/> */}
                            <SortableColumn name="p.startDate" label={i18next.t("start-date")} filter={this.state.filter}
                                onClick={this.onSortOrder} />
                            <SortableColumn name="p.endDate" label={i18next.t("end-date")}
                                filter={this.state.filter} onClick={this.onSortOrder} />
                            <SortableColumn name="p.user.login, p.user.firstName, p.user.lastName" label={i18next.t("managing-user")}
                                filter={this.state.filter} onClick={this.onSortOrder} />
                            <SortableColumn name="p.orderInDays" label={i18next.t("amount-of-wages")}
                                filter={this.state.filter} onClick={this.onSortOrder} />
                            <SortableColumn name="p.ratePerDay" label={i18next.t("day-wage")}
                                filter={this.state.filter} onClick={this.onSortOrder} />
                        </tr>
                    </thead>
                    <tbody>
                        <OrderListItem key={'+'} selected={this.state.selectedRowId} onSelect={this.selectRow}
                            findOrders={this.findOrders} order={{
                                id: "+", customerName: "", externalId: "", startDate: "",
                                endDate: "", userFullName: "", orderInDays: "", ratePerDay: "", plus: "plus"
                            }} />
                        {this.props.orders.map((order) =>
                            <OrderListItem key={order.id} selected={this.state.selectedRowId} order={order}
                                onSelect={this.selectRow} findOrders={this.findOrders} />)}
                        <PageableFilter colSpan="8" onFilterChange={this.onFilterChange}
                            findPageSize={this.state.filter.findPageSize}
                            findPageNumber={this.state.filter.findPageNumber}
                            resultSize={this.props.orders.length}
                            hidePageSize={true} />
                    </tbody>
                </table>
            </div>
        );
    }
}

export default OrderList;
