import React, {Component} from 'react';
import i18next from 'i18next';

class Version extends Component {

    render() {
        return (
            <>
                <h4 className="headline-version">Timekeeper, {i18next.t("version")} {process.env.REACT_APP_VERSION}</h4>
                {/* <hr className="my-4"/> */}
            </>
        )
    }
}

export default Version;