import request from '../lib/request';

function get(id) {
    return request({
        url: `/users/${id}`,
        method: 'GET'
    });
}

function getByLogin(login) {
    var dt = {};
    dt["login"] = login;
    return request({
        url: `/users/reset`,
        method: 'POST',
        data: dt
    });
}

function find(searchParams) {
    return request({
        url: "/users",
        method: 'GET',
        params: {
            login: searchParams.findLogin,
            firstName: searchParams.findFirstName,
            lastName: searchParams.findLastName,
            email: searchParams.findEmail,
            active: searchParams.findActive,
            size: searchParams.findPageSize === undefined ? "50" : searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy+","+searchParams.sortDirection
        }
    });
}

function findAll() {
    return request({
        url: "/users",
        method: 'GET'
    });
}

function create(user) {
	let data = Object.assign({}, user);
	data.id = null;
    return request({
    	url: "/users",
    	method: 'POST',
    	data: data
    });
}

function update(user) {
    return request({
    	url: "/users/" + user.id,
    	method: 'PUT',
    	data: user
    });
}

function getLoggedInUser() {
    return request({
        url: "/users/loggedin",
        method: 'GET'
    })
}

function findByTeamId(teamId) {
    return request({
        url: `/users/teams/${teamId}`,
        method: 'GET'
    });
}

function findSuggestions(value) {
    return request({
        url: "/users/suggestions",
        method: 'GET',
        params: {
            value: value
        }
    });
}

function findAssignableUsers(projectId) {
    return request({
        url: `/users/usersByProjectId/${projectId}`,
        method: 'GET'
    });
}

function findUsersRoleUser() {
    return request({
        url: "/users/roleuser",
        method: 'GET'
    });
}

const UserService = {
    find,
    get,
    create,
    update,
    getByLogin,
    findAll,
    getLoggedInUser,
    findByTeamId,
    findSuggestions,
    findUsersRoleUser,
    findAssignableUsers
};

export default UserService;