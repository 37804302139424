import { Component } from "react";
import {toast} from "react-toastify";

export default class Utils extends Component {

    static error(message) {
        toast.error(message, {
            autoClose: 15000,
            closeOnClick: true,
            icon: false
        });
    }
}