import request from '../lib/request';

function get(id) {
    return request({
        url: `/skills/${id}`,
        method: 'GET'
    });
}

function find(searchParams) {
    return request({
        url: "/skills",
        method: 'GET',
        params: {
            name: searchParams.findName,
            skillCategoryName: searchParams.findSkillCategoryName,
            size: searchParams.findPageSize === undefined ? "50" : searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy+","+searchParams.sortDirection
        }
    });
}

function create(skill) {
	let data = Object.assign({}, skill);
	data.id = null;
    return request({
    	url: "/skills",
    	method: 'POST',
    	data: data
    });
}

function update(skill) {
    return request({
    	url: "/skills/" + skill.id,
    	method: 'PUT',
    	data: skill
    });
}

function del(id) {

    return request({
        url: `/skills/${id}`,
        method: 'DELETE'
    })
}


const SkillService = {
    find,
    get,
    create,
    update,
    del
};

export default SkillService;