import React from 'react';
import PropTypes from 'prop-types';



export default class WorkListItemHeaderItem extends React.Component {


    render() {
        let style = {
            textAlign: 'center'
        };
        if (this.props.day.free) {
            style = {
                textAlign: 'center',
                color: '#25a1ff'
            }
        }
        return (
            <th scope="col" style={style}>{this.props.headerText}</th>
        );
    }
}

WorkListItemHeaderItem.propTypes = {
    day: PropTypes.object.isRequired
};