import React, {Component} from 'react';
import '../../reactTags.css';
import {WithContext as ReactTags} from 'react-tag-input';
import i18next from 'i18next';
import TeamService from '../../services/team.js';

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class ProjectTeamTags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            suggestions: []
        };

        this.projectTeams = this.projectTeams.bind(this);
        this.teamSuggestions = this.teamSuggestions.bind(this);
    }

    componentDidMount() {
        TeamService
            .findAll()
            .then((result) => {
                this.setState({suggestions: result, tags: this.projectTeams()});
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.teams !== this.props.teams) {
            const tags = this.projectTeams();
            this.setState({tags: tags});
        }
    }

    teamSuggestions() {
        return (
            this.state.suggestions.map((team) => {
                return {
                    id: team.id.toString(),
                    text: team.name
                };
            })
        );
    }

    projectTeams() {
        return this.props.teams.map((team) => {
            return {
                id: team.id.toString(),
                text: team.text,
                className: 'react-tagsinput--focused'
            };
        });
    }

    render() {
        const{tags} = this.state;
        return (
            <div>
                <ReactTags
                    tags={tags}
                    suggestions={this.teamSuggestions()}
                    minQueryLength={0}
                    delimiters={delimiters}
                    handleDelete={this.props.handleDel}
                    handleAddition={this.props.handleAdd}
                    placeholder={i18next.t('team-add-new')}
                    autofocus={false}
                    renderSuggestion={({ text }) => <div>{text}</div>}
                    autocomplete={true}/>
            </div>
        );
    }
}

export default ProjectTeamTags;