import request from '../lib/request';

function get(id) {
    return request({
        url: `/holidays/${id}`,
        method: 'GET'
    });
}

function find() {
    return request({
        url: "/holidays",
        method: 'GET',
    });
}

function create(holiday) {
	var data = Object.assign({}, holiday);
	data.id = null;
    return request({
    	url: "/holidays",
    	method: 'POST',
    	data: data
    });
}

function update(holiday) {
    return request({
    	url: "/holidays/" + holiday.id,
    	method: 'PUT',
    	data: holiday
    });
}

function remove(holidayId) {
    return request({
    	url: "/holidays/" + holidayId,
    	method: 'DELETE'
    });
}

const HolidayService = {
    find,
    get,
    create,
    update,
    remove
};

export default HolidayService;