import React, { Component } from 'react';
import TaskStatusService from '../../services/taskstatus.js';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import i18next from "i18next";
import Utils from "../common/Utils";

class TaskStatusFormControl extends Component {

    constructor(props) {
    	super(props);
    	this.state = {
    	    statuses: [],
    	    selectedOption: null,
    	    taskStatusArray: []
    	}

    	this.handleChange = this.handleChange.bind(this);
    }

	componentDidMount() {
    	TaskStatusService
    	    .find({})
    		.then((result) => {
    			this.setState({statuses: result});
    		}).catch(error => Utils.error(i18next.t("task-status-error") + JSON.stringify(error)));
    }

    handleChange = (selectedOption) => {

        let taskArray = [];
        for (let i=0; i < selectedOption.length; i++) {
            taskArray.push(selectedOption[i].value);
        }
        this.props.onChange(taskArray);
        this.setState({selectedOption});
    }

    render() {
        let result = [];
        this.state.statuses.map((status) =>
            result.push({label: status.name, value: status.id}));
        const {selectedOption} = this.state;
        return (
	    	<div>
	    		<ReactMultiSelectCheckboxes value={selectedOption} options={result} onChange={this.handleChange}
                                            placeholderButtonLabel={i18next.t("all")}/>
	    	</div>
        );
    }
}

export default TaskStatusFormControl;