import React, { Component } from 'react';
import PropTypes from "prop-types";
import PageableFilter from "../common/PageableFilter";
import i18next from 'i18next';

class UserFilter extends Component {

	constructor(props) {
		super(props);

		this.onKeyDown = this.onKeyDown.bind(this);
	}

	onKeyDown(event) {
		if (event.keyCode === 13) {
			this.props.onSubmit();
		}
	}
	
  render() {
    return <>
            <PageableFilter colSpan="6" onFilterChange={this.props.onFilterChange}
                            onSubmit={this.props.onSubmit}
                            findPageSize={this.props.filter.findPageSize}
                            findPageNumber={this.props.filter.findPageNumber}
                            resultSize={this.props.resultSize} />
			<tr>
				<th/>
				<th scope="col"><input className="form-control" type="text" name="findFirstName" value={this.props.filter.findFirstName} onChange={this.props.onFilterChange}
                                       onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
				<th scope="col"><input className="form-control" type="text" name="findLastName" value={this.props.filter.findLastName} onChange={this.props.onFilterChange}
									   onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
				<th scope="col"><input className="form-control" type="text" name="findLogin" value={this.props.filter.findLogin} onChange={this.props.onFilterChange}
									   onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
				<th scope="col"><input className="form-control" type="text" name="findEmail" value={this.props.filter.findEmail} onChange={this.props.onFilterChange}
									   onKeyDown={this.onKeyDown} placeholder={i18next.t("all")}/></th>
				<th scope="col"><select className="custom-select"  name="findActive" value={this.props.filter.findActive} onChange={this.props.onFilterChange}>
                    <option value="">{i18next.t('users-all')}</option>
                    <option value="true">{i18next.t('users-active')}</option>
                    <option value="false">{i18next.t('users-inactive')}</option>
                    </select></th>
				{/* <th/> */}
			</tr>
		</>

  }
}

UserFilter.propTypes = {
	filter: PropTypes.object,
    resultSize: PropTypes.number,
    onFilterChange: PropTypes.func,
    onSubmit: PropTypes.func
};

export default UserFilter;