import request from "../lib/request";

function sendEmailWithAttachment(message, files) {
    return request({
        url: "/emails/sendEmailWithAttachment",
        method: 'POST',
        params: {
            message: message
        },
        data: files,
    });
}

const EmailService = {
    sendEmailWithAttachment
};

export default EmailService;