import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class ErrorDialog extends Component {

	render() {
		return <Modal.Dialog>
		    <Modal.Header closeButton>
		    	<Modal.Title>Wystąpił błąd</Modal.Title>
		    </Modal.Header>
		    <Modal.Body>
		    	{this.props.description}
		    </Modal.Body>
		    <Modal.Footer>
		    	<Button variant="primary" onClick={this.props.onClose}>Zamknij</Button>
		    </Modal.Footer>
		</Modal.Dialog>
	}
}

export default ErrorDialog;