import request from '../lib/request';

function get(id) {
    return request({
        url: `/projects/${id}`,
        method: 'GET'
    });
}

function getPrograms() {
    return request({
        url: "/programs",
        method: 'GET'
    })
}

function find(searchParams) {
    return request({
        url: "/projects",
        method: 'GET',
        params: {
            login: searchParams.findCreatedBy,
            name: searchParams.findName,
            code: searchParams.findCode,
            active: searchParams.findActive,
            managerName: searchParams.findManager,
            programName: searchParams.findProgram,
            startDateLeftLimit: searchParams.startDateLeftLimit,
            startDateRightLimit: searchParams.startDateRightLimit,
            endDateLeftLimit: searchParams.endDateLeftLimit,
            endDateRightLimit: searchParams.endDateRightLimit,
            size: searchParams.findPageSize === undefined ? "50" : searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy+","+searchParams.sortDirection
        }
    });
}

function findAllActive() {
    return request({
        url: "/projects/short",
        method: 'GET'
    });
}

function create(project) {
	let data = Object.assign({}, project);
	data.id = null;
    return request({
    	url: "/projects",
    	method: 'POST',
    	data: data
    });
}

function update(project) {
    return request({
    	url: "/projects/" + project.id,
    	method: 'PUT',
    	data: project
    });
}

const ProjectService = {
    find,
    get,
    create,
    update,
    getPrograms,
    findAllActive
}

export default ProjectService;