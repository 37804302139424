import React, {Component} from 'react';
import PropTypes from "prop-types";
import i18next from 'i18next';

class FormTextarea extends Component {

    render() {
        return (
            <div className="form-group row">
                <label className="col-sm-2 control-label"
                       htmlFor={this.props.name}>{this.props.labelKey === undefined ? this.props.label : i18next.t(this.props.labelKey)}</label>
                <div className="col-sm-6">
                    <textarea className="form-control" name={this.props.name} value={this.props.value} onChange={this.props.onChange}/>
                </div>
            </div>
        );
    }
}

FormTextarea.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    labelKey: PropTypes.string,
    value: PropTypes.string
};

export default FormTextarea;