import UserService from "../../services/user";
import UserSuggestion from '../common/UserSuggestion';

class TaskUserFormControl extends UserSuggestion {

    componentDidMount() {
        super.componentDidMount();
        this.readUsers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        if (prevProps.projectId !== this.props.projectId) {
            this.readUsers();
        }
    }

    readUsers() {
        if (this.props.projectId) {
            UserService
                .findAssignableUsers(this.props.projectId)
                .then((result) => {
                    this.setState({items: result});
                });
        } else {
            this.setState({items: []});
        }
    }
}

export default TaskUserFormControl;
