import React, { Component } from 'react';
import AddressForm from './AddressForm';

class AddressListItem extends Component {

    render() {
        let addressForm = null;
        if (this.props.selected === this.props.address.id) {
            addressForm = <tr>
                <td colSpan="4">
                    <AddressForm key={this.props.address.id} customerId={this.props.customerId}
                                 address={this.props.address} onChange={this.props.onChange}
                                 addresses={this.props.addresses}/>
                </td>
            </tr>
        }
        let listItemContent = null;
        if (this.props.address.id === "+") {
            listItemContent =
                <td colSpan="4">+</td>

        } else {
            listItemContent =
                <>
                    <td>{this.props.address.addressUsageTypeName}</td>
                    <td>{this.props.address.fullStreetName}</td>
                    <td>{this.props.address.postalCode}</td>
                    <td>{this.props.address.settlement}</td>
                </>
        }
        return (
            <>
                <tr onClick={() => this.props.onSelect(this.props.address.id)}>
                    {listItemContent}
                </tr>
                {addressForm}
            </>
        )
    }
}

export default AddressListItem;