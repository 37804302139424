import React, {Component} from 'react';
import i18next from 'i18next';
import InactiveList from './InactiveList';
import InactiveService from '../../../services/inactive.js';
import FormButton from '../../common/FormButton.js';
import {SyncLoader} from 'react-spinners';
import {css} from '@emotion/core';
import Utils from "../../common/Utils";
import {toast} from 'react-toastify';
import FormCheckbox from '../../common/FormCheckbox';
import InactiveRoleList from './InactiveRoleList';

const override = css`
    display: block;
    margin: 2 auto;
    border-color: red;
    z-index: 1;
`;

class InactiveFilteredList extends Component {

	constructor(props) {
		super(props);
        this.state = {
            filter: {
                objectType: "TASK",
                inactiveFromDays: 30,
                showOnlyActive: true
            },
            objectTypes: [],
            inactiveList: [],
            loading: false,
            selectedIds: []
        };

        this.findInactiveObjects = this.findInactiveObjects.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onDeactivate = this.onDeactivate.bind(this);
        this.onCheckItem = this.onCheckItem.bind(this);
        this.onCheckAll = this.onCheckAll.bind(this);
	}

    componentDidMount() {
        InactiveService
            .getRoles()
            .then((result) => {
                let filter = {...this.state.filter};
                filter.objectType = result.length > 0 ? result[0].id : "";
                this.setState({
                    objectTypes: result,
                    filter: filter
                });
            });
    }

    onFilterChange(event) {
	    let filter = Object.assign({}, this.state.filter);
        const name = event.target.name;
        filter[name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({filter});
    }

    findInactiveObjects() {
	    this.setState({loading: true});
        InactiveService
		    .find(this.state.filter)
		    .then((response) => {
			    this.setState({inactiveList: response, loading: false, selectedIds: []});
		    }).catch((error) => {
			    Utils.error(i18next.t("inactive-objects-error") + JSON.stringify(error));
			    this.setState({loading: false})
		    });
	}

    onCheckItem(id, isSelected) {
	    const newIds = [...this.state.selectedIds];

        const index = newIds.indexOf(id);

        if (isSelected && index === -1) {
            newIds.push(id);
            this.setState({selectedIds: newIds});
        } else if (!isSelected && index > -1) {
            newIds.splice(index, 1);
            this.setState({selectedIds: newIds})
        }
    }

    onCheckAll(event) {
	    if (event.target.checked) {
            let selectedIds = this.state.inactiveList.map((o) => "" + o.id.toString());
            this.setState({selectedIds})
        } else {
            this.setState({selectedIds: []})
        }
    }

	onDeactivate() {
	    if (this.state.selectedIds.length === 0 ) {
            alert(i18next.t('select-items-to-deactivate'));
        } else {
            InactiveService
                .deactivate(this.state.filter.objectType, this.state.selectedIds)
                .then(() => this.findInactiveObjects())
                .catch((error) => {
                    Utils.error(i18next.t("inactive-objects-error") + JSON.stringify(error));
                })
            toast.info(i18next.t('deactivate-complete'), {
                icon: false
              });
        }
	}

	render() {
        let data;
        if (this.state.loading) {
            data =  <div className="row align-items-center justify-content-center">
                        <SyncLoader
                            css={override}
                            sizeUnit={"px"}
                            size={15}
                            color={'#d0e1e1'}
                            loading={this.state.loading}/>
                    </div>
        } else {
            data = <InactiveList onCheckItem={this.onCheckItem}
                                 inactiveObjects={this.state.inactiveList}
                                 selectedIds={this.state.selectedIds}
                                 onCheckAll={this.onCheckAll}/>
        }

        return (
            <>
				<table className="table">
				    <thead className="thead-t-report">
                        <tr className="tr-t-report">
                            <th scope="col">{i18next.t("object-type")} <InactiveRoleList onRoleChange={this.onFilterChange} name="objectType" value={this.state.filter.objectType} roles={this.state.objectTypes}/></th>
                            <th scope="col">{i18next.t("inactivity-for")} <input className="form-control" type="number" name="inactiveFromDays" value={this.state.filter.inactiveFromDays}
                                                                      	    			                       onChange={this.onFilterChange}/></th>
                            <th scope="col">
                                <FormCheckbox name="showOnlyActive"
                                              checked={this.state.filter.showOnlyActive}
                                              onChange={this.onFilterChange}
                                              label={i18next.t("show-only-active")}/>
                            </th>
                            <th scope="col"><FormButton type="button" onClick={this.findInactiveObjects} value={i18next.t("generate-button")}/></th>
                            <th scope="col"><FormButton type="button" onClick={this.onDeactivate} value={i18next.t("deactivate-selected")} notVisible={this.state.inactiveList.length === 0}/></th>
        		    	</tr>
                    </thead>
                </table>
                {data}
        	</>
        );
    }
}

export default InactiveFilteredList;
