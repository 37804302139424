import React, {Component} from 'react';

class FileStatusTableRow extends Component {

    render() {
        return (
            <tr>
                <th scope="row">{this.props.label}</th>
                <td>{this.props.data.lastCorrectUpdate}</td>
                <td>{this.props.data.statusDate}</td>
                <td>{this.props.data.description}</td>
            </tr>
        )
    }
}

export default FileStatusTableRow;