import React, { Component } from 'react';
import SkillFilter from './SkillFilter.js';
import SkillListItem from './SkillListItem.js';
import i18next from 'i18next';
import SortableColumn from "../../common/SortableColumn";
import PageableFilter from "../../common/PageableFilter";
import ListTitle from "../../common/ListTitle";

class SkillList extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
				selectedRowId: null,
				filter: {},
				addButtonLabel: i18next.t("add-new")
		};

		this.selectRow = this.selectRow.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onCreateSkill = this.onCreateSkill.bind(this);
		this.onUpdateSkill = this.onUpdateSkill.bind(this);
		this.onSortOrder = this.onSortOrder.bind(this);
		this.addNewItem = this.addNewItem.bind(this);
	}

	selectRow(key) {
		this.setState((prevState) => {
				return {selectedRowId: key === prevState.selectedRowId ? null: key,
					    addButtonLabel: i18next.t("add-new")}
		});
	}

	onFilterChange(event) {
		let filter = {...this.state.filter};
		let targetName = event.target.name;
		filter[targetName] = event.target.value;
		if(!["findPageNumber", "sortBy", "sortDirection"].includes(event.target.name)){
			filter["findPageNumber"] = "0";
		}
		this.setState({filter}, () => {
				this.props.onFindSkills(this.state.filter)
		});
	}
	
	onCreateSkill(skill) {
		this.setState({selectedRowId: null,
			addButtonLabel: i18next.t("add-new")});
	}
	
	onUpdateSkill(skill) {
		this.setState({skill: skill});
		this.props.onFindSkills(this.state.filter);
	}

	onSortOrder(filter) {
		this.setState({filter: filter, selectedRowId: null}, () => this.props.onFindSkills(this.state.filter));
	}

	addNewItem(){
		this.setState(prevState => ({
			selectedRowId: prevState.selectedRowId === '+' ? null : '+',
			addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
		}))
	}

    render() {
        return (
	    	<table className="table table-sm">
	    		<thead>
				<ListTitle sectionLabel={i18next.t("configurations-tab-skill")} span={1}
						   onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
	    		<SkillFilter  resultSize={this.props.skills.length} filter={this.state.filter} onFilterChange={this.onFilterChange} onSubmit={this.findSkills} />
	    		<tr>
	    			<SortableColumn name="s.id" label="Id" filter={this.state.filter} onClick={this.onSortOrder}/>
	    			<SortableColumn name="s.name" label={i18next.t("configurations-skill-skill")} filter={this.state.filter} onClick={this.onSortOrder}/>
	    			<SortableColumn name="s.skillCategory.name" label={i18next.t("configurations-skill-skill-category")} filter={this.state.filter} onClick={this.onSortOrder}/>
	    		</tr>
	    		</thead>
	    		<tbody>

				<SkillListItem key={'+'} selected={this.state.selectedRowId} onSelect={this.selectRow} onCreate={this.onCreateSkill}
							   skill={{id: "+", name: "", skillCategory: "", plus: "plus"}} onFindSkills={this.props.onFindSkills} filter={this.state.filter}/>
	    			{this.props.skills.map((skill) =>
	    				<SkillListItem key={skill.id} selected={this.state.selectedRowId} skill={skill} onSelect={this.selectRow} onUpdate={this.onUpdateSkill}
						onFindSkills={this.props.onFindSkills} filter={this.state.filter}/>)
	    			}
				<PageableFilter colSpan="3" onFilterChange={this.onFilterChange}
								findPageSize={this.state.filter.findPageSize}
								findPageNumber={this.state.filter.findPageNumber}
								resultSize={this.props.skills.length}
								hidePageSize={true}/>
	    		</tbody>
	    	</table>
        );
    }
}

export default SkillList;