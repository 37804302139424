import React, { Component } from 'react';
import UserService from '../../services/user.js';
import i18next from 'i18next';

class UserFormControl extends Component {

    constructor(props) {
		super(props);
		this.state = {
            users: []
        }
	}

	componentDidMount() {
    	UserService
    	    .findAll()
    		.then((result) => {
    			this.setState({users: result});
    		});
    }

    renderNewUser() {
        if ((this.props.value === undefined && this.props.name === "managingUserId") || (this.props.name !== "managingUserId")) {
            return <option key="" value="">&lt;{i18next.t("choose-the-user")}&gt;</option>
        }
    }

	classNameRequired() {
		if (this.props.required === true) {
			return "form-group row required";
		}
		return "form-group row";
	}

    render() {
        return (
            <div className={this.classNameRequired()}>
				<label className="col-sm-2 control-label"
					   htmlFor={this.props.name}>{this.props.label}</label>
            	<div className="col-sm-6" >
            	    <select name={this.props.name} className="form-control" value={this.props.value} onChange={this.props.onChange} disabled={this.props.disabled}>
						{this.renderNewUser()}
            	        {this.state.users.map((user) =>
                        	 <option key={user.id} value={user.id}>{user.firstName} {user.lastName} ({user.login})</option>)
                        }
                    </select>
            	</div>
            </div>
        );
    }
}

export default UserFormControl;