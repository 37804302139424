import React from 'react';
import FormControl from '../common/FormControl.js';
import FormButton from '../common/FormButton.js';
import UserRoleForm from './UserRoleForm.js';
import UserService from '../../services/user.js';
import ErrorHandlerComponent from "../common/error/ErrorHandlerComponent";
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Utils from "../common/Utils";

class UserForm extends ErrorHandlerComponent {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user
        };

        this.onRoleChange = this.onRoleChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.user.id !== "+") {
            UserService
                .get(this.props.user.id)
                .then((response) => {
                    this.setState({
                        user: response,
                        baseState: JSON.parse(JSON.stringify(response))
                    });
                }).catch((error) => {
                    this.setState({error});
                });
        }
    }

    onChange(event) {
        let user = Object.assign({}, this.state.user);
        user[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({user});
    }

    onRoleChange(roles) {
        let user = Object.assign({}, this.state.user);
        user.roles = roles;
        this.setState({user});
    }

    resetForm() {
        this.setState({user: JSON.parse(JSON.stringify(this.state.baseState))});
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.user.id === "+") {
            UserService
                .create(this.state.user)
                .then((data) => {
                    this.props.onCreate(data);
                    toast.info(i18next.t('add-user-success'), {
                        icon: false
                      });
                    this.props.onFindUsers();
                }).catch((error) => {
                    this.setState({error});
                });
        } else {
            UserService
                .update(this.state.user)
                .then((response) => {
                    this.setState({user: response});
                    this.props.onUpdate(response);
                    toast.info(i18next.t("modify-user-success"), {
                        icon: false
                      });
                }).catch((error) => {
                    this.setState({error});
                });
        }
    }

    resetPassword(login) {
        UserService.getByLogin(login).then(response => {
            toast.info(i18next.t("password-reset-email", {icon: false, email: this.state.user.email}))
        }).catch(error => {
            Utils.error(i18next.t("reset-password-fail") + error.data.message);
        })
    }

    resetPasswordButton() {
        let reset = null;
        if (this.state.user.email !== null && this.state.user.email.length !== 0) {
            reset = <FormButton type="button" onClick={() => this.resetPassword(this.state.user.login)}
                                value={i18next.t("reset-set-password-button")}/>
        }
        return reset;
    }

    doRender() {
        let disabled = false;
        if (this.state.user.id !== "+") {
            disabled = true;
        }
        return (
            <form className="form-horizontal" id="userForm" onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-sm">
                        <FormControl label={i18next.t("first-name")} name="firstName" value={this.state.user.firstName}
                                     onChange={this.onChange}/>
                        <FormControl label={i18next.t("last-name")} name="lastName" value={this.state.user.lastName}
                                     onChange={this.onChange}/>
                        <FormControl label="Login" name="login" value={this.state.user.login} onChange={this.onChange}
                                     disabled={disabled} required={true}/>
                        <FormControl label="Email" name="email" type="email" value={this.state.user.email}
                                     onChange={this.onChange}/>
                        <FormControl label={i18next.t("created-by")} name="createdBy" value={this.state.user.createdBy}
                                     className="form-control-plaintext" onChange={() => null}/>
                        <FormControl label={i18next.t("active")} name="active" type="checkbox" checked={this.state.user.active}
                                     onChange={this.onChange} required={true}/>
                    </div>
                    <div className="col-sm">
                        <UserRoleForm name="role" userRoles={this.state.user.roles} onChange={this.onRoleChange}/>
                    </div>
                </div>
                <div className="row buttons-to-left btn-grey-container">
                    <div className="col-sm-1">&nbsp;</div>
                    <FormButton type="submit" value={i18next.t("save-button")}/>
                    <FormButton type="button" onClick={this.resetForm} value={i18next.t("cancel-button")}/>
                    {this.resetPasswordButton()}
                </div>
            </form>
        );
    }
}

UserForm.propTypes = {
    user: PropTypes.object,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func
};

export default UserForm;