import request from '../lib/request';

function get(id) {
    return request({
        url: `/customers/${id}`,
        method: 'GET'
    });
}

function find(searchParams) {
    return request({
        url: "/customers",
        method: 'GET',
        params: {
            name: searchParams.findName,
            nip: searchParams.findNip,
            externalId: searchParams.findExternalId,
            managingUserFullName: searchParams.findManagingUserFullName,
            size: searchParams.findPageSize === undefined ? "50" : searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy+","+searchParams.sortDirection
        }
    });
}

function findAll() {
    return request({
        url: "/customers",
        method: 'GET'
    });
}

function findByManager() {
    return request({
        url: "/customers/manager",
        method: 'GET'
    });
}

function create(customer) {
    const data = Object.assign({}, customer);
	data.id = null;
    return request({
        url: "/customers",
        method: 'POST',
        data: data
    });
}

function update(customer) {
    const data = Object.assign({}, customer);
    return request({
        url: `/customers/${customer.id}`,
        method: 'PUT',
        data: data
    });
}

function getAddressUsageTypes() {
    return request({
        url: "/customers/addressusagetypes",
        method: 'GET'
    });
}

const CustomerService = {
    get,
    find,
    create,
    update,
    findAll,
    findByManager,
	getAddressUsageTypes
};

export default CustomerService;