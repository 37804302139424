import React , {Component} from 'react';
import InactiveFilteredList from './InactiveFilteredList';

class InactiveContainer extends Component {

    render() {
        return <InactiveFilteredList/>
    }
}

export default InactiveContainer;