import request from '../lib/request';

function findSuggestedStreets(pattern) {
    return request({
        url: `/teryt/streets/${pattern}`,
        method: 'GET'
    });
}

const StreetService = {
    findSuggestedStreets
};

export default StreetService;