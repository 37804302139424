import React, { Component } from 'react';
import SkillCategoryForm from './SkillCategoryForm.js';

class SkillCategoryListItem extends Component {
  
	constructor(props) {
		super(props);

		this.onCreateSkillCategory = this.onCreateSkillCategory.bind(this);
		this.onUpdateSkillCategory = this.onUpdateSkillCategory.bind(this);
	}	

	onCreateSkillCategory(skillCategory) {
		this.props.onCreate(skillCategory);
	}
	
	onUpdateSkillCategory(skillCategory) {
		this.props.onUpdate(skillCategory);
	}

 	render() {
		if(this.props.skillCategory.id === '+' && this.props.selected !== this.props.skillCategory.id){
			return null;
		}
		let skillCategoryForm = null;
		if (this.props.selected === this.props.skillCategory.id) {
		  	skillCategoryForm =
				<tr>
				    <td colSpan="2">
				  	    <SkillCategoryForm
				  	    	  key={this.props.skillCategory.id}
				  	    	  skillCategory={this.props.skillCategory}
				  	    	  onCreate={this.onCreateSkillCategory}
				  	    	  onUpdate={this.onUpdateSkillCategory}
				  	    	  onFindSkillCategories={this.props.onFindSkillCategories}
				  	    	  filter={this.props.filter}/>
				    </td>
				</tr>
	    }
	    return (
            <>
		        <tr className={this.props.skillCategory.plus} onClick={() => this.props.onSelect(this.props.skillCategory.id)}>
		        	<td>{this.props.skillCategory.id}</td>
		        	<td>{this.props.skillCategory.name}</td>
		        </tr>{skillCategoryForm}
		    </>
        );
    }
}

export default SkillCategoryListItem;