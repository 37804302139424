import React, {Component} from 'react';
import TaskList from './TaskList.js';
import TaskService from '../../services/task.js';
import i18next from 'i18next';
import Utils from "../common/Utils";

class TaskFilteredList extends Component {

	constructor(props) {
		super(props);
		this.state = {
		    taskData: []
		};

		this.findTasks = this.findTasks.bind(this);
	}

    componentDidMount() {
        this.findTasks({});
    }

    findTasks(filter) {
		TaskService
			.find(filter)
			.then((taskData) => {
				this.setState({taskData});
			}).catch((error) => {
				Utils.error(i18next.t("task-error") + JSON.stringify(error));
			});
	}

  	render() {
    	return <TaskList tasks={this.state.taskData} onFindTasks={this.findTasks}/>
	}
}

export default TaskFilteredList;