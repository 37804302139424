import request from '../lib/request';

function find(searchParams) {
    return request({
        url: "/tasks",
        method: 'GET',
        params: {
            name: searchParams.findName,
        	assignedUser: searchParams.findAssignedUser,
        	taskStatusId: searchParams.findTaskStatusId,
        	projectId: searchParams.findProjectId,
        	projectName: searchParams.findProjectName,
        	active: searchParams.findActive,
        	externalCode: searchParams.findExternalCode,
        	projectCode: searchParams.findProjectCode,
        	programCode: searchParams.findProgramCode,
        	programName: searchParams.findProgramName,
            size: searchParams.findPageSize === undefined ? "50" : searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy+","+searchParams.sortDirection
        }
    });
}

function get(id) {
    return request({
        url: `/tasks/${id}`,
        method: 'GET'
    });
}

function create(task) {
	var data = Object.assign({}, task);
	data.id = null;
    return request({
    	url: "/tasks",
    	method: 'POST',
    	data: data
    });
}


function update(task) {
    return request({
    	url: "/tasks/"+ task.id,
    	method: 'PUT',
    	data: task
    });
}

function remove(taskId) {
    return request({
    	url: "/tasks/" + taskId,
    	method: 'DELETE'
    });
}

function changeReportingFrom(data) {
    return request({
        url: '/tasks/reportingFromChange',
        method: 'POST',
        data: data
    })
}

const TaskService = {
    find,
    get,
    create,
    update,
    remove,
    changeReportingFrom
}

export default TaskService;
