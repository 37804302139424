import React, {Component} from 'react';
import PageSize, {defaultPageSize} from "./PageSize";
import PageNumber from "./PageNumber";
import PropTypes from "prop-types";

export default class PageableFilter extends Component {


    render() {
        return <tr>
            <td colSpan={this.props.colSpan}>
                {(this.props.hidePageSize === false || this.props.hidePageSize === undefined) && <div className="row float-left">
                    <PageSize onChange={this.props.onFilterChange} value={this.props.findPageSize}
                    />
                </div>}
                <div className="row float-right">
                    <PageNumber onChange={this.props.onFilterChange} value={this.props.findPageNumber} showNextPage={this.props.resultSize === Number(this.props.findPageSize === undefined ? defaultPageSize : this.props.findPageSize)}/>
                </div>
            </td>
        </tr>
    }
}

PageableFilter.propTypes = {
    colSpan: PropTypes.string,
    findPageSize: PropTypes.string,
    findPageNumber: PropTypes.string,
    resultSize: PropTypes.number,
    onSubmit: PropTypes.func,
    onFilterChange: PropTypes.func
};
