import React, { Component } from 'react';
import i18next from 'i18next';
import TaskReportListItem from './TaskReportListItem';
import TaskReportAdditionalFilter from './TaskReportAdditionalFilter';
import SortableColumn from '../../common/SortableColumn';

class TaskReportList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            additionalFilter: {
                loggedInPeriodHours: "",
                loggedPrevPeriodHours: "",
                plannedHours: "",
                remainingHours: ""
            },
            list: this.props.reports
        };

        this.onFilterChange = this.onFilterChange.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
        this.sortTaskReports = this.sortTaskReports.bind(this);
        this.renderSummary = this.renderSummary.bind(this);
        this.filterRecords = this.filterRecords.bind(this);
        this.isNumeric = this.isNumeric.bind(this);
    }

    isNumeric(num){
      return !isNaN(num)
    }

    onFilterChange(event) {
        let additionalFilter = Object.assign({}, this.state.additionalFilter);
        const name = event.target.name;
        const value = event.target.value;
        if(name.includes("Hours")){
            if(value.length === 1 && (value.includes("-") || value.includes("<") || value.includes(">") || value.includes("=") ||this.isNumeric(value))){
                additionalFilter[name] = value;
            }
            else if (value.charAt(0) !== ":") {
                if((value.charAt(0) === "<" && value.split("<").length === 2 && (this.isNumeric(value.split("<")[1]) || value.split("<")[1] === "-" )) ||
                    (value.charAt(0) === ">" && value.split(">").length === 2 &&(this.isNumeric(value.split(">")[1]) ||  value.split(">")[1] === "-")) ||
                    (value.charAt(0) === "=" && value.split("=").length === 2 &&(this.isNumeric(value.split("=")[1]) || value.split("=")[1] === "-"))
                ){
                    additionalFilter[name] = value;
                }
                else {
                    if(!["<", ">", "="].includes(value.charAt(0)) && (this.isNumeric(value) ||
                        (value.includes(":") && value.split(":").length === 2 && this.isNumeric(value.split(":")[0]) && (this.isNumeric(value.split(":")[1]) || value.split(":")[1] === "-")))){
                        additionalFilter[name] = value;
                    }
                }
            }
        }
        else {
            additionalFilter[name] = event.target.value;
        }
        this.setState({
            additionalFilter: additionalFilter
        }, ()=> {
            this.filterRecords();
            this.onClickSort(this.state.additionalFilter);
        });
    }

    filterRecords(){

        let copyAddFilter = Object.assign({}, this.state.additionalFilter);
        delete copyAddFilter.sortBy;
        delete copyAddFilter.sortDirection;
        const list = this.props.reports.filter ((item) => {
            for (const[key, value] of Object.entries(copyAddFilter)) {
                if (key.includes("Hours") && value !== "") {
                    if(value.includes("<") && value.charAt(value.length - 1) !== "<" && value.charAt(value.length - 1) !== "-" && Number(item[key]) >= Number(value.split("<")[1])){
                            return false;
                    }
                    if(value.includes(">") && value.charAt(value.length - 1) !== ">" && value.charAt(value.length - 1) !== "-" && Number(item[key]) <= Number(value.split(">")[1])){
                        return false;
                    }
                    if(value.includes("=") && value.charAt(value.length - 1) !== "=" && value.charAt(value.length - 1) !== "-" && Number(item[key]) !== Number(value.split("=")[1])){
                        return false;
                    }
                    if(value.includes(":") && value.charAt(value.length - 1) !== ":" && value.charAt(value.length - 1) !== "-" &&  (Number(item[key]) >= Number(value.split(":")[1]) || Number(item[key]) <= Number(value.split(":")[0]))){
                        return false;
                    }
                } else {
                    if (this.isNumeric(item[key]) && this.isNumeric(value)
                        && parseFloat(value) === 0 && parseFloat(item[key]) !== 0) {
                        return false;
                    } else if (this.isNumeric(item[key]) && this.isNumeric(value)
                        && parseFloat(value) !== 0 && parseFloat(item[key]) < parseFloat(value)) {
                        return false;
                    } else if ((item[key] === undefined || item[key] === null) && value) {
                        return false;
                    } else if (item[key] !== null && item[key] !== undefined && !item[key].toString().toLowerCase().includes(value.toString().toLowerCase())) {
                        return false;
                    }
                }
            }
            return true;
        });
        this.setState({
            list: list
        })
    }

    onClickSort(filter) {
        this.setState(prevState => ({
            additionalFilter: {
            ...prevState.additionalFilter,
            sortBy: filter.sortBy,
            sortDirection: filter.sortDirection
            }
        }), () => {
            this.sortTaskReports(this.state.additionalFilter.sortBy,
                this.state.additionalFilter.sortDirection);
        })
    }

    sortTaskReports(sortBy, sortDirection) {
        let copyList = JSON.parse(JSON.stringify(this.state.list));
        let direction = 1;
        if (sortDirection !== "ASC") {
            direction = -1;
        }
        if(sortBy === "taskStartDate" || sortBy === "taskEndDate"){
            direction *= -1;
        }
        copyList.sort((x, y) =>  direction*((x[sortBy] === y[sortBy]) ? 0 : ((x[sortBy] > y[sortBy]) ? 1 : -1)));
        this.setState({
            list: copyList
        });
    }

    renderSummary() {
        return (
            <tr>
                <td>{i18next.t("summary")}</td>
                <td colSpan={7}/>
                <td>
                    {(this.state.list.map(t => t['plannedHours'])
                    .reduce((partial_sum, t2) => partial_sum + t2*100, 0)|0)/100}</td>
                <td>
                    {(this.state.list.map(t => t['loggedPrevPeriodHours'])
                    .reduce((partial_sum, t2) => partial_sum + t2*100, 0)|0)/100}</td>
                <td>
                    {(this.state.list.map(t => t['loggedInPeriodHours'])
                        .reduce((partial_sum, t2) => partial_sum + t2*100, 0)|0)/100}
                </td>
                <td>
                    {(this.state.list
                        .filter(t => t.taskCode !== "T&M")
                        .map(t => t['remainingHours'])
                        .reduce((partial_sum, t2) => partial_sum + Number(t2)*100, 0) | 0)/100}
                </td>
                {this.renderWorkDaysSummary()}
            </tr>
        );
    }

    renderWorkDaysSummary() {
        if (this.state.list.length > 0) {
            const firstReport = this.state.list[0]
            if (firstReport.workDays !== null) {
                return <><td/>{firstReport.workDays.map((workDay, index) =>
                    <td key={index}>{this.reduceWorkDay(index)}&nbsp;</td>
                )}
                </>
            }
        }
        return <td colSpan={1+this.getWorkDaysColumnsSize()}/>
    }

    reduceWorkDay(index) {
        return (this.state.list.map(t => t.workDays[index].workedHours).reduce((partial_sum, t2) => partial_sum + Number(t2)*100, 0) | 0)/100
    }

    generateWorkDaysColumns() {
        if (this.state.list.length > 0) {
            const firstReport = this.state.list[0]
            if (firstReport.workDays !== null) {
                return firstReport.workDays.map((workDay, index) =>
                    <th key={index} scope="col">{workDay.date.substring(5)}&nbsp;</th>
                )
            }
        }
        return <td/>
    }

    getWorkDaysColumnsSize() {
        if (this.state.list.length > 0){
            const firstReport = this.state.list[0]
            if (firstReport.workDays !== null) {
                return firstReport.workDays.length
            }
        }
        return 1;
    }

    render() {

        return (
            <>
	        	<table className="table">
	        	    <thead>
                        <TaskReportAdditionalFilter filter={this.state.additionalFilter} onFilterChange={this.onFilterChange}
                                                    onFindReports={this.props.findReports} reports={this.state.list}
                                                    lastColumnSpan={this.getWorkDaysColumnsSize()} />
                        <tr>
                            <SortableColumn label={i18next.t("portfolio-name")} filter={this.state.additionalFilter}
                                            name="programName" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("assigned-to")} filter={this.state.additionalFilter}
                                            name="assignedUserName" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("project-code")} filter={this.state.additionalFilter}
                                            name="projectCode" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("project-name")} filter={this.state.additionalFilter}
                                            name="projectName" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("task-code")} filter={this.state.additionalFilter}
                                            name="taskCode" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("task-name")} filter={this.state.additionalFilter}
                                            name="taskName" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("start-date")} filter={this.state.additionalFilter}
                                            name="taskStartDate" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("end-date")} filter={this.state.additionalFilter}
                                            name="taskEndDate" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("planned-hours")} filter={this.state.additionalFilter}
                                            name="plannedHours" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("logged-prev-period")} filter={this.state.additionalFilter}
                                            name="loggedPrevPeriodHours" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("logged-in-period")} filter={this.state.additionalFilter}
                                            name="loggedInPeriodHours" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("remaining-hours")} filter={this.state.additionalFilter}
                                            name="remainingHours" onClick={this.onClickSort}/>
                            <SortableColumn label={i18next.t("status")} filter={this.state.additionalFilter}
                                            name="taskStatusName" onClick={this.onClickSort}/>
                            {this.generateWorkDaysColumns()}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.list.map((report, index) =>
                            <TaskReportListItem key={index} report={report}/>)}
                        {this.renderSummary()}
                    </tbody>
                </table>
            </>
        );
    }
}

export default TaskReportList;