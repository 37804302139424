import React from "react";
import FormControl from "../common/FormControl";
import FormButton from "../common/FormButton";
import UserService from "../../services/user";
import i18next from 'i18next';
import Button from 'react-bootstrap/Button';
import Utils from "../common/Utils";

class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login: "",
            success: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    handleSubmit(event) {
        event.preventDefault();
        UserService
            .getByLogin(this.state.login).then(response => {
            this.setState({
                success: true
            });
        }).catch(error => {
            Utils.error(i18next.t("data-read-error" + JSON.stringify(error)));
            this.setState({
                success: false,
                error: "⚠ " + error.data.message
            });
        });
    }

    windowReload() {
        window.location.reload();
    }

    renderGoBackButton() {
        if (this.props.token === undefined) {
            return <th><Button variant="link" onClick={this.windowReload}>{i18next.t("go-back-button")}</Button></th>
        }
    }

    render() {
        let info = null;
        let style = {};
        if (this.state.success === true) {
            info = "✓ " + i18next.t("password-reset-email-sent");
            style = {color: '#66cda3'};
            setTimeout(this.windowReload, 4000);
        } else if (this.state.errorInfo !== null) {
            info = this.state.errorInfo;
            style = {color: '#cd6666'};
        }
        return (
            <div className="log-window-container">
                <form id="loginForm" className="form-inlin justify-content-center log-window-form" onSubmit={this.handleSubmit}>
                    <h2 className="log-window-headline">{i18next.t("reset-password")}</h2>
                    <h6 style={style}>{info}</h6>
                    <FormControl label={i18next.t("login")} name="login" value={this.state.login} onChange={this.handleChange}
                                 type="text" style={{backgroundColor: "#ffffff"}}/>
                    {/* <tr>
                        <th><FormButton type="submit" value={i18next.t("reset-password")}/></th>
                            {this.renderGoBackButton()}
                    </tr> */}
                    <div className="log-window-btns">
                        <FormButton type="submit" value={i18next.t("reset-password")}/>
                        {this.renderGoBackButton()}
                    </div>
                </form>
            </div>
        )
    }
}

export default ResetPasswordForm;