import React, { Component } from 'react';
import ProgramList from './ProgramList';
import ProgramService from '../../services/program';
import i18next from "i18next";
import Utils from "../common/Utils";

class ProgramFilteredList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            programData: []
        };

        this.findPrograms = this.findPrograms.bind(this);
    }

    componentDidMount() {
        this.findPrograms({});
    }

    findPrograms(filter) {
        ProgramService
            .find(filter)
            .then((programData) => {
                this.setState({programData});
            })
            .catch((error) => {
                Utils.error(i18next.t("portfolio-error") + JSON.stringify(error));
            });
    }

    render() {
        return (
            <ProgramList programs={this.state.programData} onFindPrograms={this.findPrograms}/>
        );
    }
}

export default ProgramFilteredList;