import request from '../lib/request';

function find(searchParams) {
    return request({
        url: "/taskstatuses",
        method: 'GET',
        params: {
            name: searchParams.findName,
			size: searchParams.findPageSize === undefined ? "50" : searchParams.findPageSize,
            page: searchParams.findPageNumber === undefined ? "0" : searchParams.findPageNumber,
            sort: searchParams.sortBy === undefined ? undefined : searchParams.sortBy + "," + searchParams.sortDirection
        }
    });
}

function get(id) {
    return request({
        url: `/taskstatuses/${id}`,
        method: 'GET'
    });
}

function create(taskStatus) {
	let data = Object.assign({}, taskStatus);
	data.id = null;
    return request({
    	url: "/taskstatuses",
    	method: 'POST',
    	data: data
    });
}

function update(taskStatus) {
    return request({
    	url: "/taskstatuses/" + taskStatus.id,
    	method: 'PUT',
    	data: taskStatus
    });
}

function remove(taskStatusId) {
    return request({
    	url: "/taskstatuses/" + taskStatusId,
    	method: 'DELETE'
    });
}

const TaskStatusService = {
    find,
    get,
    create,
    update,
    remove
}

export default TaskStatusService;