import React, {Component} from 'react';
import ProjectFilter from './ProjectFilter.js';
import ProjectListItem from './ProjectListItem.js';
import moment from "moment";
import i18next from 'i18next';
import SortableColumn from "../common/SortableColumn";
import PageableFilter from "../common/PageableFilter";
import ListTitle from "../common/ListTitle";
import UserService from "../../services/user";
import Utils from "../common/Utils";

class ProjectList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowId: null,
            filter: {},
            copyItem: null,
            addButtonLabel: i18next.t("add-new")
        };

        this.selectRow = this.selectRow.bind(this);
        this.findProjects = this.findProjects.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onCreateProject = this.onCreateProject.bind(this);
        this.onUpdateProject = this.onUpdateProject.bind(this);
        this.onCalendarChangeStartDate = this.onCalendarChangeStartDate.bind(this);
        this.onCalendarChangeEndDate = this.onCalendarChangeEndDate.bind(this);
        this.onSortOrder = this.onSortOrder.bind(this);
        this.copyAnItem = this.copyAnItem.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
    }

    componentDidMount() {
        UserService
            .getLoggedInUser()
            .then((response) => {
                this.setState({
                    loggedInUser: response,
                });
            }).catch((error) => {
                Utils.error(i18next.t("user-read-error") + error.data.message);
        });
    }

    selectRow(key) {
        this.setState((prevState) => {
            return {selectedRowId: key === prevState.selectedRowId ? null : key,
                    addButtonLabel: i18next.t("add-new")};
        });
    }

    findProjects() {
        let filter = {...this.state.filter};
        if (this.state.filter.startDateLeftLimit !== undefined && this.state.filter.startDateRightLimit !== undefined) {
            if (this.state.filter.startDateLeftLimit !== null && this.state.filter.startDateRightLimit !== null) {
                filter.startDateLeftLimit = moment(this.state.filter.startDateLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.startDateRightLimit = moment(this.state.filter.startDateRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.startDateRightLimit == null && this.state.filter.startDateLeftLimit !== null) {
                filter.startDateLeftLimit = moment(this.state.filter.startDateLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.startDateRightLimit !== null && this.state.filter.startDateLeftLimit == null) {
                filter.startDateRightLimit = moment(this.state.filter.startDateRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
        if (this.state.filter.endDateLeftLimit !== undefined && this.state.filter.endDateRightLimit !== undefined) {
            if (this.state.filter.endDateLeftLimit !== null && this.state.filter.endDateRightLimit !== null) {
                filter.endDateLeftLimit = moment(this.state.filter.endDateLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.endDateRightLimit = moment(this.state.filter.endDateRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.endDateRightLimit == null && this.state.filter.endDateLeftLimit !== null) {
                filter.endDateLeftLimit = moment(this.state.filter.endDateLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.endDateRightLimit !== null && this.state.filter.endDateLeftLimit == null) {
                filter.endDateRightLimit = moment(this.state.filter.endDateRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
        this.props.onFindProjects(filter);
    }

    onCalendarChangeStartDate(event) {
        this.setState({
                filter: {
                    ...this.state.filter,
                    startDateLeftLimit: event.startDate,
                    startDateRightLimit: event.endDate,
                    findPageNumber: "0"
                }
            }, () => this.findProjects()
        )
    }

    onCalendarChangeEndDate(event) {
        this.setState({
                filter: {
                    ...this.state.filter,
                    endDateLeftLimit: event.startDate,
                    endDateRightLimit: event.endDate,
                    findPageNumber: "0"
                }
            }, () => this.findProjects()
        )
    }

    onFilterChange(event) {
        let filter = Object.assign({}, this.state.filter);
        filter[event.target.name] = event.target.value;
        if(!["findPageNumber", "sortBy", "sortDirection"].includes(event.target.name)){
            filter["findPageNumber"] = "0";
        }
        this.setState({filter}, () => this.findProjects());
    }

    onCreateProject(project) {
        this.setState({
            selectedRowId: null,
            copyItem: null,
            addButtonLabel: i18next.t("add-new")
        });
    }

    onUpdateProject(project) {
        this.setState({project: project});
        this.props.onFindProjects(this.state.filter);
    }

    onSortOrder(filter) {
        this.setState({filter: filter, selectedRowId: null}, () => this.findProjects());
    }

    copyAnItem(project) {
        this.setState({
            selectedRowId: "+",
            copyItem: project
        })
    }

    renderEmptyItem() {
        let project = {id: "+", name: "", code: "",managerId: this.state.loggedInUser ? this.state.loggedInUser.id : "", managerLogin: "",
            managerFirstName: "", managerLastName: "", active: "",
            createdBy: "", program: "", startDate: "", endDate: "",
            reportingFromDate: "", purchaseOrderId: "", plus: "plus"};
        if(this.state.copyItem !== null){
            project = this.state.copyItem;
            project.id = "+";
        }
        return <ProjectListItem key={'+'} selected={this.state.selectedRowId} onSelect={this.selectRow} onCreate={this.onCreateProject}
                                project={project} onFindProjects={this.props.onFindProjects} filter={this.state.filter}/>
    }

    addNewItem(){
        this.setState(prevState => ({
            selectedRowId: prevState.selectedRowId === '+' ? null : '+',
            addButtonLabel: prevState.addButtonLabel === i18next.t("add-new") ? i18next.t("close-form") : i18next.t("add-new")
        }))
    }

    render() {
        return (
            <table className="table table-sm">
                <thead>
                <ListTitle sectionLabel={i18next.t("navbar-projects")} span={9}
                           onAddNewItem={this.addNewItem} buttonLabel={this.state.addButtonLabel}/>
                <ProjectFilter filter={this.state.filter} onCalendarChangeStartDate={this.onCalendarChangeStartDate}
                               onCalendarChangeEndDate={this.onCalendarChangeEndDate} onFilterChange={this.onFilterChange}
                               resultSize={this.props.projects.length}/>
                <tr>
                    <SortableColumn name="p.id" label="Id" filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="p.name" label={i18next.t("project-name")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="p.code" label={i18next.t("project-code")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="m.firstName, m.lastName, m.login" label={i18next.t("managing-user")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="p.active" label={i18next.t("active")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="u.firstName, u.lastName, p.createdBy" label={i18next.t("created-by")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="p.program.name" label={i18next.t("portfolio-name")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="p.startDate" label={i18next.t("start-date")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="p.endDate" label={i18next.t("end-date")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="p.reportingFromDate" label={i18next.t("start-report-date")} filter={this.state.filter} onClick={this.onSortOrder}/>
                    <SortableColumn name="p.purchaseOrderCode" label={i18next.t("order")} filter={this.state.filter} onClick={this.onSortOrder}/>
                </tr>
                </thead>
                <tbody>
                    {this.renderEmptyItem()}
                    {this.props.projects.map((project) =>
                        <ProjectListItem key={project.id} selected={this.state.selectedRowId} project={project}
                                         onSelect={this.selectRow} onUpdate={this.onUpdateProject} copyAnItem={this.copyAnItem}/>)}
                    <PageableFilter colSpan="11" onFilterChange={this.onFilterChange}
                                    findPageSize={this.state.filter.findPageSize}
                                    findPageNumber={this.state.filter.findPageNumber}
                                    resultSize={this.props.projects.length}
                                    hidePageSize={true}/>
                </tbody>
            </table>
        );
    }
}

export default ProjectList;