import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {ContainerIssuesForm} from "../../styledDropzone";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import filesize from "filesize";
import Label from "../common/Label";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import i18next from 'i18next';
import Utils from "../common/Utils";

class ReportIssueForm extends Component {

    state = {
        files: [],
        message: "",
    };

    onDrop = (files) => {
        this.setState({
            files: [...this.state.files, ...files]
        })
    };

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    };

    onUpload = () => {
        if (this.state.message.length > 4) {
            this.props.handleUpload(this.state.files, this.state.message);
        } else {
            Utils.error(i18next.t("message-length"));
        }
    };

    removeFile = (fileToRemove) => {
        const files = this.state.files.filter(file => file.name !== fileToRemove.name);
        this.setState({files: files});
    };

    files() {
        let files = null;
        if (this.state.files.length > 0) {
            files = this.state.files.map(file => (
                <div>
                    <li key={file.name}>
                        {file.name} - {filesize(file.size)}
                        <button className={"btn btn-link"} onClick={() => this.removeFile(file)}>Usuń</button>
                    </li>
                </div>
            ));
        }
        return files;
    }

    render() {
        return (
            <div>
                <Modal.Header>
                    <Modal.Title><Label messageKey="profile-issue-form-title"/></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea className="form-control" rows="5" name="message"
                              value={this.state.message} onChange={this.handleChange}/>
                    <br/>
                    <Dropzone onDrop={this.onDrop}>
                        {({getRootProps, getInputProps}) => (
                            <section className="container">
                                <ContainerIssuesForm {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p><Label messageKey="profile-issue-form-attachment-add"/></p>
                                </ContainerIssuesForm>
                                {this.state.files.length === 0 ? null : (
                                    <div><br/><h5><Label messageKey="profile-issue-form-attachment-added-info"/></h5></div>)}
                                {this.files()}
                            </section>)}
                    </Dropzone>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.disableButton ? (<Spinner animation="border"/>) : null}
                    <PrimaryButton valueKey="send-button"  onClick={this.onUpload} disabled={this.props.disableButton} />
                    <SecondaryButton valueKey="profile-issue-form-close-button" onClick={this.props.handleClose} />
                </Modal.Footer>
            </div>
        );
    }
}

export default ReportIssueForm;