import React, {Component} from 'react';

export default class ListTitle extends Component{


    render() {
        return (
            <tr>
                <td><h2>{this.props.sectionLabel}</h2></td>
                {this.props.span > 0 && <td colSpan={this.props.span}/>}
                <td className="btn-padding-fix" style={{textAlign: 'right'}}><button type="button" className="btn btn-success btn-sm"
                                                         onClick={this.props.onAddNewItem}>
                    {this.props.buttonLabel}</button></td>
            </tr>
        );
    }

}