import React, {Component} from 'react';
import PropTypes from "prop-types";
import i18next from 'i18next';

export default class PageNumber extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: props.value === undefined ? 0 : props.value
        };

        this.changePage = this.changePage.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({page: this.props.value === undefined ? 0 : this.props.value});
        }
    }

    changePage(value) {
        let act = Number(this.state.page) + value;
        if (act < 0) {
            act = 0;
        }
        this.setState({page: act} , () => {
            let event = new Event('input', {bubbles: true});
            this.myinput.dispatchEvent(event);
            this.props.onChange(event);
        });
    }

    render() {
        return <>
            <input type="hidden" name="findPageNumber" value={this.state.page} ref={(input)=> this.myinput = input}/>
            <div><button className="form-control" disabled={Number(this.state.page) === 0} onClick={()=>this.changePage(-1)}>&lt;{i18next.t("previous")}</button></div>&nbsp;
            <div><label style={{backgroundColor: "#25A1FF", width: 30, textAlign:"center", color: "#fff", borderRadius: 5}} className="form-control-plaintext" > {Number(this.state.page) + 1}</label></div>&nbsp;
            <div><button className="form-control" disabled={!this.props.showNextPage} onClick={()=>this.changePage(1)}>{i18next.t("next")}&gt;</button></div>&nbsp;
        </>
    }
}

PageNumber.propTypes = {
    value: PropTypes.string,
    showNextPage: PropTypes.bool,
    onChange: PropTypes.func
};