import React, { Component } from 'react';

class BusinessReportListItem extends Component {

    render() {
        return (
            <>
                <tr>
                    <td>{this.props.report.customerName}</td>
                    <td>{this.props.report.orderId}</td>
                    <td>{this.props.report.portfolioName}</td>
                    <td>{this.props.report.projectName}</td>
                    <td>{this.props.report.projectId}</td>
                    <td>{this.props.report.plannedHours}</td>
                    <td>{this.props.report.loggedHours}</td>
                </tr>
            </>
        );
    }
}

export default BusinessReportListItem;