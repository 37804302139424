import React, { Component } from 'react';
import i18next from 'i18next';

class TaskReportListItem extends Component {


    renderRemainingHours() {
        if (this.props.report.taskCode === 'T&M') {
            return "NA";
        } else {
            return this.props.report.remainingHours;
        }
    }

    renderTaskStatusName() {
        if (this.props.report.taskCode === 'T&M') {
            return i18next.t("in-progress");
        } else {
            return this.props.report.taskStatusName;
        }
    }

    renderWorkDays() {
        if (this.props.report.workDays !== null) {
            return this.props.report.workDays.map((workDay, index) =>
                <td key={index}>{workDay.workedHours}</td>)
        }
        return <td/>
    }

    render() {
        return (
                <tr>
                    <td>{this.props.report.programName}</td>
                    <td>{this.props.report.assignedUserName}</td>
                    <td>{this.props.report.projectCode}</td>
                    <td>{this.props.report.projectName}</td>
                    <td>{this.props.report.taskCode}</td>
                    <td>{this.props.report.taskName}</td>
                    <td>{this.props.report.taskStartDate}</td>
                    <td>{this.props.report.taskEndDate}</td>
                    <td>{this.props.report.plannedHours}</td>
                    <td>{this.props.report.loggedPrevPeriodHours}</td>
                    <td>{this.props.report.loggedInPeriodHours}</td>
                    <td>{this.renderRemainingHours()}</td>
                    <td>{this.renderTaskStatusName()}</td>
                    {this.renderWorkDays()}
                </tr>
        );
    }
}

export default TaskReportListItem;