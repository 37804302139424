import React, {Component} from 'react';
import TaskReportService from "../../../services/taskReport";
import moment from "moment";
import DateRangePickerWrapper from '../../common/DateRangePickerWrapper';
import TaskGroupReportRoleList from './TaskGroupReportRoleList';
import FormButton from '../../common/FormButton.js';
import TaskGroupReportList from "./TaskGroupReportList";
import Utils from "../../common/Utils";
import i18next from 'i18next';
import { css } from '@emotion/core';
import {SyncLoader} from "react-spinners";
import FormCheckbox from '../../common/FormCheckbox';

const override = css`
    display: block;
    margin: 2 auto;
    border-color: red;
    z-index: 1;
`;

export default class TaskGroupReportFilteredList extends Component{

    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            roles: [],
            reportData: [],
            reportList: [],
            loading: false,
            projectNotActive: false,
            userNotActive: false
        };
        this.onCalendarChange = this.onCalendarChange.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
        this.onGenerate = this.onGenerate.bind(this);
        this.findReports = this.findReports.bind(this);
        this.onCheckboxClick = this.onCheckboxClick.bind(this);
    }

    componentDidMount() {
        TaskReportService
            .getRoles()
            .then((result) => {
                this.setState({roles: result,
                    filter: {
                        role: result.length > 0 ? result[0].id: "",
                        startDateLeftLimit: moment().startOf('month'), startDateRightLimit: moment().endOf('month')}
                });
            });
    }

    onCalendarChange(eve) {
        this.setState({
            filter: {
                ...this.state.filter,
                startDateLeftLimit: eve.startDate,
                startDateRightLimit: eve.endDate
            }
        });
    }

    onRoleChange(event) {
        let filter = {...this.state.filter};
        filter[event.target.name] = event.target.value;
        this.setState({filter});
    }


    onGenerate(details) {
        let filter = {...this.state.filter};
        if (this.state.filter.startDateLeftLimit !== undefined && this.state.filter.startDateRightLimit !== undefined) {
            if (this.state.filter.startDateLeftLimit !== null && this.state.filter.startDateRightLimit !== null) {
                filter.startDateLeftLimit = moment(this.state.filter.startDateLeftLimit).format(moment.HTML5_FMT.DATE);
                filter.startDateRightLimit = moment(this.state.filter.startDateRightLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.startDateRightLimit == null && this.state.filter.startDateLeftLimit !== null) {
                filter.startDateLeftLimit = moment(this.state.filter.startDateLeftLimit).format(moment.HTML5_FMT.DATE);
            } else if (this.state.filter.startDateRightLimit !== null && this.state.filter.startDateLeftLimit == null) {
                filter.startDateRightLimit = moment(this.state.filter.startDateRightLimit).format(moment.HTML5_FMT.DATE);
            }
        }
        this.findReports(filter, details);
    }


    findReports(filter, details) {
        this.setState({loading: true});
        TaskReportService
            .findReports(filter, details, this.state.projectNotActive, this.state.userNotActive)
            .then((reportData) => {
                this.setState({reportData: reportData, reportList: <TaskGroupReportList reports={reportData}/>, loading: false});
            }).catch((error) => {
            Utils.error(i18next.t("reports-error") + JSON.stringify(error));
            this.setState({loading: false});
        });
    }

    onCheckboxClick(event){
        let state = {...this.state};
        state[event.target.name] = !state[event.target.name];
        this.setState(state);
    }

    render() {
        let data;
        if (this.state.loading) {
            data =  <div className="row align-items-center justify-content-center">
                <SyncLoader
                    css={override}
                    sizeUnit={"px"}
                    size={15}
                    color={'#d0e1e1'}
                    loading={this.state.loading}/>
            </div>
        } else {
            data = this.state.reportList;
        }

        return (
            <>
            <table className="table">
                <thead className="thead-t-report">
                    <tr className="tr-t-report">
                        <th scope="col">
                            {i18next.t("time-interval")}
                            <DateRangePickerWrapper onChange={this.onCalendarChange}
                                                    dateLeftLimit={this.state.filter.startDateLeftLimit}
                                                    dateRightLimit={this.state.filter.startDateRightLimit}/>
                        </th>
                        <th scope="col">
                            <FormCheckbox name="projectNotActive"
                                          checked={this.state.projectNotActive}
                                          onChange={this.onCheckboxClick}
                                          label={i18next.t("without-inactive-projects")}/>
                            <FormCheckbox name="userNotActive"
                                          checked={this.state.userNotActive}
                                          onChange={this.onCheckboxClick}
                                          label={i18next.t("without-inactive-users")}/>
                        </th>
                        <th scope="col">
                            {i18next.t("report-for")}
                            <TaskGroupReportRoleList onRoleChange={this.onRoleChange}
                                                     name="role"
                                                     value={this.state.filter.role}
                                                     roles={this.state.roles}/>
                        </th>
                        <th scope="col">
                            <FormButton type="button"
                                        onClick={()=> this.onGenerate(false)}
                                        value={i18next.t("generate-button")}/>
                        </th>
                    </tr>
                </thead>
            </table>
                {data}
            </>
        );
    }

}