import React, { Component } from 'react';
import TaskStatusService from '../../services/taskstatus.js';
import i18next from 'i18next';

class TaskAssignerStatusFormControl extends Component {

    constructor(props) {
		super(props);
		this.state = {
            statuses: []
        }
	}

    componentDidMount() {
		TaskStatusService
		    .find({})
			.then((result) => {
				this.setState({statuses: result});
			});
	}

	getEmptyOption() {
        if (this.props.value === undefined) {
            return <option key="" value="">&lt;{i18next.t("choose-the-task-state")}&gt;</option>
        }
    }

    classNameRequired() {
        if (this.props.required === true) {
            return "form-group row required";
        }
        return "form-group row";
    }

    render() {
        return (
            <div className={this.classNameRequired()}>
                <label className="col-sm-2 control-label" htmlFor={this.props.name}>{this.props.label}</label>
                <div className="col-sm-6" >
                    <select name={this.props.name} className="form-control" onChange={this.props.onChange} value={this.props.value}>
                        {this.getEmptyOption()}
                        {this.state.statuses.map((status) =>
                    	    <option key={status.id} value={status.id}>{status.name}</option>)}
                    </select>
                </div>
            </div>
        );
    }
}

export default TaskAssignerStatusFormControl;