import React, {Component} from 'react';
import SkillService from '../../services/skill';
import UserSkillService from '../../services/userskill';
import FormCheckbox from '../common/FormCheckbox';
import FormButton from '../common/FormButton';
import _ from 'dry-underscore-react-native';
import DictionaryService from '../../services/dictionary';
import i18next from 'i18next';
import Utils from "../common/Utils";

class UserSkillsList extends Component {

    constructor(props){
        super(props);
        this.state = {
            allSkills: [],
            userSkills: [],
            tmpUserSkills: [],
            skillCategories: []
        };

        this.checkIfIncludes = this.checkIfIncludes.bind(this);
        this.onSkillChange = this.onSkillChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.getCategoryName = this.getCategoryName.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        SkillService
            .find([])
            .then((response) => {
                this.setState({
                    allSkills: response
                });
            }).catch((error) => {
                Utils.error(i18next.t("skill-reading-error"));
            });

        if (this.props.userId !== "+") {
            UserSkillService
                .getSkillsByUserId(this.props.userId)
                .then((response) => {
                    this.setState({
                        userSkills: [...response],
                        tmpUserSkills: [...response]
                    });
                }).catch((error) => {
                Utils.error(i18next.t("user-skill-reading-error"))
            });
        }
        DictionaryService
            .getSkillCategories()
            .then((response) => {
                this.setState({
                    skillCategories: response
                });
            }).catch((error) => {
                Utils.error(i18next.t("skill-category-reading-error"))
            });
    }

    checkIfIncludes(givenId) {
        return this.state.tmpUserSkills.includes(givenId);
    }

    onSkillChange(event) {
        let newArray;
        if (!event.target.checked) {
            newArray = this.state.tmpUserSkills.filter(function(element) {
                return element !== Number(event.target.value);
            });
        } else {
            newArray = [...this.state.tmpUserSkills];
            newArray.push(Number(event.target.value));
        }
        this.setState({
            tmpUserSkills: newArray
        });
    }

    getCategoryName(givenId) {
        const categories = [...this.state.skillCategories];
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].id === givenId) {
                return categories[i].name;
            }
        }
    }

    onReset(event) {
        event.preventDefault();
        this.setState({
            tmpUserSkills : this.state.userSkills
        });
    }

    onSubmit(event) {
        event.preventDefault();
        UserSkillService
            .updateUserSkills(this.props.userId, this.state.tmpUserSkills)
            .then((response) => {
                this.setState({
                    userSkills: response
                });
            }).catch((error) => {
                Utils.error(i18next.t("user-skills-update-error"));
            });
    }

    render() {
        const grouped = _.groupBy(this.state.allSkills, aSkill => aSkill.skillCategoryId);
        const keys = Object.keys(grouped);
        let result = [];

        keys.map((key) =>
            result[key] = grouped[key].map((skill) =>
                <div className="row">
                    {/* <div className="col-sm-1"/> */}
                    <div className="col-sm-6 user-skill-col">
                        <FormCheckbox key = {skill.id}
                                      label = {skill.name}
                                      value = {skill.id}
                                      checked = {this.checkIfIncludes(skill.id) ? "checked" : ""}
                                      onChange = {this.onSkillChange}/>
                    </div>
                </div>
            )
        );

        return (
            <form id="userSkillsForm" className="form-horizontal" onReset={this.onReset} onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-sm">
                        {Object.keys(result).map(key => {
                            return (
                                <>
                                    <div className="form-group row">
                                        <div className="col-sm-2 category-skills-user">{this.getCategoryName(key)}:</div>
                                    </div>
                                    <div>{result[key]}</div>
                                </>
                            );
                        })}
                        <div className="row btn-skills-user">
                            <FormButton type="submit" value={i18next.t("save-button")}/>
                            <FormButton type="reset" value={i18next.t("cancel-button")}/>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default UserSkillsList;