import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

class PrimaryButton extends Component {

    render() {
        return (
            <input className="btn btn-primary"
                   type="button"
                   disabled={this.props.disabled === undefined ? false : this.props.disabled}
                   value={this.props.valueKey === undefined ? this.props.value : i18next.t(this.props.valueKey)}
                   onClick={this.props.onClick}/>
        );
    }
}

PrimaryButton.propTypes = {
    value: PropTypes.string,
    valueKey: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

export default PrimaryButton;