import request from '../lib/request';
		
function getRoles() {
    return request({
        url: "/dicts/roles",
        method: 'GET'
    });
}

function getTaskStatuses() {
    return request({
        url: "/dicts/taskstatuses",
        method: 'GET'
    });
}

function getReconciliationStatuses() {
    return request({
        url: "/dicts/reconciliationstatuses",
        method: 'GET',
    });
}

function getSettlementTypes() {
    return request({
        url: "/dicts/settlementtypes",
        method: 'GET'
    });
}

function getSkillCategories() {
	return request({
		url: "/dicts/skillcategories",
		method: 'GET'
	});
}

const DictionaryService = {
    getRoles,
    getTaskStatuses,
    getReconciliationStatuses,
    getSkillCategories,
    getSettlementTypes
};

export default DictionaryService;