import React from 'react';
import Suggestion from './Suggestion';
import UserService from '../../services/user';
import i18next from 'i18next';

export default class UserSuggestion extends Suggestion{


    componentDidMount() {
        UserService
            .findUsersRoleUser()
            .then((result) => {
                if(this.props.superUser){
                    //let items = [...result];
                    result.unshift({id: null, firstName: "", lastName: "", login: ""});
                }
                this.setState({items: result});
            });

        if (this.props.chosenUserId !== null) {
            UserService.get(this.props.chosenUserId)
                .then((result) => {
                    this.setState({
                        value: result.firstName + " " + result.lastName + " (" + result.login + ")"
                    })
                })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.chosenUserId !== this.props.chosenUserId && this.props.chosenUserId !== null){
            UserService.get(this.props.chosenUserId)
                .then((result) => {
                    this.setState({
                        value: result.firstName + " " + result.lastName + " (" + result.login + ")"
                    })
                })
        }
    }

    getSuggestionValue(suggestion) {
        if(suggestion.id === null){
            return i18next.t("none");
        } else {
            return suggestion.firstName + " " + suggestion.lastName + " (" + suggestion.login + ")";
        }
    }

    renderSuggestion (suggestion){
        if(suggestion.id === null){
            return (
                <div>
                    &lt;{i18next.t("none")}&gt;
                </div>
            )
        } else {
            return (
                <div>
                    {suggestion.firstName} {suggestion.lastName} ({suggestion.login})
                </div>
            );
        }
    }

    onSuggestionsFetchRequested({value}) {
        const inputValue = value.trim().toLowerCase();
        this.setState({
            suggestions: this.state.items.filter((item) => {
                if(item.id) {
                    return item.firstName.toLowerCase().includes(inputValue) ||
                    item.lastName.toLowerCase().includes(inputValue) ||
                    item.login.toLowerCase().includes(inputValue)
                }
                else{
                    return true;
                }
            })
        })
    }
}
