import React, {Component} from 'react';
import uploadService from "../../services/upload";
import orderService from "../../services/order";
import i18next from 'i18next';
import Utils from "../common/Utils";

class OrderAttachment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attachment: this.props.attachment
        }
    }

    handleDownloadAttachment = (id) => {
        uploadService
            .downloadOrderAttachment(id)
            .then(response => {
                const filename = response.headers.contentdisposition.split('filename=')[1];
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            }).catch((error) => {
                Utils.error(i18next.t("data-read-error" + JSON.stringify(error)));
        });
    };

    handleDeleteAttachment = (id, purchaseOrderId) => {
        orderService
            .deletePurchaseAttachment(id, purchaseOrderId)
            .then(() => {
                    this.props.onUpdate();
            }).catch((error) => {
                Utils.error(i18next.t("delete-error" + JSON.stringify(error)));
        });
    };

    render() {
        return (
            <>
                <tr>
                    <td>
                        <button className={"btn btn-link"}
                                onClick={() => this.handleDownloadAttachment(this.props.attachment.id)}>{this.props.attachment.name}</button>
                    </td>
                    <td>
                        <button className={"btn btn-danger"}
                                onClick={() => this.handleDeleteAttachment(this.props.attachment.id, this.props.order.id)}>{i18next.t("delete-button")}</button>
                    </td>
                </tr>
            </>
        );
    }
}

export default OrderAttachment;