import React, { Component } from 'react';
import UserService from "../../services/user";
import TeamService from "../../services/team";
import i18next from 'i18next';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Utils from "../common/Utils";

class TeamMemberList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            members: [],
            users: [],
            selectedOption: null
        };
        this.findUsers = this.findUsers.bind(this);
        this.loadSelected = this.loadSelected.bind(this);
        this.reloadSelected = this.reloadSelected.bind(this);
    }

    componentDidMount() {
        this.findUsers()
    }

    handleChange = (selectedOption) => {
        let userArray = [];
        for (let i = 0; i < selectedOption.length; i++) {
            userArray.push(selectedOption[i].value);
        }
        this.setState({ selectedOption }, () => this.reloadSelected());

    }

    findUsers() {
        Promise.all([UserService.findByTeamId(this.props.team.id), UserService.findUsersRoleUser()]).then((resultArr) => {
            this.setState({ members: resultArr[0], users: resultArr[1] }, () => this.loadSelected());
        });
    }

    loadSelected() {
        let result = [];
        this.state.members.map((member) =>
            result.push({ label: member.firstName + " " + member.lastName + " (" + member.login + ")", value: member.id }));
        this.setState({
            selectedOption: result
        })
    }

    reloadSelected() {
        let result = this.state.selectedOption.map((selectedOption) => selectedOption.value);

        TeamService
            .updateTeamMembers(this.props.team.id, result)
            .then(() => {
                this.findUsers()
            }).catch(error => {
                Utils.error(i18next.t("team-members-update-error"))
            });

    }

    render() {
        let result = [];
        this.state.users.map((user) =>
            result.push({ label: user.firstName + " " + user.lastName + " (" + user.login + ")", value: user.id }));
        const { selectedOption } = this.state;
        return (

            <div>
                <table className="table table-sm table-team-members">
                    <tbody>
                        <tr>
                            <th>{i18next.t("team-members")}</th>
                            <th>{i18next.t("team-add-new-members")}</th>
                        </tr>
                        <tr>
                            <td>{this.state.members.map((member) =>
                                <div key={member.id} className="row"><label>{member.firstName + " " + member.lastName}</label></div>)}
                            </td>
                            <td className="choose-members">
                                <div className="row">
                                    <div className="col-sm-6 choose-members-input">
                                        <ReactMultiSelectCheckboxes value={selectedOption} options={result}
                                            onChange={this.handleChange} placeholderButtonLabel={i18next.t("select")} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TeamMemberList;