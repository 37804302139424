import React, {Component} from 'react';
import i18next from 'i18next';
import TaskGroupReportListItem from  './TaskGroupReportListItem';
import TaskGroupReportAdditionalFilter from './TaskGroupReportAdditionalFilter';
import SortableColumn from '../../common/SortableColumn';

export default class TaskGroupReportList extends Component{

    constructor(props){
        super(props);
        this.state = {
            reports: this.props.reports,
            users: [],
            filter: {}
        };
        this.getNonDuplicateReports = this.getNonDuplicateReports.bind(this);
        this.getSumsByUser = this.getSumsByUser.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
        this.sortTaskReports = this.sortTaskReports.bind(this);
    }

    componentDidMount() {
        let users = [];
        this.state.reports.forEach((report) => {
            if(!users.includes(report.assignedUserName)){
                users.push(report.assignedUserName);
            }
        });
        this.setState({users});
    }

    getNonDuplicateReports() {
        let nonDuplicateReports = [];
        this.state.reports.forEach((report) => {
            if(!nonDuplicateReports.map(nonDuplicate => nonDuplicate.projectCode)
                .includes(report.projectCode)){
                nonDuplicateReports.push(report);
            }
        });
        return nonDuplicateReports;
    }

    getSumsByUser() {
        let sums = [];
        this.state.users.forEach((user) => {
            sums.push(this.state.reports.filter((report) => {
                return report.assignedUserName === user
            }).map(report => report.loggedInPeriodHours)
                .reduce((previousValue, currentValue) => {
                    return previousValue + currentValue;
                }, 0))
        });
        return sums;
    }

    onFilterChange(event) {
        let copyFilter = {...this.state.filter};
        copyFilter[event.target.name] = event.target.value;
        this.setState({
            filter: copyFilter
        }, () => {
            const filteredReports = this.props.reports.filter((item) => {
                for(const [key, value] of Object.entries(this.state.filter)){
                    if(item[key] !== null
                        && item[key] !== undefined
                        && !item[key].toString().toLowerCase().includes(value.toString().toLowerCase())){
                        return false;
                    }
                }
                return true;
            });
            this.setState({
                reports: filteredReports
            })
        })
    }

    onClickSort(filter) {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                sortBy: filter.sortBy,
                sortDirection: filter.sortDirection
            }
        }), () => {
            this.sortTaskReports(this.state.filter.sortBy,
                this.state.filter.sortDirection);
        })
    }

    sortTaskReports(sortBy, sortDirection) {
        let copyList = JSON.parse(JSON.stringify(this.state.reports));
        let direction = 1;
        if (sortDirection !== "ASC") {
            direction = -1;
        }
        copyList.sort((x, y) =>  direction*((x[sortBy] === y[sortBy]) ? 0 : ((x[sortBy] > y[sortBy]) ? 1 : -1)));
        this.setState({
            reports: copyList
        } );
    }


    render() {
        return (
            <table className="table">
                <thead>
                    <TaskGroupReportAdditionalFilter reports={this.state.reports}
                                                     users={this.state.users}
                                                     onFilterChange={this.onFilterChange}
                                                     filter={this.state.filter}
                                                     sums={this.getSumsByUser()}/>
                    <tr>
                        <SortableColumn label={i18next.t("project-name")}
                                        filter={this.state.filter}
                                        name="projectName"
                                        onClick={this.onClickSort}/>
                        <SortableColumn label={i18next.t("project-code")}
                                        filter={this.state.filter}
                                        name="projectCode"
                                        onClick={this.onClickSort}/>
                        {this.state.users.map((user, index) =>
                            <th key={index}>{user}</th>)}
                        <th>{i18next.t("summary")}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.getNonDuplicateReports().map((report, index) =>
                        <TaskGroupReportListItem report={report}
                                                 users={this.state.users}
                                                 allReports={this.state.reports}
                                                 key={index}/>)}
                    <tr>
                        <th>{i18next.t("summary")}</th>
                        <th/>
                        {this.getSumsByUser().map((sum, index) => <th key={index}>{sum}</th>)}
                        <th>
                            {this.getSumsByUser().reduce((previousValue, currentValue) => {
                                return Number(previousValue) + Number(currentValue);
                            }, 0)}
                        </th>
                    </tr>
                </tbody>
            </table>

        );
    }

}